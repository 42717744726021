.statement-block-component{  
    margin-top: -2rem; 
    .statement-block{
        background-color:$white;
        position: relative;   
        overflow: hidden;
        box-sizing: border-box;
        //min-height:208px;
        padding: 3rem 1.563rem;
        background-repeat:no-repeat;
        background-position:95% 0;
        @include breakpoint(small down){
            //min-height:296px;
            background-position:130% 0;
        }
        h4,.heading4{
            color:$gray-black-dark;
            font-family:'Raleway', sans-serif;
            font-weight:700;
            font-size: 1.375rem;
            line-height:1.31;
            margin:0;
            @include breakpoint(small down){
                line-height:1.36;
            }
        }
    }   
   
    .heart-img{
        position: absolute;
        right:0;
        top:-1.25rem;
        width:225px;
        height:100%;
        @include breakpoint (small down){
            right:-5.938rem;
        }
        .inner-container{
            position: relative;
            .img1{
                position:absolute;
                left:1.25rem;
                top:0;
            }
            .img2{
                position:absolute;
                left:0;
                top:5.125rem;
            }
        }
    }
}

.statement-block-component{
   &.bg-blue{
    .statement-block{
        background-color:$blue-dark;
        h4,.heading4{
            color:$white
        }
    }
   }
}