%fontStyle{
    font-family:'Muli',sans-serif;
    font-size:18px;
    margin:0;
    display:inline-block;
    vertical-align: middle;
}
.announcement-bar-component{
    background-color:$blue-super;  
    box-shadow: 0 7px 8px 0 rgba(91, 101, 115, 0.08); 
    z-index:1; 
    @include breakpoint(small down){
        z-index:9;        
    }
    .announcement-block{
        min-height:80px;
        padding:1.5rem 0;
        box-sizing: border-box;
		text-align: center;
        img{
            @include breakpoint(small down){
                display:none;
            }
        }
    }
  
    h4{
       @extend %fontStyle;    
       font-size: 1rem;    
        font-weight:800;   
        @include breakpoint(medium down){
            display: block;
        }     
    }
    h5{
        @extend %fontStyle;
        font-size: 1.125rem;
        font-weight:400;
        @include breakpoint(medium down){
            display: block;
            font-size: 1rem;
        }  
        @include breakpoint(small down){
            font-size:0.875rem;
        }
    }
    .button{
        background-color:$red-dark;
        line-height:1.25rem;
        margin:0;
        padding: 1rem 1.25rem;
        font-size: 1rem;
        font-family:'Muli',sans-serif;
        font-weight:900;
        border-radius:2px;
        text-transform: uppercase;
        img{
            display: inline-block;
            vertical-align: top;
            margin-right:0.625rem;
        }
        @include breakpoint(medium down){
            margin:10px 0 0!important;  
        }
    }
    .close{
        position: absolute;             
        width:40px;
        height:40px;
        right:38px; 
        top:50%;
        margin-top:-20px; 
        cursor: pointer;
        @include breakpoint(medium down){
            margin:0;
            top:1.95rem;
        }
        @include breakpoint(small down){
            top:0.80rem; 
            width:30px;
            height:30px;
            right:20px;
        }
        svg{
            width:100%;
            height:100%
        }
    }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .announcement-bar-component {
        .close{
            right: 15px;
        }
    }
  }
  .overflow-hidden{
    .announcement-bar-component{
        @include breakpoint(small down){
            z-index:1;
        }
    } 
  }