.rich-text-block {
    text-align: center;
    padding: 3.125rem 11rem;

    @include breakpoint(medium down) {
        padding: 1.875rem 0rem;
    }

    h2 {
        font-family: $primary-font;
        font-size: $x-large;
        font-weight: $xxx-bold;
        text-align: center;
        line-height: $line-height-2;
        margin-bottom: 1.563rem;
        color: $gray-black-dark;

        @include breakpoint(small down) {
            text-align: left;
            font-size: $xxx-medium;
            font-weight: $xx-bold;
            line-height: $xx-large;
            margin-bottom: 0.938rem;
        }
    }

    h3 {
        font-family: $primary-font;
        font-size: $x-medium;
        font-weight: $bold;
        color: $gray-black-dark;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    h4 {
        font-family: $primary-font;
        font-size: $normal;
        font-weight: $xx-bold;
        line-height: $line-height-2;
        color: $gray-black-dark;
        margin-bottom: 2rem;
    }

    h5 {
        font-size: 1.125rem;
        font-family: $primary-font;
        font-weight: 700;
        line-height: 1.31;
        color: $blue-dark;
        margin-bottom: 2rem;
    }

    h6 {
        font-family: $primary-font;
        font-size: 1.125rem;
        font-weight: 800;
        color: $blue-dark;
        margin-bottom: 2rem;
    }

    p {
        font-family: $primary-font;
        font-size: $medium;
        font-weight: $x-bold;
        line-height: $large;
        color: $gray-black-dark;
        text-align: left;

        img {
            min-width: 100% !important;
        }

        @include breakpoint(medium down) {
            font-size: $normal;
            line-height: $xx-medium;
            font-weight: $regular;
            text-align: left;
        }
    }

    a {
        font-family: $primary-font;
        font-size: $medium;
        font-weight: $x-bold;
        font-style: normal;
        text-decoration: underline;
        color: $blue-dark;
    }

    ul {
        text-align: left;
        font-size: $medium;
        font-weight: $x-bold;
        font-family: $primary-font;
    }

    blockquote {
        font-family: $primary-font;
    }

    div > span {
        font-family: $primary-font;
        font-weight: $x-bold;
    }

    figure {
        display: block;
        text-align: center;
        margin: 2.188rem 0;

        @include breakpoint(small down) {
            margin: 1.5rem 0;
        }

        img {
            max-width: 100%;
            max-height: 100%
        }

        a {
            color: #2E548A !important;
            text-decoration: underline;
        }
    }

    div.inner-content {
        ul {
            text-align: left;
            margin-left: 5rem;
            list-style: none;

            li {
                font-family: $primary-font;
                font-size: $medium;
                line-height: $line-height-6;
                font-weight: $regular-2;

                &:before {
                    content: "\2022";
                    color: #e97666;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                    font-weight: $xx-bold;
                    font-size: $x-large;
                    line-height: $xxx-medium;
                    vertical-align: top;
                }
            }

            @include breakpoint (medium down) {
                margin-left: 1rem;
                padding-bottom: 1rem;
            }
        }
    }
}

.rich-text-block2 {
  padding: 5rem 11rem;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  color: $gray-black-dark;

  @include breakpoint(small down) {
    padding: 0 1rem;
  }

  h3 {
    font-size: 1.375rem;
    line-height: 2.125rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
  }

  h4 {
    font-family: 'Muli', sans-serif;
    font-weight: 800;
    font-size: 1rem;
  }

  a {
    color: $blue;
  }

  ul {
    margin-left: 2rem;

    li {
      margin-bottom: 1.25rem;
    }
  }

}