%labelStyle_corp {
  font-family: "Muli", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: $gray-black-dark;
  margin-bottom: 0.5rem;
  line-height: normal;
}
%inputStyle_corp {
  width: 380px;
  height: 56px;
  border: solid 1px #ccc;
  box-shadow: none;
  margin: 0;
  font-family: "Muli", sans-serif;
  background-color: #fff;
  @include breakpoint(medium down) {
    width: 100%;
  }
}
.corporate-partner-form {
  background-color: $blue-super;
  margin-top: -4rem;
  padding-bottom: 4rem;
  .field-block {
    margin: 0 0 30px 0;
  }
  .field-group {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
    .field-block {
      padding: 0 10px;
      @include breakpoint(medium down) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include breakpoint(small down) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
    &.date-container {
      .field-block {
        &.month-field {
          width: 110px;
          flex: 0 0 110px;
          @include breakpoint(small down) {
            width: 50%;
            flex: 0 0 50%;
          }
        }
        &.day-field {
          width: 100px;
          flex: 0 0 100px;
          @include breakpoint(small down) {
            width: 50%;
            flex: 0 0 50%;
          }
        }
        &.year-field {
          width: 200px;
          flex: 0 0 200px;
          @include breakpoint(small down) {
            width: 100%;
            flex: 0 0 100%;
          }
        }
      }
      select {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }
  .corporate-partner-form-section {
    .corporate-partner-form-sub-section {
      background-color: $white;
      padding: 3rem 6.3rem;
      border-top: 1px solid #ccc;
      @include breakpoint(medium down) {
        padding: 2rem;
      }
      .corporate-partner-p {
        font-family: "Muli", sans-serif;
        font-size: 1rem;
        font-weight: 800;
        text-transform: uppercase;
      }
      .hint {
        font-family: "Muli", sans-serif;
        font-size: 0.9rem;
      }
      label {
        @extend %labelStyle_corp;
      }
      input[type="text"] {
        @extend %inputStyle_corp;
      }
      input[type="email"] {
        @extend %inputStyle_corp;
      }
      input[type="number"] {
        @extend %inputStyle_corp;
      }
      select {
        @extend %inputStyle_corp;
      }
      textarea {
        @extend %inputStyle_corp;
        height: 185px;
        width: 100%;
        resize: none;
      }
    }
    .type-partnership-selection,
    .make-selection-section {
      label {
        margin-bottom: 1rem;
      }
      .donations-custom-checkbox {
        font-size: 1rem !important;
        line-height: 1.7rem !important;
        margin-bottom: 1rem !important;
        @include breakpoint(medium down) {
          line-height: normal !important;
        }
      }
    }
    .corporate-partner-button-section {
      margin-top: 3rem;
      text-align: center;
      .button {
        margin: 0 10px;
        &:hover {
          background-color: #2e548a !important;
        }
        @include breakpoint(small down) {
          width: 100%;
          margin: 0 0 10px;
        }
      }
    }
  }
  .corporate-partner-thankyou-container {
    background-color: $white;
    padding: 0 3rem 3rem;
    @include breakpoint(small down) {
      padding: 0 2rem 2rem;
    }
    .inner-container {
      border-radius: 2px;
      border: solid 2px #c5d6f2;
      background-color: #f1f4f9;
      padding: 3rem;
      text-align: center;
      @include breakpoint(small down) {
        padding: 1rem;
      }
      .icon {
        background: url(../../images/svg/icons-handrawn-56-x-56-host-a-fundraiser.png)
          no-repeat center;
        display: block;
        height: 56px;
        margin-bottom: 1rem;
      }
      h2 {
        font-family: "Raleway", sans-serif;
        font-size: 2rem;
        font-weight: 800;
        line-height: 1.33;
        margin: 0 0 1rem;
        color: #404042;
        display: block;
        @include breakpoint(small down) {
          font-size: 1.5rem;
        }
      }
      p {
        font-family: "Muli", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        color: #404042;
      }
    }
  }
  .field-validation-error {
    & > span {
      top: 0;
    }
  }
  .corp-error-msg {
    color: #da1a00;
    font-size: 0.875rem;
    font-family: "Muli", sans-serif;
    position: relative;
    top: 0;
    display: none;
    line-height: normal;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .custom-validation-container {
    .field-validation-error {
      display: none;
    }
  }
  .input-validation-error {
    border-color: #da1a00 !important;
  }
}
.chapter-dropdown-section {
  display: none;
}
.corp-confirm-section {
  background-color: $blue-super;
  .rich-text-block {
    background: #fff;
    position: relative;
    top: -4rem;
    padding: 3rem 6.3rem;
    text-align: left;
    @include breakpoint(medium down) {
      padding: 0 2rem;
    }
  }
}