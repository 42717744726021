.img-link-block{
    padding-top: 2.5rem;
    padding-bottom:3.75rem;
    @include breakpoint(medium down){
        padding-top:1rem;
        padding-bottom:1rem;
    }
    h2{
        font-family: 'Raleway',sans-serif;
        font-size: 2rem;
        line-height:2.75rem;
        color:$gray-black-dark;
        font-weight:800;
        margin-bottom: 2.5rem;
        @include breakpoint(small down){
            margin-bottom: 1.813rem;
            font-size: 1.625rem;
        }
    }
    .figure{
        
        position:relative;
        border:2px solid #fff;
        height:140px;
        @include breakpoint(small down){
            height:68px
        }
        a{
            position: relative;
            display: block;
            text-align: center;
            img{
                object-fit: cover;
                height:136px;
                width:100%;
                @include breakpoint(small only){
                    height:64px;
                }
            }                       
            .figcaption{               
                position: absolute;
                width:100%;
                height:100%;
                text-align: center;
                color:#fff;
                left:0;
                top:0;
                font-size:1.375rem;
                line-height:1.375rem;
                font-family: $primary-font;
                font-weight:800;
                background:rgba(70,100,138,0.6);
                transition:all 0.5s ease-in-out;
                -moz-transition:all 0.5s ease-in-out;
                -webkit-transition:all 0.5s ease-in-out;
                padding: 0.5rem;
                &:hover{
                    background:rgba(70,100,138,0.75);
                }
                @include breakpoint(small down){
                    font-size:1rem;
                    line-height:1.125rem;
                    padding:0.625;
                }
            }
        }
    }
}