.quote-block-component{
    padding:3.125rem 11rem;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    //margin:20px 0;
    @include breakpoint(medium down){
      padding:1.875rem 1rem;
      //margin:1.813rem 0;
    } 
    h5{
      font-size: 1.125rem;
      font-family:'Raleway',sans-serif;
      font-weight:700;
      line-height:1.31;
      color:$blue-dark;
      margin:0;
      @include breakpoint(medium down){
        font-size:1.125rem;
        line-height:1.33;
      }
    }
    h6{
      font-family: 'Muli',sans-serif; 
      font-size:1.125rem;
      font-weight:800;
      color:$blue-dark;
      margin:1.25rem 0 0;
      @include breakpoint(medium down){
        font-size:1rem;
      } 
    }
  }
  .root-page-quote{
    padding: 0rem 6.3rem 5rem;
    position: relative;
    top: -4rem;
    @include breakpoint (medium down){
      padding: 3rem 2rem;
    }
    div.cell.small-12.medium-12.large-12{
      border-top:1px solid #ccc;
      border-bottom:1px solid #ccc;
      padding: 2rem 0;
    h5{
      font-size: 1.125rem;
      font-family:'Raleway',sans-serif;
      font-weight:700;
      line-height:1.31;
      color:$blue-dark;
      margin:0;
      @include breakpoint(medium down){
        font-size:1.125rem;
        line-height:1.33;
      }
    }
    h6{
      font-family: 'Muli',sans-serif; 
      font-size:1.125rem;
      font-weight:800;
      color:$blue-dark;
      margin:1.25rem 0 0;
      @include breakpoint(medium down){
        font-size:1rem;
      } 
    }
  }
}