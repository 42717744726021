.cta-block-component{
    background:$blue-dark;    
    font-family: 'Raleway',sans-serif;
    font-weight:400;
    color:#fff;
    position:relative;
    overflow: hidden;   
    box-sizing: border-box;
    .cta-block{
        min-height:392px;
        position: relative;
        z-index: 1;
        padding: 2rem 1.25rem!important;
        @include breakpoint(medium down){
            min-height: 200px;
            padding: 2rem 1.25rem!important;
        } 
    }
        .heart-img{            
            display: block;
            position: absolute;
            right:0;
            top:0;            
            width:515px;
            height:392px;
            opacity:0.6;
            @include breakpoint(medium down){
                right:-185px;
                width:425px;
                height:323px;
            }
    }
     
   
    h2{
        color:#fff;
        font-family: $primary-font;
        line-height: $xxxxx-large;
        font-size: $x-large;
        font-weight:$regular;
        //margin-bottom: 2rem;
        padding: 0 6.25rem;
        padding-bottom: 2rem;
        @include breakpoint (medium down){
            padding: 0rem 3.5rem;
        }
        @include breakpoint(small down){
            font-size: $xxx-medium;
            line-height: $line-height-9;
            margin-bottom: 1.25rem;
            padding: 0;
        }
    }
    .button.donate-btn{
        background-color:$red-dark;
        border-radius: 2px;
        line-height:$line-height-3;
        margin:0;
        padding: 1rem 1.25rem;
        font-size: $normal;
        font-family:$primary-font;
        font-weight:$xxxx-bold;
        text-transform: uppercase;
        img{
            display: inline-block;
            vertical-align: top;
            margin-right:0.625rem;
        }
    }
    
}