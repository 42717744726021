.feature-block-section-left,
.feature-block-section-right {
    overflow: hidden;
    padding: 3.125rem 0;

    @include breakpoint (medium down) {
        padding: 1.875rem 0;
    }

    .feature-block-left,
    .feature-block-right {
        div.feature-block-text,
        .feature-block-text1 {
            h2 {
                font-family: 'Raleway', sans-serif;
                font-size: 2rem;
                font-weight: 800;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                // padding: 0.3rem 1.5rem;
                padding-left: 0;
                text-align: left;
                color: $gray-black-dark;
                // @include breakpoint (medium down){
                //     text-align: center;
                // }
                @include breakpoint (small down) {
                    font-size: 1.625rem;
                    line-height: 1.26;
                    // text-align: left;
                }
            }

            h4, .h4 {
                font-family: $primary-font;
                font-size: $medium;
                font-weight: $x-bold;
                font-style: normal;
                line-height: 1.875rem;
                letter-spacing: normal;
                text-align: left;
                padding-left: 0;
                color: $gray-black-dark;

                @include breakpoint (small down) {
                    line-height: 1.36;
                }

                ul {
                    list-style: none;

                    li {
                        &:before {
                            content: "\2022";
                            color: #e97666;
                            display: inline-block;
                            width: 1em;
                            margin-left: -.9em;
                            font-weight: 700;
                            font-size: 1.1rem;
                            vertical-align: top;
                        }
                    }
                }
            }

            ul {
                list-style: none;

                li {
                    &:before {
                        content: "\2022";
                        color: #e97666;
                        display: inline-block;
                        width: 1em;
                        margin-left: -.9em;
                        font-weight: 700;
                        font-size: 1.1rem;
                        vertical-align: top;
                    }
                }
            }
        }
    }

    div.action_button {
        padding-top: 2rem;

        @include breakpoint (medium down) {
            padding-bottom: 2rem;
        }

        @include breakpoint (small down) {
            padding-left: 0.8rem;
        }
    }
}

.feature-block-section-right {
    background-color: $blue-dark;

    div.feature-block-right {
        div.feature-block-text1 {

            h2,
            h4, .h4 {
                color: $white;
                a {
                    color: $blue-dark;
                    text-decoration: underline;
                }
            }
        }
    }
}

.feature-block-left {
    div.feature-block-image {
        padding: 1.25rem 2rem;
        @include breakpoint(medium down){
            text-align: left;
            padding: 1.25rem 0.7rem;
        }
    }
}
div.feature-block-right {
    div.feature-block-image1 {
        padding: 1.25rem 2rem;
        @include breakpoint(medium down){
            text-align: left;
            padding: 1.25rem 0.7rem;
        }
    }
}
.feature-block-font-small{
    font-size: 18px !important;
}
.feature-block-font-large{
    font-size: 18px !important;
}

.feature-block-image, .feature-block-image1 {
    iframe {
        width: 100%;
        border: 0;
    }

    .video-transcript {
        display: none;
        height: 150px;
        background-color: #F1F4F9;
        overflow-y: scroll;
        padding: 1.5rem;

        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }
    }
    .link-wrapper{
        text-align: center;
    }
    .link-text {
        font-family:$special-font;
        color: $blue-dark;
        font-size: $x-medium;
        font-weight: $xx-bold;
        font-style: $line-height-normal;
        border-bottom: 1px solid #fff;
        text-decoration: underline;

        em {
            &:after {
                content: ">";
                display: inline-block;
                margin-left: 0.2rem;
            }
        }
    }
}
.feature-block-section-right{
    .feature-block-text1{
        padding: 1.25rem 2rem;
        @include breakpoint(medium down){
            padding: 1.25rem 0.7rem;
        }
    }
}
div.feature-block-left{
    div.feature-block-text{
        padding: 1.25rem 2rem;
        @include breakpoint(medium down){
            padding: 1.25rem 0.7rem;
        }
    }
}