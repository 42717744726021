.scrolljack-map-wrapper{    
    background-color:$blue-super;  
    left:0;
    width:100%;     
    margin:0;
   position: relative;
    .map-slide{
        padding: 50px 100px;
        background-color:transparent; 
         @include breakpoint(medium down){
            padding: 30px 30px;                    
        }
        .map-img{
            width:100%;
        }
        .panel-header{
            margin-bottom:50px;
            @include breakpoint(medium down){
                margin-bottom:30px;
            }
            .sub-panel-header{
                margin:0;
                text-align: center;
                font-family: $primary-font;
                font-weight:$regular-2;
            }
        }
        h2,.heading2{
            font-family: $primary-font;
            font-size: $x-large;
            font-weight: $xxx-bold;
            line-height: $line-height-2;
            color:$gray-black-dark;
            margin:0 auto 20px;
            max-width:600px;
            @include breakpoint(small down){
                font-size: 1.625rem;
                line-height: 1.11;
            }
            sup{
                font-size:0.75rem;
                top: -15px;
            }
            span{
                display:block;
                color:$gray-black-dark;
                @include breakpoint(medium down){
                    display: inline-block;
                }
                &.red{
                    color:#da1a00;
                }
                &.blue{
                    color:$blue-dark;
                }
               
            }
        }
        h5,.heading5{
            font-family: $primary-font;
            font-size: $medium;
            font-weight: $x-bold;
            line-height: $line-height-5;
            margin: 0 0 45px;
            color: $gray-black-dark;
            @include breakpoint(small down){
                font-size:1.125rem;
                font-weight:normal;
                line-height: normal;
            }
        }
        .left-col{
            position: absolute;
            z-index:1;
            top:49.5%;
            transform:translateY(-50%);
            @include breakpoint(medium down){
                position: static;
                top:0;
                transform:none;
            }
            h2,.heading2{                
                font-size: $x-large; 
                margin:0 0 20px;  
                @include breakpoint(small down){
                    font-size: 1.625rem;
                    line-height: 1.26;
                }             
            }
            .action-container{
                margin-top:50px;
                @include breakpoint(medium down){
                    margin-top:25px;
                }
                .button{
                  background-color:$blue-dark;
                  font-family: $primary-font;
                  font-size: $normal;
                  font-weight: $xx-bold;
                  padding: 1rem 1.25rem;
                  line-height:$line-height-normal;
                  border-radius:2px;
                   margin:0;
                }
            }
          
        }
    }
    .left-container{
        margin-top: -35%;
        position: relative;
        @include breakpoint(medium down){
            margin-top:0;
        }
        .map-slide{
            padding-bottom:500px;
            height:200vh;  
            padding-top:0;          
            &:last-child{
                padding-bottom:0;
                height:175vh;
                @include breakpoint(medium down){
                    height: auto;
                    padding-bottom:1.5rem;
                }
            }
               @include breakpoint(medium down){
                background-color:#fff;
                padding:2rem 1.5rem; 
                height: auto!important;               
            }
            .grid-x{
               height:100vh;
                top:0;
                @include breakpoint(medium down){
                    height: auto;
                }
            } 
            
        }
    }
    .map-img-container{
        position: absolute;
        height:100%;
        width:100%;
        left:0;
        .map-img{
            position:absolute;
            width:50%;
            right:100px;
            top:50%;
            transform:translateY(-50%);
            opacity:0;
            transition:all 0.5s ease-in-out;
        //     transition: transform 5s .25s cubic-bezier(0, 1, .3, 1),
        //     opacity 0.5s 0.7s ease-out;
        //    will-change: transform, opacity;
            &.current{               
                opacity:1
            }
            
        }
    }
    .onepage-pagination{
        position: absolute;        
        left:30px;
        top:44%;        
        margin:0;
        z-index:99;
        opacity:0;
        transition: transform 2s .25s cubic-bezier(0, 1, .3, 1),
        opacity 1s 1.5s ease-out;
        will-change: transform, opacity;
        &.show-nav{
            position:fixed;
            
        }
       &.nav-animation{
        opacity:0;
            animation-delay:1s;
            animation-duration: 1s;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            -webkit-animation-fill-mode: both;   
            animation-name: fadeInUp;
            -webkit-animation-name: fadeInUp;
       }
        @include breakpoint(medium down){
            display:none;
        }
       li{
           display: block;
           margin:6px 0;
           a{
            width:16px;
            height:16px;
            background-color:transparent;
            border:2px solid $blue-dark;
            border-radius:50%;
            display: block;  
            position: relative;  
           }
           &.current{
               a{
                &:before{
                    content:'';
                    width:8px;
                    height:8px;
                    border-radius:50%;
                    background-color:$blue-dark;
                    left:2px;
                    top:2px;
                    opacity:1;
                    display: block;
                    position: absolute;
                }
               }
           }
       }
    }
}
.eDSLrY {
    position: sticky;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    top:0;
    height: 100vh;
    overflow: hidden; 
    transition: transform 5s .25s cubic-bezier(0, 1, .3, 1),
    opacity 0.5s 0.7s ease-out;
    will-change: transform, opacity;  
}
.sticky-top-ie{
    position:fixed!important;
    width:100%;
    z-index:1;
    top:56px;
} 
@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.map-slide{
    height:150vh;
    @include breakpoint(medium down){
        height: auto!important;
    }
    .grid-x{
        position:sticky;
        position: -webkit-sticky;
        top:80px;
        &::before{
            content: '';
            display: table;
        }
        &::after{
            content: '';
            display: table;
        }
        @include breakpoint(medium down){
            position: static;
        }
    }
}
@media all and (-ms-high-contrast:none)
     {
        .map-slide{
            height:150vh;
            @include breakpoint(medium down){
                height: auto;
            }
            .grid-x{
                position:relative;               
                top:80px;
                @include breakpoint(medium down){
                    position: static;
                }
            }
        }
        .scrolljack-map-wrapper .map-slide h2 {
    margin: 0 auto;
}
     }
     div.map-img.text-center img {
     width: 1180px;
     height: 520px;
     object-fit: contain;
     @include breakpoint (medium down){
         width: 100%;
         height: auto;
         object-fit: contain;
     }
 }