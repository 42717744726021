.promotion-block {
    .hero-description {
        width: 100%;
        padding: 0;

        .grid-container {
            flex: 0 0 100%;
        }
    }

    button {
        &.rmhc-primary-btn {
            font-family: $primary-font;
            font-size: 1.125rem;
            font-weight: 800;
            color: $blue-dark;
            background-color: $white;
        }
    }

    .hero-description {
        // width: 69%;

        @include breakpoint (small down) {
            width: 100%;
        }

        p.descrption {
            line-height: 1.5;
            width: 100%;
            font-size: 1.125rem;
            word-break: break-word;
            padding-left: 2rem;

            @include breakpoint (medium down) {
                padding-left: 0.7rem;
            }

            @include breakpoint (small down) {
                font-size: 1rem;
                line-height: 1.5;
            }
        }

        ul {
            font-family: $primary-font;
        }
    }

    h2 {
        font-size: $x-large;
        padding-left: 2rem;

        @include breakpoint (medium down) {
            padding-left: 0.7rem;
        }
    }

    @include breakpoint (small down) {
        h2 {
            font-size: $xxx-medium;
            line-height: $line-height-2;
        }

        p {
            font-size: $normal;
            line-height: $line-height-2;
            width: 100%;
        }
    }

    @include breakpoint (medium down) {
        img.promotion-left-img {
            object-fit: cover;
            object-position: left;
            height: 550px;
        }
    }

    a {
        margin-left: 2rem !important;

        @include breakpoint (medium down) {
            margin-left: 0.7rem !important;
        }

        &.donate-btn {
            background-color: #da1a00;
            margin: 0;
            padding: 1rem 1.25rem;
            font-size: 1rem;
            font-family: $primary-font;
            font-weight: 900;
            border-radius: 2px;
            line-height: normal;
            text-transform: uppercase;

            img {
                display: inline-block;
                vertical-align: top;
                margin-right: .625rem;
            }
        }
    }
}

.promotion-block-right {
    .hero-description {
        width: 100%;
        padding-left: 0;
        left: auto;
        right: 0;
        padding-right: 0;

        &.hero-gradient {
            background: linear-gradient(to left,rgba(70,100,138,1) 0,rgba(70,100,138,0) 100%);

            @include breakpoint (medium down) {
                background: rgba(70,100,138,.8);
            }
        }

        .grid-container {
            flex: 0 0 100%;
        }

        @include breakpoint (medium down) {
            padding: 0;
        }

        p {
            font-size: $normal;
            width: 100%;
            line-height: $line-height-6;
            padding-left: 2rem;

            @include breakpoint (medium down) {
                padding-left: 0.7rem;
            }
        }

        h2 {
            font-size: $x-large;
            width: 100%;
            padding-left: 2rem;

            @include breakpoint (medium down) {
                padding-left: 0.7rem;
            }
        }

        @include breakpoint (small down) {
            h2 {
                font-size: 1.625rem;
                line-height: 1.16;
                width: 100%;
            }

            p {
                font-size: 1rem;
                line-height: 1.4rem;
                width: 100%;
            }
        }

        a {
            margin-left: 2rem !important;

            @include breakpoint (medium down) {
                margin-left: 0.7rem !important;
            }

            &.donate-btn {
                background-color: $red-dark;
                margin: 0;
                padding: 1rem 1.25rem;
                font-size: $normal;
                font-family: $primary-font;
                font-weight: $xxx-bold;
                border-radius: 2px;
                line-height: $line-height-normal;
                text-transform: uppercase;

                img {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: .625rem;
                }
            }
        }
    }

    @include breakpoint (medium down) {
        img.promotion-left-img {
            height: 500px;
            object-fit: cover;
            object-position: left;
        }
    }

    @include breakpoint (medium down) {
        img.promotion-right-img {
            height: 600px;
            object-fit: cover;
            object-position: right;
        }
    }
}
div.hero-description{
    div.cell{
        padding: 0;
    }
}