.partner-block-component{
    background-color:$blue-super;
    padding: 2.5rem 0;
    @include breakpoint(small down){
        padding-top: 1rem;
    }
    .section-header{
        margin-bottom: 2rem;
        h4{
            font-family: 'Raleway',sans-serif;
            font-size: 1.125rem;
            font-weight: bold;            
            line-height: 1.31;
            color:$gray-black-dark;
            margin:0 0 5px;
        }
        h6{
            font-family: 'Muli',sans-serif;
            font-size: 1rem;
            font-weight:400;
            margin:0;
        }
    }
    .article-preview{
        &.article-preview-grid{
            justify-content: center;
            .item{
                background-color:#fff;
                border-color:$blue-super;
            }
        }
    }
}
.partner-block-logo-component{   
    padding:3.438rem 0;
    .section-header{
        margin-bottom: 2rem;
        h4{
            font-family: 'Raleway',sans-serif;
            font-size: 1.125rem;
            font-weight: bold;            
            line-height: 1.31;
            color:$gray-black-dark;
            margin:0 0 5px;
        }
        h6{
            font-family: 'Muli',sans-serif;
            font-size: 1rem;
            font-weight:400;
            margin:0;
        }
    }
    .article-preview{
        &.article-preview-grid{
            justify-content: center;
            .item{
                width:calc(100% / 6);
                padding:1.5rem 1rem;
                background-color:#fff;
                border:none;
                margin:0;                
                @include breakpoint(medium down){
                    width:calc(100% / 5);
                    padding:20px;
                }
                @include breakpoint(small down){
                    width:calc(100% / 3);
                    padding:10px;
                }
                .inner-content{
                    height:auto;
                    padding:0;
                    .img-container{
                        height:62px;
                        width:100%;
                        img{
                            height:62px;
                            object-fit: contain;
                        }
                    }
                }
            }
            @include breakpoint(small down){
                margin-left:-10px;
                margin-right:-10px;
            }
        }
    }
}

.partner-block-promoted{
    background-color:$blue-super;
    padding: 3.125rem 0;
    @include breakpoint(small down){
        padding: 1.875rem 0rem; 
    }
    .section-header{
        margin-bottom: 2rem;
        h2{
            font-family: 'Raleway',sans-serif;
            font-size: 2rem;
            font-weight: 800;           
            line-height: 1.14;
            color:$gray-black-dark;
            margin:0 0 30px;
            @include breakpoint(small down){
                font-size: 1.625rem;
            }
        }
        h4,h2{
            font-family: 'Raleway',sans-serif;
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.31;
            color:$gray-black-dark;
            margin:0 0 5px;
        }
        h6,.heading6{
            font-family: 'Muli',sans-serif;
            font-size: 1rem;
            font-weight: normal;
            color:$gray-black-dark;
            margin:0;
        }
    }
    .article-preview{
        &.article-preview-promoted-grid{
            .item{
                background-color:#fff;
                .inner-content{
                    .description{
                        padding:40px 40px 40px 4rem;
                        @include breakpoint(small down){
                            padding:40px;
                        }
                    }
                }
            }
        }
    }
}