.here-for-rmhc li:before {
    color: blue !important;
}
.grid-container {

    @include breakpoint (medium down){
    padding: 0 1.5rem;
    }
}

.blue-super-bg {
    background-color: $blue-super;
    padding: 3.125rem 0 0;
}

.white-super-bg {
    padding: 3.125rem 0 0;

    .article-preview {
        &.article-preview-promoted-grid {
            .item {
                .inner-content {
                    background-color: $blue-super;
                }
            }
        }
    }
}

.promotion-btn {
    background-color: $white;
    border-radius: 2px;
    line-height: normal;
    margin: 0;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    font-family: $primary-font;
    font-weight: 700;
    color: $blue-dark;

    &:hover {
        background-color: $white;
        color: $blue-dark;
    }
}

.rich-nohero {
    margin-top: 3rem;

    @include breakpoint(medium down) {
        margin-top: 0rem;
    }

    @include breakpoint(small down) {
        margin-top: 1rem;
    }
}

section.root-page {
    background-color: $blue-super;

    div.root-page-content {
        position: relative;
        top: -4rem;
        background-color: $white;
        padding: 3rem 6.3rem;

        @include breakpoint (medium down) {
            padding: 3rem 2rem;
        }

        h2 {
            @include breakpoint (medium down) {
                text-align: left;
                font-family: $primary-font;
            }
        }

        h3 {
            font-family: $primary-font;
            text-align: left;
            margin-top: 2rem;
            margin-bottom: 0;
        }

        @include breakpoint(small down) {
            padding: 3rem 2rem;
        }

        h4 {
            font-family: $primary-font;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.11;
            color: $gray-black-dark;
            text-align: left;
            margin-bottom: 2rem;
        }

        h5 {
            font-size: 1.125rem;
            font-family: $primary-font;
            font-weight: 700;
            line-height: 1.31;
            color: $blue-dark;
            margin-bottom: 2rem;
            text-align: left;
        }

        h6 {
            font-family: $primary-font;
            font-size: 1.125rem;
            font-weight: 800;
            color: $blue-dark;
            margin-bottom: 2rem;
            text-align: left;
        }

        ul {
            text-align: left;
            margin-left: 5rem;

            li {
                font-family: $primary-font;
            }

            @include breakpoint (medium down) {
                margin-left: 1rem;
            }
        }
    }
    // form.contactUs{
    //    background-color: $blue-super;
    //    margin-top: -4rem;
    //    div.ContactUsForm{
    //        margin: 0;
    //        background-color: $white;
    //        padding: 3rem 6.3rem;
    //        @include breakpoint (small down){
    //            margin: 0;
    //            padding: 3rem 2rem;
    //        }
    //    }
    // }
}
.blue_super_bg {
    background-color: $blue-super;
}
.super_blue_bg{
    background-color: $blue-dark;
}
.rich-text-block, .hero-description{
    p {
      a{
        color: $blue-dark;
        text-decoration: underline;
      }
    }
  }
  .feature-block-section-left, .feature-block-section-right{
      h4{
        a{
            color: $blue-dark;
            text-decoration: underline;
          }
      }
  }
  div.accordion-content{
    p, .list {
        a{
          color: $blue-dark;
          text-decoration: underline;
        }
      }
  }
  //ADA skip to content
  #main-content{
      pointer-events: none;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.skip-link:focus, .skip-link:active {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    padding: 15px;
    padding: 1.5rem;
    background: #4872ae;
    z-index: 10;
    outline: 0;
    text-align: center;
    color: #fff;
    font-weight: 700;    
}
.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}
.dragscroll {    
    cursor : -webkit-grab;
    cursor : -moz-grab;
    cursor : -o-grab;
    cursor : grab;
  }
  
  .dragscroll:active {
    cursor : -webkit-grabbing;
    cursor : -moz-grabbing;
    cursor : -o-grabbing;
    cursor : grabbing;
  }
  #main-content {
    pointer-events: all;
}
.error-msg {
    color: #da1a00;
    font-size: 13px;
    line-height: 1.38;
    margin: 0;
    font-family: $primary-font;
    font-weight: normal;
}
.default_dialog {
    position: absolute;
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;

    @include breakpoint(medium down) {
        width: 85%;
    }

    .modal-content {
        padding: 90px 60px;

        @include breakpoint(medium down) {
            padding: 80px 40px;
        }

        .close-button {
            width: 40px;
            height: 40px;
            outline: 0;
            top: 20px;
            right: 20px;
        }
    }

    .location-marker {
        margin-bottom: 20px;
    }

    h4 {
        font-family: $primary-font;
        font-size: 26px;
        font-weight: 800;
        line-height: 1.31;
        color: #404042;
    }

    p {
        font-family: $primary-font;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
        color: #404042;
    }
}
.dialog-backdrop{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1005;
    display: none;
    background-color: rgba(0,0,0,.45);
    overflow-y: auto;
    &.active{
        display: block;
    }
}
.hidden{display: none;}
//Custom Checkbox
.checkbox-container {
    margin: 1rem 0 0;

    ul.checkboxes {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            width: 50%;
            flex: 0 0 50%;
            margin-bottom: 1.5rem;
        }

        [role="checkbox"] {
            display: inline-block;
            position: relative;
            padding-left: 2.2rem;
            cursor: default;
            font-family: $primary-font;
            font-size: 1rem;
            color: #fff;
            font-weight: 400;
            padding-right: 5px;

            &::before {
                content: '';
                width: 24px;
                height: 24px;
                background: #fff;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
            }

            &[aria-checked="true"] {
                &::after {
                    content: '';
                    left: 8px;
                    top: 5px;
                    width: 8px;
                    height: 13px;
                    border: solid #4872ae;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                    position: absolute;
                    display: block;
                }
            }
        }
    }
}

