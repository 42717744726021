
.newsLeterForm {
    padding-top: 3.125rem !important;
    text-align: center;
    padding: 1.5rem;
    padding-bottom: 0rem;

    @include breakpoint (small down) {
        padding-top: 2rem;
    }



    .newsletter-btn-section {
        position: absolute;
        top: 59%;
        left: 43.3%;

        @include breakpoint (medium down) {
            position: static;
            padding-bottom: 5%;
        }
        /* Ipad Portrait view */
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            top: 62%;
            left: 52%;
        }
        /* Ipad pro Portrait view */
        @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
            position: static !important;
        }
    }

    .field-block {
        margin: 0 0 30px 0;

        .field-validation-error > span {
            color: $red-dark;
            font-size: 0.875rem;
            font-family: "Muli", sans-serif;
            position: relative;
            top: 0.3rem;
            display: block;
            line-height: normal;
            text-align: left !important;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .field-group {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -10px;
        //Ipad Portrait view
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            display: block;
        }

        .field-block {
            padding: 0 10px;

            @include breakpoint(medium down) {
                width: 50%;
                flex: 0 0 50%;
            }

            @include breakpoint(small down) {
                width: 100%;
                flex: 0 0 100%;
            }
        }

        &.date-container {
            .field-block {
                &.month-field {
                    width: 110px;
                    flex: 0 0 110px;

                    @include breakpoint(small down) {
                        width: 50%;
                        flex: 0 0 50%;
                    }
                }

                &.day-field {
                    width: 100px;
                    flex: 0 0 100px;

                    @include breakpoint(small down) {
                        width: 50%;
                        flex: 0 0 50%;
                    }
                }

                &.year-field {
                    width: 200px;
                    flex: 0 0 200px;

                    @include breakpoint(small down) {
                        width: 100%;
                        flex: 0 0 100%;
                    }
                }
            }

            select {
                flex: 0 0 100%;
                width: 100%;
            }
        }
    }

    .newsletter {
        .newsletter-form-sub-section {
            padding: 3rem 6.3rem;
            position: relative;


            @include breakpoint(medium down) {
                padding: 2rem;
            }

            .corporate-partner-p {
                font-family: "Muli", sans-serif;
                font-size: 1rem;
                font-weight: 800;
                text-transform: uppercase;
            }

            .hint {
                font-family: "Muli", sans-serif;
                font-size: 0.9rem;
            }

            label {
                @extend %labelStyle_corp;
                text-align: left !important;
            }

            input[type="text"] {
                @extend %inputStyle_corp;

                @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
                    width: 362px !important;
                }
                /* Ipad Portrait view */
                @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
                    width: 601px !important;
                }
                /* Ipad pro Portrait view */
                @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
                    width: 744px !important;
                }
            }

            input[type="email"] {
                @extend %inputStyle_corp;

                @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
                    width: 362px !important;
                }
                /* Ipad Portrait view */
                @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
                    width: 601px !important;
                }
                /* Ipad pro Portrait view */
                @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
                    width: 744px !important;
                }
            }

            input[type="number"] {
                @extend %inputStyle_corp;
            }

            select {
                @extend %inputStyle_corp;
            }

            textarea {
                @extend %inputStyle_corp;
                height: 185px;
                width: 100%;
                resize: none;
            }
        }

        .type-partnership-selection,
        .make-selection-section {
            label {
                margin-bottom: 1rem;
            }

            .donations-custom-checkbox {
                font-size: 1rem !important;
                line-height: 1.7rem !important;
                margin-bottom: 1rem !important;

                @include breakpoint(medium down) {
                    line-height: normal !important;
                }
            }
        }
    }

    h4 {
        font-family: 'Raleway',sans-serif;
        font-size: 1.5rem;
        font-weight: 800;
        line-height: 1.31;
        color: $gray-black-dark;
        margin-bottom: 1.875rem;
    }

    .input-group-field {
        width: 270px;
        display: inline-block;
        vertical-align: top;
        position: relative;

        @include breakpoint(small down) {
            width: 100%;
            display: block;
        }

        > {
            .input-group-field {
                height: 56px;
                font-family: 'Muli',sans-serif;
                font-size: 1rem;
                font-weight: normal;
                line-height: normal;
                box-shadow: none;
                border-color: #979797;
                color: $gray-black-dark;
                position: static;
                width: 100%;
                padding-left: 10px;

                &::placeholder {
                    color: #999;
                }

                &::-webkit-input-placeholder {
                    color: #999;
                }

                &:-moz-placeholder {
                    color: #999;
                }

                &::-moz-placeholder {
                    color: #999;
                }

                &:-ms-input-placeholder {
                    color: #999;
                }

                &::-ms-input-placeholder {
                    color: #999;
                }
            }
        }

        .field-validation-error {
            display: block;
            text-align: left;
            position: absolute;
            top: -30px;
            left: 0px;
            width: 100%;
            font-family: 'Muli',sans-serif;
            font-size: 0.813rem;
            font-weight: normal;
            line-height: 1.38;
            color: #da1a00;

            @include breakpoint(small down) {
                text-align: center;
            }
        }
    }


    input[type=submit] {
        &.newsletter-submit {
            background: linear-gradient(to right, #2e548a 50%, $blue-dark 50%);
            background-size: 200% 100%;
            background-position: right bottom;
            -moz-transition: all .3s ease-out;
            -o-transition: all .3s ease-out;
            -webkit-transition: all .3s ease-out;

            &:hover {
                background-position: left top;
            }

            &:focus {
                background-position: left top;
            }
        }
    }

    .button {
        &.primary {
            font-family: 'Muli',sans-serif;
            font-size: 1rem;
            font-weight: bold;
            line-height: normal;
            text-align: center;
            background-color: $blue-dark;
            outline: none;
            padding: 0 1.933rem;
            height: 56px;
            margin: 0;
            vertical-align: top;
            margin-left: -5px;

            &:hover {
                background-color: #2e548a;
            }

            &:focus {
                background-color: #2e548a;
            }

            &:disabled {
                background-color: $blue-dark;
                opacity: 1;

                &:hover {
                    background-color: $blue-dark;
                }
            }

            @include breakpoint(small down) {
                /* margin-top: 1.25rem; */
                border-radius: .125rem;
            }
        }
    }

    .confirmation-block {
        h5 {
            font-family: 'Muli',sans-serif;
            font-size: 1.125rem;
            font-weight: 800;
            line-height: 1.36;
            color: $blue-dark;
        }

        p {
            font-family: 'Muli',sans-serif;
            font-size: 1.125rem;
            line-height: normal;
        }
    }
}
