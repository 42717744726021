.accordian-component-block {
    padding: 3.125rem 11rem;

    @include breakpoint(medium down) {
        padding: 1.875rem 0;
    }

    h2 {
        font-family: $primary-font;
        font-weight: $xxx-bold;
        font-size: $x-large;
        line-height: $xxx-large;
        color: $gray-black-dark;
        text-align: center;
        margin-bottom: 1rem;

        @include breakpoint(small down) {
            font-size: $xxx-medium;
            line-height: $x-large;
        }
    }

    .accordin-block {
        font-family: $primary-font;

        .accordion-title {
            border: none;
            font-size: $medium;
            line-height: $xx-medium;
            color: $gray-black-dark;
            font-weight: $x-bold;
            padding: 0.82rem;
            padding-left: 0;
            padding-right: 4rem;
            width: 100%;
            text-align: left;
            cursor: pointer;

            &:hover, &:focus {
                background: none;
            }

            &:before {
                margin-top: -.7rem;
                width: 1.5rem;
                height: 1.5rem;
                border: 2px solid $blue-dark;
                border-radius: 50%;
                text-align: center;
                color: $blue-dark;
                line-height: $x-medium;
                font-size: $x-large;
                font-weight: $regular-1;

                @include breakpoint(small down) {
                    margin: 0;
                    top: 22%
                }
            }
        }

        .is-active {
            > {
                .accordion-title {
                    &:before {
                        font-size: $xx-medium;
                        line-height: $medium;
                    }
                }
            }
        }

        .accordion-item {
            border-bottom: 1px solid #e2e2e2;

            .accordion-content {
                border: none;
                font-size: $medium;
                font-weight: $regular;
                padding: .75rem 4rem 0 0;

                p, .list {
                    margin-bottom: 2.5rem;
                    font-size: $normal;
                }

                @include breakpoint(small down) {
                    padding: .75rem 0 0 0;
                }
            }
        }
    }
}
