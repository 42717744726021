.header-for-donation{
    position: static;
}
.donation-header {
    .hroizantal-logo {
        @include breakpoint(medium down) {
            display: block;
            text-align: center;
        }
    }

    padding: 1rem 0;

    .donation-support {
        span {
            font-size: $x-small;
            line-height: $large;
            font-weight: $xxx-bold;
            font-family: $primary-font;
            color: $gray-black-dark;

            a {
                font-size: $x-small;
                line-height: $large;
                font-weight: $xxx-bold;
                font-family: $primary-font;
                color: $gray-black-dark;
            }
        }

        h6, .heading6 {
            display: inline;
            font-size: $x-small;
            font-family: $primary-font;
            color: $gray-black-dark;
            font-weight: $regular;
            line-height: $large;
            margin: 0;
        }

        span {
            &.pipe {
                display: inline-block;
                width: 1.5px;
                height: 15px;
                background-color: $gray-black-dark;
                position: relative;
                top: 2px;
                margin: 0 0.2rem;
            }
        }
    }
}