section.hero-root {
    background-color: $blue-dark;
    height: 18.5rem;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .hero-root-description {
        padding: 56px 20% 0 20%;

        @include breakpoint (small down) {
            padding: 0 1.75rem;
        }

        h2 {
            color: #fff;
            font-family: Raleway, sans-serif;
            font-size: 2rem;
            font-weight: 800;
            line-height: 1.14;
            letter-spacing: normal;

            @include breakpoint (small down) {
                font-size: 1.625rem;
                font-weight: bold;
            }
        }
    }

    .heart-img {
        display: block;
        position: absolute;
        right: 70px;
        top: 56px;
        width: 388px;
        //height: 392px;

        //opacity: .2;
        @include breakpoint(small down) {
            right: -120px;
            width: 425px;
            top: 0;
            //height: 323px;
        }
    }

}