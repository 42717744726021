.overview-block-component{ 
   padding: 3.125rem 0 !important;
    @include breakpoint (medium down){
        padding: 1.875rem 0 !important;
    }
    .overview-block{
        padding: 2.5rem 0;
        .icons-grid-anchor{
            display: flex;
            flex-flow:row nowrap;
            align-items: center;
        }
        .icons-grid{
            display:flex;
            flex-flow:row nowrap;
           align-items: center;
           padding:0 2.813rem;
           box-sizing: border-box;
           -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
           @include breakpoint (medium down){
            margin-bottom: 3.625rem;
           }
           @include breakpoint(small down){
               margin-bottom: 3.625rem;
               padding: 0 1.875rem;
               &:last-child{
                   margin:0 0 1.25rem;
               }
           }
           .icons{            
            padding:0 0.438rem;
            flex: 0 0 5em;
            @include breakpoint(small down){
               // flex: 0 0 7em;
                border-color:$blue-dark;
            }
           }
           .info{
               padding:0 0.875rem;
               border-left:1px solid #ccc;
               min-height:3.75rem;
               display: flex;
               display: -ms-flexbox;
               display: -webkit-flex;
               flex-flow:column nowrap;
               justify-content: center;                            
           }
           @media all and (-ms-high-contrast:none)
                {
                .info { display:table-cell; vertical-align: middle; width:15rem } /* IE10 */
                *::-ms-backdrop, .info { display:table-cell; vertical-align: middle; width:15rem } /* IE11 */
                }  
           h2{
            margin: 0;
            font-family: $primary-font;
            font-size: $xx-large;
            color:$gray-black-dark;
            font-weight:$xx-bold;
            line-height:$xx-large;
            @include breakpoint(small down){
                font-size: $xxx-medium;
            }          
        }
        h4,.heading3{
            margin:0;
            font-family:$primary-font;
            font-size: $normal;
            font-weight:$xx-bold;
            line-height:$line-height-2;
            color:$gray-black-dark;
            display:inline-flex; 
        }
        }
        
    }
    .overview-btn-block{
        margin-top: 1.875rem;
        .button{
            &.btn-expand{
                background-color:$blue-dark;
                font-family:'Muli',sans-serif;
                font-size: 1rem;
                margin:0;
                font-weight: 700;
                border-radius:2px;
                padding: 1rem 1.25rem;
            }
            
        }
        .button.hollow{            
            color:$blue-dark;
            font-family:'Muli',sans-serif;
            font-size: 1rem;
            margin:0;
            font-weight:700;
            border: 0.063rem solid $blue-dark;
            border-radius:2px;
            padding: 1rem 1.25rem;
        }
        
    }

    //expand
    .section-title{
        h2{
            font-family:'Raleway',sans-serif;
            font-size: 2rem;
            line-height:3rem;
            font-weight:800;
            margin:0 0 2rem;
            color:$gray-black-dark;
            @include breakpoint(medium down){
                font-size: 1.625rem;
                line-height:2.625rem;
            }
        }
    }
    .overview-block{
        &.overview-block-expand-icon{
            border-top:1px solid #ccc;
            border-bottom:1px solid #ccc;
            padding: 2.5rem 0;
            h4,.heading3{
                font-size: 1rem;
                line-height:1;
            }
            .icons-grid{
                @include breakpoint(small down){
                    padding:0 1.875rem;
                }
                .info{
                    border-color:$blue-dark;
                }
                &:last-child{
                    margin:0;
                }
                .icons{
                    @include breakpoint(small down){
                        flex: 0 0 5em;
                        border-color:$blue-dark;
                    }
                }
            }
        }
    }
   .overview-expand-block{
       padding-top: 2rem;
      >{
           section {
               width:100%;
              >{
                   .grid-container.padding-top-2{padding:0!important}
               }
           }

       }
       .grid-container{
           @include breakpoint (medium down){
               padding: 0;
           }
       }
    .rich-text-block{
        padding:0;
    }
       h3{
           margin:0 0 1.25rem;
           font-size: 1.375rem;
           line-height:1.31;
           font-family: 'Raleway',sans-serif;
           font-weight:700;
           color:$gray-black-dark;
       }
       .inner-content{
           padding-right:1.875rem;
           text-align: left;
        @include breakpoint(medium down){
            padding-right:0;
        }
        }
       ul{
           list-style:none !important;
           margin-left: 1rem !important;
           li{
            font-size: 1rem;
            line-height:1.24;
            font-family:'Muli',sans-serif;
            font-weight:600;
            margin: 0 0 0.313rem;
            color:$gray-black-dark;
               &:before{
                content: "\2022";   
                color: #e97666;  
                display: inline-block;  
                width: 1em; 
                margin-left: -0.9em; 
                font-weight: bold; 
                font-size:1.1rem; 
                vertical-align:top;
                
               }
           }
       }
   } 

}
