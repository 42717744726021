body {
    a:focus{
        outline:none;       
    }
    button:focus{
        outline:none;
    }
    input[type='submit']:focus{
        outline:none;
    }
   
}
body.keyboard-navigation {
    a:focus{
        outline:3px solid #333;
        outline-offset:2;
    }
    button:focus{
        outline:3px solid #333;
        outline-offset:2;
    }
    input:focus{
        outline:3px solid #333;
        outline-offset:2;
    }
    select:focus{
        outline:3px solid #333;
        outline-offset:2; 
    }
   footer{
       a{
           &:focus{
               outline-color:#fff;
           }
       }
       button{
        &:focus{
            outline-color:#fff;
        }
    }
   }
}

%hover-animation{     
    -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
    @include breakpoint(medium up){
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        //box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;  
       
    } 
 
    &:before{
        @include breakpoint(medium up){
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }

    }
    &:hover{
        &:before{
            @include breakpoint(medium up){
                -webkit-transform: scaleX(1);
            transform: scaleX(1);
            }
            
        }
    }
    &:focus{
        &:before{
            @include breakpoint(medium up){
                -webkit-transform: scaleX(1);
            transform: scaleX(1);
            }
            
        }
    }
    &:active{
        &:before{
            @include breakpoint(medium up){
                -webkit-transform: scaleX(1);
            transform: scaleX(1);
            }
            
        }
    }
}
.donate-hover,.solid-red-hover{
@extend %hover-animation;
border:none;
&:before{
    @include breakpoint(medium up){
    background:#ac1400;
    }
}
&:hover{
    @include breakpoint(medium down){
        background-color:#ac1400!important;
    }
}
&:focus{
    @include breakpoint(medium down){
        background-color:#ac1400!important;
    }
}
&:active{
    @include breakpoint(medium down){
        background-color:#ac1400!important;
    }
}
}
.solid-white-hover{
    @extend %hover-animation;
    &:hover{
        color:#2e548a;
        border-color:#f1f4f9;
        @include breakpoint(medium down){
            background-color:#f1f4f9!important;
        }
    }
    &:focus{
        color:#2e548a;
        border-color:#f1f4f9;
        @include breakpoint(medium down){
            background-color:#f1f4f9!important;
        }
    }
    &:active{
        color:#2e548a;
        border-color:#f1f4f9;
        @include breakpoint(medium down){
            background-color:#f1f4f9!important;
        }
    }
&:before{
    @include breakpoint(medium up){
        background:#f1f4f9;
    }
    
}
}

.solid-yellow-hover {
    @extend %hover-animation;

    &:hover {
        color: #000;

        @include breakpoint(medium down) {
            background-color: #f2b606 !important;
        }
    }

    &:focus {
        color: #000;

        @include breakpoint(medium down) {
            background-color: #f2b606 !important;
        }
    }

    &:active {
        color: #000;

        @include breakpoint(medium down) {
            background-color: #f2b606 !important;
        }
    }

    &:before {
        @include breakpoint(medium up) {
            background: #f2b606;
        }
    }
}

.outline-white-hover{
    @extend %hover-animation;    
    &:hover{
        color:#4872ae!important;
        @include breakpoint(medium down){
            background-color:#fff!important;
        }
    }
    &:focus{
        color:#4872ae!important;
        @include breakpoint(medium down){
            background-color:#fff!important;
        }
    }
    &:active{
        color:#4872ae!important;
        @include breakpoint(medium down){
            background-color:#fff!important;
        }
    }
&:before{
    @include breakpoint(medium up){
    background:#fff;
    }
}
}
.solid-blue-hover{
    @extend %hover-animation;
    border:none;
    &:hover{
        @include breakpoint(medium down){
            background-color:#2e548a!important;
        }
    }
    &:focus{
        @include breakpoint(medium down){
            background-color:#2e548a!important;
        }
    }
    &:active{
        @include breakpoint(medium down){
            background-color:#2e548a!important;
        }
    }
&:before{
    @include breakpoint(medium up){
    background:#2e548a;
    }
}
}
.outline-blue-hover{
    @extend %hover-animation;
   
    &:hover{
        color:#fff!important;
        @include breakpoint(medium down){
            background-color:#4872ae!important;
        }
    }
    &:focus{
        color:#fff!important;
        @include breakpoint(medium down){
            background-color:#4872ae!important;
        }
    }
    &:active{
        color:#fff!important;
        @include breakpoint(medium down){
            background-color:#4872ae!important;
        }
    }
&:before{
    @include breakpoint(medium up){
    background:#4872ae;
    }
}
}
.button {
    &.primary-btn-white {
        background-color: #fff;
        border-radius: 2px;
        line-height: normal;
        margin: 0;
        padding: 1rem 1.25rem;
        font-size: $normal;
        font-family: $primary-font;
        font-weight: $xx-bold;
        color: #4872ae;
        border: none;
        text-decoration: none;
    }
}
.button {
    &.primary-btn-blue {
        background-color: $blue-dark;
        border-radius: 2px;
        line-height: normal;
        margin: 0;
        padding: 1rem 1.25rem;
        font-size: $normal;
        font-family: $primary-font;
        font-weight: $xx-bold;
        color: #fff;
        border: none;
        text-decoration: none;
    }
}
.button {
    &.white-btn-outline {
        border-radius: 2px;
        border: solid .1rem #fff;
        background-color: transparent;
        font-size: $normal;
        font-family: $primary-font;
        font-weight: $xx-bold;
        text-align: center;
        color: #fff;
        padding: .99rem 1.25rem;
        margin: 0;
        text-decoration: none;
    }
}
.button {
    &.blue-btn-outline {
        border-radius: 2px;
        border: solid .087rem #4872ae;
        background-color: #f1f4f9;
        font-size: $normal;
        font-family: $primary-font;
        font-weight: $xx-bold;
        text-align: center;
        color: #4872ae;
        padding: .99rem 1.25rem;
        margin: 0;
        text-decoration: none;
    }
}
.button {
    &.primary-btn-red {
        background-color: $red-dark;
        margin: 0;
        padding: 1rem 1.25rem;
        font-size: $normal;
        font-family: $primary-font;
        font-weight: $xxxx-bold;
        border-radius: 2px;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: none;
    }
}
.button.primary-btn-red {
    img{
        display: inline-block;
    vertical-align: middle;
    margin-right: .625rem;
    }
}

.button {
    &.yellow-themed-btn {
        background-color: $yellow;
        border-radius: 2px;
        line-height: normal;
        margin: 0;
        padding: 1rem 1.25rem;
        font-size: $normal;
        font-family: $primary-font;
        font-weight: $xx-bold;
        color: #000;
    }
}