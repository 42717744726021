.image-gallery {
    background-color: $blue-super;
    padding: 3.125rem 0;

    @include breakpoint (medium down) {
        padding: 1.875rem 0;
    }

    .grid-container {
        @include breakpoint(medium down) {
            padding: 0;
        }
    }

    .item {
        margin: 0;
    }

    @include breakpoint (medium down) {
        .grid-container .tabs-panel {
            padding: 0;
        }
    }

    .tabbed-panel {
        @include breakpoint(medium down) {
            overflow: hidden;
            height: 50px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 1rem;
                display: block;
                height: 100%;
                z-index: 1;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f1f4f9+0,ffffff+100&1+0,0+100 */
                background: -moz-linear-gradient(left, rgba(241,244,249,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(241,244,249,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(241,244,249,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f4f9', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 1rem;
                display: block;
                height: 100%;
                z-index: 1;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f1f4f9+100&0+0,1+100 */
                background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(241,244,249,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(241,244,249,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(241,244,249,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f1f4f9',GradientType=1 ); /* IE6-9 */
            }
        }
    }

    ul.tabs {
        background-color: $blue-super;
        border: 0;
        display: block;
        text-align: center;
        padding: 0;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        @include breakpoint(medium down) {
            text-align: center;
            height: 68px;
        }

        li.tabs-title {
            float: none;
            display: inline-block;

            a {
                font-family: $primary-font;
                font-size: $x-small;
                font-weight: $bold;
                line-height: $line-height-normal;
                color: $gray-black-dark;
                margin: 0 1rem;
                padding: 0;
                padding-bottom: 0.4rem;
                color: #9fa1a4;

                @include breakpoint (small down) {
                    margin: 0.25rem 0.8rem;
                }

                &:focus {
                    border-bottom: 4px solid rgb(255, 200, 41);
                }

                &:hover {
                    background-color: $blue-super;
                }

                &:hover {
                    color: #404040;
                }
            }

            &.is-active {
                a {
                    border-bottom: 4px solid $yellow;
                    color: #404040;
                }
            }
        }
    }

    div.tabs-content {
        background-color: $blue-super;
    }

    button {
        &.slick-prev {
            background-image: url(../../images/svg/arrow-left.svg);
            // background-image: url(/-/media/Feature/RMHC/Image Gallery/arrow-left.svg);
            background-repeat: no-repeat;
            background-size: cover;
            left: -50px;
            height: 43px;
            width: 42px;
            top: 50%;
            transform: translateY(-50%);

            @include breakpoint(medium down) {
                left: 10px;
                z-index: 1;
                background-image: url(../../images/svg/arrow-left-white.svg);
                //background-image: url(/-/media/Feature/RMHC/Image Gallery/arrow-left-white.svg);
            }
        }

        &.slick-next {
            background-image: url(../../images/svg/arrow-right.svg);
            //background-image: url(/-/media/Feature/RMHC/Image Gallery/arrow-right.svg);
            background-repeat: no-repeat;
            background-size: cover;
            right: -50px;
            height: 43px;
            width: 42px;
            top: 50%;
            transform: translateY(-50%);

            @include breakpoint(medium down) {
                right: 10px;
                z-index: 1;
                background-image: url(../../images/svg/arrow-right-white.svg);
                //background-image: url(/-/media/Feature/RMHC/Image Gallery/arrow-right-white.svg);
            }
        }

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    div.slick-slide {
        .item {
            figcaption {
                background-color: rgba(34, 34, 36, 0.5);
                font-family: $primary-font;
                font-size: $normal;
                font-weight: $bold;
                line-height: $line-height-7;
                color: $white;
                position: absolute;
                width: 100%;
                bottom: 0px;
                padding: 0.5rem;
                // left: 2px;
                @include breakpoint (small down) {
                    font-size: 0.813rem;
                    line-height: 1.313rem;
                    padding: 0.4rem;
                }
            }
        }
    }

    div.slider-nav, div.slider-nav-new {
        @include breakpoint (small down) {
            padding: 0 1rem;
        }

        .slick-slide {
            cursor: pointer;
            outline: none;

            .item {
                outline: none;

                img {
                    opacity: 0.25;
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    outline: none;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .slider-for {
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    div.slick-slide {
        &.slick-main-active {
            .item {
                img {
                    opacity: 1;
                }
            }
        }
    }
}

.image-gallery.new-carousel {
    padding: 0;
}

@media  all and (max-width: 63.99875em) and (-ms-high-contrast:none)
     {
        .image-gallery {
            .tabbed-panel{
                padding:0 10px;
            }
        }
     }