.reveal{
	&.lightbox-reveal{
		border: 0px !important;
		outline: 0px !important;
		background: none;

		.lightbox-close{
			color: $white;
			@include breakpoint(small down){
				color: $black;
			}
		}
		.lightbox-modal_container{
			width: 100%;
			display: flex;
			background-color: $white;
			padding: 2px;
			@include breakpoint(small down){
				display: block;
			}
			.left-section{
				width: 55%;
				display: block;
				padding: 10px;
				@include breakpoint(small down){
					width: 100%;
				}
				.lightbox-heading{
					display: flex;
					width: 100%;
					justify-content: space-evenly;
					align-items: center;
					.lightbox-heading-txt{
						font-size: 22px;
						text-transform: uppercase;
						margin-top: 15px;
					}
					.lightbox-heading-img {
						width: 80%;
						img{
							width: 100%;
						}
					}
				}
				.lightbox-msg{
					.lightbox-msg-txt{
						font-weight: $bold;
						text-align: center;
						padding: 10px 5px;
						font-size: 18px;
						text-transform: uppercase;
					}
				}
				.lightbox-msg-details{
					.lightbox-msg-details-txt{
						text-align: center;
						font-size: 14px;
						padding: 5px 10px;
					}
				}
				.lightbox-action-btn-container{
					padding: 20px 0px;
					text-align: center;
					.lightbox-action-btn{
						background: red;
						color: $white;
						padding: 10px 25px;
						font-weight: $bold;
						border: 0;
						text-decoration: none;
					}
				}
			}
			.right-section{
				width: 45%;
				@include breakpoint(small down){
					width: 100%;
				}
				img{
					max-width: 100%;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}