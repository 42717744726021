.partner-block-promoted ~ .partner-card-component {
    h4, h2 {
        font-family: Raleway, sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.31;
        color: #404042;
        margin: 0 0 5px;
    }

    h6, .heading6 {
        font-family: Muli, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
    }
}

div.partner-card-address-url {
    a {
        font-weight: 800;
        color: #2e548a;
        text-decoration: underline;
        display: block;
        margin-top: 20px;
    }
}
// .partner-block-promoted .article-preview.article-preview-promoted-grid .item .inner-content{
//     @include breakpoint (medium only){
//         padding: 1.5rem;
//     }
// }
  
.partner-block-promoted .article-preview.article-preview-promoted-grid .item .inner-content .description {
    padding: 0rem 3rem;
   
    @include breakpoint (medium down){
        padding:2.313rem;
    }

//    div.partner-card-logo-small, .img-title-container, .partner-card-address-url{
//        @include breakpoint (medium down){
//            margin-left: 1.5rem;
//        }
//    }
}