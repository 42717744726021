.donation-footer {
    padding: 20px 0;

    @include breakpoint(medium down) {
        padding: 0.5rem 0;
    }

    .copy-right-text p {
        line-height: normal;
    }

    a {
        color: $white;
        font-size: $x-small;
        font-family: $primary-font;
        font-weight: $bold;
        letter-spacing: 0;
        line-height: $large;
    }

    span {
        &.pipe {
            display: inline-block;
            width: 2px;
            height: 15px;
            background-color: $white;
            position: relative;
            top: 2px;
            margin: 0 1rem;

            @include breakpoint(medium down) {
                margin: 0 0.2rem;
            }
        }
    }

    .donation-support {
        padding-bottom: 2rem;

        h6, .heading6 {
            color: $white;
            font-size: $normal;
            line-height: $large;
            font-weight: $regular;
            font-family: $primary-font;
        }

        span {
            a {
                font-size: $normal;
                line-height: $large;
                font-weight: $xxx-bold;
                font-family: $primary-font;
                text-decoration: none;
                border-bottom: 1px solid $white;
            }
        }
    }

    .copy-right-text {
        margin-top: 1rem;

        @include breakpoint (medium down) {
            padding: 0;
        }
    }
}
.footer-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;

    li {
        position: relative;
        display: flex;
        align-items: center;

        &::after {
            content: '';
            width: 2px;
            height: 15px;
            background-color: #fff;
            display: inline-block;
            margin: 0 0.8rem;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    a {
        font-family: $primary-font;
        font-size: $x-small;
        font-weight: $bold;
        line-height: $line-height-10;
        color: #fff;
        display: inline-block;
    }
}