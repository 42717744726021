.about-page-map {
  background-color: $blue-super;
  position: relative;
  overflow: hidden;
  padding: 3.125rem 0;
  h2 {
    font-family: Raleway, sans-serif;
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.14;
    text-align: center;
    color: $gray-black-dark;
    margin: 0 0 4rem;
    // padding: 0 20%;

    @include breakpoint(small down) {
      font-size: 1.625rem;
      line-height: 1.11;
      padding: 0;
      margin: 0 0 2rem;
    }
  }
  .about-img-container {
    position: relative;
  }
  img.about-page-map-img {
    max-width: 100%;
  }
  div.aboutpage-map {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    left: 0;
    width: 100%;
    justify-content: center;

    @include breakpoint(medium down) {
      position: relative;
      top: 0;
      transform: none;
      padding: 0;
      max-width: 380px;
      margin: 2rem auto 0;
    }

    a {
      display: inline-block;
      margin: 0 0.5rem;
      text-align: left;
    }
    .icons-grid {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 2rem;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      background-color: $white;
      box-shadow: 0 7px 8px 0 rgba(91, 101, 115, 0.08);
      width: calc(33.333% - 1rem);
      min-height: 8.5rem;
      @include breakpoint(medium down) {
        margin: 0 0 1rem;
        width: 100%;
      }

      .icons {
        padding: 0 0.438rem;
        flex: 0 0 5em;

        @include breakpoint(small down) {
          flex: 0 0 5em;
          border-color: $blue-dark;
        }
      }

      .info {
        padding: 0 0.875rem;
        padding-right: 0;
        border-left: 2px solid #ccc;
        height: 3.75rem;
        display: flex;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-flow: column nowrap;
        justify-content: center;
        h2 {
          text-align: left;
          margin: 0 0 0.5rem;
        }
      }
      @media all and (-ms-high-contrast: none) {
        .info {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: auto;
        }

        /* IE10 */
        *::-ms-backdrop,
        .info {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: auto;
        }

        /* IE11 */
      }
      h4 {
        font-family: "Raleway", sans-serif;
        font-size: 1rem;
        font-weight: bold;
        color: $gray-black-dark;
        margin-bottom: 0;
      }

      p {
        font-family: "Muli", sans-serif;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.11;
        color: $gray-black-dark;
      }
    }
  }

  a.about-map-btn {
    border-radius: 2px;
    border: solid 0.087rem $blue-dark;
    background-color: $blue-super;
    font-family: "Muli", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: $blue-dark;
    padding: 0.99rem 1.25rem;
    margin: 0;
  }
  .action_container {
    margin: 3rem 0 0;
    @include breakpoint(medium down) {
      margin: 2rem 0 0;
    }
  }
}

// @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
//     .about-page-map  {
//         div.aboutpage-map {
//             .icons-grid{
//                 padding: 0;
//                  width:100%;
//             }
//         }
//     }
//     .about-page-map {
//         .aboutpage-map {
//             a{
//                 width:30%;
//             }
//         }
//     }
//   }
.about-page-map-heading {
    font-family: Raleway,sans-serif;
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.14;
    text-align: left;
    margin: 0 0 0.5rem;
    display:inline-block;
}