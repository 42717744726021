.file-block {
    background-color: $blue-super;

    .file-block-content {
        background-color: $white;
        box-shadow: 0 7px 8px 0 rgba(91, 101, 115, 0.08);

        img {
            width: 60px;
            height: 74px;
        }

        h3 {
            font-size: $x-medium;
            font-weight: $xxx-bold;
            font-family: $primary-font;
            color: $gray-black;
        }

        h4 {
            font-family: $primary-font;
            font-size: $normal;

            a {
                font-family: $primary-font;
                color: $gray-black;
                font-size: $normal;
                font-weight: $x-bold;
                word-wrap: break-word;
            }
        }

        a {
            display: block;
        }
    }
}
