.partner-card-component {
    h2 {
        font-family: $primary-font;
        font-size: $x-large;
        font-weight: $xxx-bold;
        line-height: $line-height-2;
        margin-bottom: 2rem;
        color: $gray-black-dark;
        text-align: center
    }

    padding: 3.125rem 0 3rem;

    @include breakpoint(medium down) {
        padding: 3rem 0 2rem;
    }

    .section-header {
        margin-bottom: 50px;

        h4 {
            font-size: $medium;
            font-family: $primary-font;
            font-weight: $xx-bold;
            line-height: 1.31;
            margin: 0 0 10px;
            color: $gray-black-dark;
        }

        h6 {
            font-family: $secondary-font;
            font-size: $normal;
            line-height: $line-height-normal;
            font-weight: $regular;
            color: $gray-black-dark;
        }
    }

    &.blue-super-bg {
        background-color: $blue-super;

        .partner-card-grid {
            background-color: #fff;

            .hover-content {
                background-color: #fff;
            }
        }

        .partner-card-grid-text-only {
            background-color: #fff;
        }
    }

    .partner-card-grid {
        background-color: $blue-super;
        position: relative;
        min-height: 277px;
        cursor: pointer;

        .img-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            padding: 2rem 1.5rem;

            img {
                display: inline-block;
            }
        }

        .hover-content {
            -webkit-transition: opacity .15s linear;
            transition: opacity .15s linear;
            background-color: $blue-super;
            position: relative;
            z-index: 2;
            height: 100%;
            padding: 34px 30px;
            font-family: $primary-font;
            font-size: $normal;
            line-height: $line-height-3;
            font-weight: $regular;
            justify-content: center;
            align-items: center;
            display: flex;
            color: $gray-black-dark;
            white-space: normal;
            text-align: left;
            visibility: hidden;
            overflow: hidden;
            opacity: 0;

            &.hover-content-active {
                opacity: 1 !important;
                visibility: visible !important;
                transition: opacity 0.1s, visibility 0.2s !important;
            }

            a {
                font-weight: 800;
                color: #2e548a;
                text-decoration: underline;
                display: block;
                margin-top: 20px;
            }

            p {
                margin-bottom: inherit;
                font-size: inherit;
                line-height: inherit;
                text-rendering: inherit;
            }
        }
    }

    .partner-card-grid-text-only {
        padding: 30px;
        position: relative;
        font-family: $primary-font;
        font-size: $medium;
        font-weight: $normal;
        line-height: $line-height-normal;
        white-space: normal;

        strong {
            font-weight: $xxx-bold;
            display: block;
            margin-bottom: .3rem;
        }

        picture {
            display: block;

            img {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
}
.partner-card-logo-small{
    width:101px;
    height:62px;
    margin-bottom:20px;
    img{
        width:100%;
        height:62px;
        object-fit: cover;
    }    
}
.partner-card-address-url{
    margin-top:20px;
    a{
        color:#2e548a;
        font-family: $primary-font;
        font-size:$normal;
        font-weight: $normal;        
        line-height: $line-height-normal;
        font-weight: 800;
        text-decoration: underline;
    }
}
//Mobile Horizontal Scroll
.scroll-area-mobile{
    .grid-container{  
        @include breakpoint(medium down){
            // grid-area: grid-container;
            padding: 0;
            overflow-x: hidden;
            overflow-y: hidden;
            position: relative;
            width:100%;
        }
    
        .grid-x{
            @include breakpoint(medium down){
                position: relative;
                width: 100%;
                overflow-x: scroll;
                overflow-y: hidden;
                white-space: nowrap;
                user-select: none;        
                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;
                flex-flow: row nowrap;
                justify-content: inherit;
                margin:0;
                &::-webkit-scrollbar { 
                    width: 0 !important;
                    height:0!important;
                    -webkit-appearance: none;
                }
                .cell{
                    flex:0 0 300px;
                }
            }
           
        }
    }
}