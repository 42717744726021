.hero-block-componant{
  background-color:#fff;
  &.blue_super_bg{
    background-color:$blue-super;
  }
  &.super_blue_bg{
    background-color: $blue-dark;
  }
}
.rmhc-hero {
    position: relative;
    z-index: 0;

    @include breakpoint(medium down) {
        margin-top: -0.5px;
    }

    img.hero-img {
        // height: 640px;
        //   object-fit: cover;
        @include breakpoint (small down) {
            height: 430px;
            object-fit: cover;
            object-position: 80%;
        }
    }

    h1 {
        font-family: $primary-font;
        color: $white;

        &.hero-home {
            font-size: $xxx-large-2;
            line-height: $line-height-normal;
            font-weight: $regular-1;
            margin-bottom: 1.875rem;

            @include breakpoint (medium down) {
                margin-bottom: 0.875rem;
            }

            @include breakpoint(small down) {
                font-size: $xx-large;
                font-weight: $regular;
            }
        }

        &.hero-landing {
            font-size: $x-large;
            font-weight: $xxx-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-2;
            letter-spacing: normal;
            margin-bottom: 1.563rem;

            @include breakpoint(small down) {
                font-size: $xxx-medium;
            }
        }
    }

    h2 {
        color: $white;
        font-family: $primary-font;
        font-size: $x-large;
        font-weight: $xxx-bold;
        font-stretch: normal;
        font-style: normal;
        line-height: $line-height-5;
        letter-spacing: normal;
        margin-bottom: 1.563rem;

        @include breakpoint(small down) {
            font-size: $xxx-medium;
        }
    }

    a {
        &.read-jordan {
            font-family: $special-font;
            color: $white;
            font-size: $x-medium;
            font-weight: $bold;
            font-style: normal;
            border-bottom: 1px solid #fff;
            padding-bottom: 8px;

            .arrow_icon {
                display: inline-block;
                padding-left: 5px;

                &:after {
                    content: '>';
                    display: inline-block;
                }
            }

            &:hover {
                color: $gray-lighter;
                border-bottom: 1px solid $gray-lighter;
            }

            @include breakpoint (medium down) {
                padding-bottom: 0px;
            }

            hr {
                margin: 0;
                border: 1px solid $white;
            }
        }
    }


    button {

        &.slick-next,
        &.slick-prev {
            display: none !important;
        }
    }

    div {
        &.rectangle-text {
            width: 75%;
            background-color: $blue-dark;
            position: relative;
            height: 8.75rem;
            margin: 0 auto;
            bottom: 5.875rem;

            @include breakpoint(small down) {
                width: 96%;
                padding: 34px;
            }

            @include breakpoint(medium) {
            }

            p {
                color: $white;
                font-family: 'Raleway', sans-serif;
                font-size: 1.625em;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                font-weight: 500;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 35%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-31%, -50%);

                @include breakpoint (small down) {
                    font-size: 1.125rem;
                    line-height: 1.33;
                    left: 27%;
                    transform: translate(-20%, -50%);
                }
            }
        }

        &.rectangle-text1 {
            width: 35%;
            top: 50%;
            left: 17%;
            padding: 1em 5em;
            position: absolute;

            @include breakpoint (small down) {
                width: 100%;
                left: -4.063rem;
                top: 54%;
            }

            p {
                color: $white;
                font-size: 1.375rem;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                font-family: $primary-font;
            }
        }
    }
}

.hero-description {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    box-sizing: border-box;
    padding: 0;

    .grid-container {
        flex: 0 0 100%;
    }

    @include breakpoint(medium down) {
        //padding: 0 2rem;
        width: 100%;
    }

    &.hero-gradient {
        background: linear-gradient(to right,rgba(70,100,138,1) 0,rgba(70,100,138,0) 100%);

        @include breakpoint (medium down) {
            background: rgba(70,100,138,.8);
        }
    }

    .descrption {
        color: $white;
        font-family: $primary-font;
        font-size: $x-medium;
        font-weight: $x-bold;
        line-height: $line-height-6;
        margin: 0;

        @include breakpoint(small down) {
            font-size: $x-medium;
            width: 100%;
        }
    }
}

.hero-bottom-text {
  margin-top: -6.875rem;
  position: relative;


  @include breakpoint (medium down) {
    margin-top: -1.5rem;
  }

  @include breakpoint(small down) {
    margin-top: -1.688rem;
  }

  .rectangle-text2 {
    background: $blue-dark;
    padding: 2.5rem;
    width: 89%;

    @include breakpoint(small down) {
      padding: 1.25rem;
      width: 100%;
    }
    p {
      font-size: 1.375rem;
      font-weight: 700;
      font-family: 'Raleway', sans-serif;
      color: #fff;
      line-height: 1.36;
      margin: 0;

      @include breakpoint(small down) {
        font-size: 1.125rem;
      }
    }
  }
}
