header{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index:9;
    @include breakpoint(medium down){
        position: relative;
    }
}
nav {
    position: relative;
    width: 100%;
    top: 0;
    z-index: 99 !important;
    font-family: $primary-font;
    color: $gray-super-lighter;

    a {
        display: inline-block;

        img {
            &.rmhc-title-logo {
                display: none;
                padding: 0.5rem 1rem;
                background-color: #fff;
            }

            &.rmhc-logo {
                display: block;
            }
        }
    }

    @include breakpoint(medium down) {
        opacity: 1;
        top: 0px;
        width: 68%;
        right: 0px;
        z-index: 0;
        height: 100%;
        position: fixed;
    }

    @include breakpoint(small down) {
        width: 68%;
    }

    .rmhcLogo {
        width: auto;
        height: 3.5rem;
        // padding-left: 13%;

        .img2svg {
            width: 70px;
            //border: 1px solid #fff;
        }
    }

    &.sticky {
        position: relative;
        padding-top: 0px;

        @include breakpoint(medium down) {
            position: fixed;
        }

        .top-bar {
            background-color: rgba(64, 64, 64, 1);
        }
    }

    .top-bar {
        background-color: rgba(64, 64, 64, 0.8);
        padding: 0px;
        height: 3.5rem;

        @include breakpoint(medium down) {
            background-color: $gray-super-lighter !important;
            opacity: 1;
            height: 100%;
        }

        .grid-container {
            flex: 0 0 100%;
        }

        .top-bar-right {
            position: absolute;
            right: 0;
            top: 0;

            ul {
                background-color: transparent;

                @include breakpoint(medium down) {
                    background-color: $gray-super-lighter;
                }
            }

            .dropdown.menu {
                @include breakpoint(medium down) {
                    display: block;
                    height: calc(100% - 236px);
                    overflow-y: auto;
                    padding-bottom: 1.25rem;
                }

                > li > a {
                    background: transparent;
                    color: $gray-super-lighter;
                    font-family: $primary-font;
                    font-size: $normal;
                    font-style: normal;
                    font-weight: $xxx-bold;
                    padding: 1.30rem 1.25rem;
                    border: 0;
                    position: relative;
                    z-index: 1;

                    @include breakpoint(medium down) {
                        color: #000;
                        padding: 1.125rem 1.563rem;
                    }
                }

                > li.li-search > a {
                    padding: 0 1.25rem;
                    height: 100%;
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                }

                > li > a:hover {
                    background-color: #fff;
                    color: #000;

                    @include breakpoint (medium down) {
                        background-color: $gray-super-lighter;
                    }
                }

                > li > a:focus {
                    background-color: #fff;
                    color: #000;
                    border-radius: 0;

                    @include breakpoint (medium down) {
                        background-color: $gray-super-lighter;
                    }
                }

                > li.is-active > a {
                    background: #fff;
                    color: #000;

                    @include breakpoint (medium down) {
                        background-color: $gray-super-lighter;
                    }
                }

                .is-dropdown-submenu-parent > a {
                    &:active {
                        border-color: $black-lighter transparent transparent;
                    }

                    &:after {
                        border-color: #fff transparent transparent;

                        @include breakpoint(medium down) {
                            border-color: #000 transparent transparent;
                            right: 1.5rem;
                        }
                    }
                }


                > li {
                    &.li-search {
                        svg {
                            path {
                                fill: $white;

                                @include breakpoint (medium down) {
                                    fill: $black-lighter;
                                }
                            }
                        }

                        a {
                            &:hover {
                                svg {
                                    path {
                                        fill: $black-lighter;
                                    }
                                }
                            }

                            &:focus {
                                svg {
                                    path {
                                        fill: $black-lighter;
                                    }
                                }
                            }
                        }

                        &.is-active {
                            svg {
                                path {
                                    fill: $black-lighter;
                                }
                            }
                        }
                    }
                }

                .is-dropdown-submenu {
                    background-color: $dropdownmenu-submenu-background;
                    border: 0px;
                    white-space: nowrap;
                    box-shadow: 0 7px 8px 1px rgba(91, 101, 115, 0.08);
                    padding: 0.8rem 0;

                    @include breakpoint (medium down) {
                        padding: 0.5rem;
                    }

                    li.is-submenu-item > a:hover,
                    li.is-dropdown-submenu-item > a:hover {
                        background-color: $blue-super;

                        @include breakpoint (medium down) {
                            background-color: $blue;
                        }
                    }

                    @include breakpoint(medium down) {
                        position: relative;
                        padding: 0rem 1rem;
                        white-space: initial;
                    }

                    p {
                        color: $blue-dark;
                        margin-bottom: 0px;
                        padding: 1rem 1.35rem .2rem;
                        font-size: $xx-small;
                        font-family: $primary-font;
                        font-weight: $xxx-bold;
                        opacity: 0.73;
                        text-transform: uppercase;
                    }

                    > li > a {
                        color: #000;
                    }
                }

                li {
                    a.donate-btn {
                        background-color: #da1a00;
                        margin: 0;
                        padding: 0 1.25rem;
                        line-height: 3.5rem;
                        font-size: $small;
                        font-family: $primary-font;
                        font-weight: $xxxx-bold;
                        text-transform: uppercase;

                        &:hover {
                            color: #fff;
                        }

                        &:focus {
                            color: #fff;
                        }

                        @include breakpoint (medium down) {
                            display: none;
                        }

                        img {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: .625rem;
                        }
                    }
                }
            }

            @include breakpoint(medium down) {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }

    li {
        &.li-en {
            a {
                &.selectedLang {
                    &:hover {
                        &::after {
                            border-color: $blue-dark transparent transparent !important;

                            @include breakpoint (medium down) {
                                border-color: $white transparent transparent !important;
                            }
                        }
                    }

                    &:focus {
                        border-color: $blue-dark transparent transparent !important;

                        &::after {
                            border-color: $blue-dark transparent transparent !important;

                            @include breakpoint (medium down) {
                                border-color: $white transparent transparent !important;
                            }
                        }
                    }

                    &.is-active {
                        border-color: $blue-dark transparent transparent !important;

                        &::after {
                            border-color: $blue-dark transparent transparent !important;

                            @include breakpoint (medium down) {
                                border-color: $white transparent transparent !important;
                            }
                        }
                    }
                }

                svg {
                    float: right;
                }
            }
        }

        a {
            img {
                &.checkmark {
                    float: right;
                }
            }
        }

        &.li-rmhc-mainnav {
            a {
                &::after {
                    display: none !important;

                    @include breakpoint (medium down) {
                        display: inline !important;
                        border-color: $blue-dark transparent transparent !important;
                    }
                }
            }
        }
    }
}



.title-bar {
    background-color: $gray-black-dark;
    display:none;
    .for-mobile{
        @include breakpoint(medium down){
            position: relative;
            display: flex;
        }
    }

    @include breakpoint (medium down) {
        background-color: $gray-super-lighter-black;
        //opacity: 0.5;
        height: 3.5rem;
        display: flex;
    }

    a {
        &.rmhc-logo {
            margin-top:0;
            padding-left: 1rem;

            @include breakpoint (medium down) {
                position: relative;
                z-index: 1;
            }
        }
    }
}

.mobile-btndonate {
    @include breakpoint(medium down) {
        display: block;
        position: absolute;
        bottom: 1.75rem;
        width: 100%;
        box-sizing: border-box;
        padding: 0 1.375rem;


        a.donate-btn {
            background-color: $red-dark;
            margin: 0;
            font-size: $small;
            font-family: $primary-font;
            font-weight: $xxxx-bold;
            display: block;
            text-transform: uppercase;

            img {
                display: inline-block;
                vertical-align: top;
                margin-right: .625rem;
            }
        }

        a.donate-btn {
            position: static;
        }
    }
}

@include breakpoint(medium down) {
    .title-bar a.button.donate-btn {
        background-color: $red-dark;
        padding: 1.05rem 1rem;
        font-size: $small;
        font-family: $primary-font;
        font-weight: $xxxx-bold;
        position: relative;
        right: 20px;
        top: 0;
        margin: 0;
        text-transform: uppercase;
        min-height: 56px;
        line-height: 20px;

        img {
            display: inline-block;
            vertical-align: top;
            margin-right: .625rem;
        }
    }
}


li.li-search.hide-for-large {
    li {
        padding-left: 1rem;

        input {
            max-width: 165px;
        }
    }

    button {
        background-color: $blue-dark;;
        position: relative;
        //right: 1rem;
        padding: 0.438rem;

        svg {
            path {
                fill: $white !important;
            }
        }
    }
}

li.li-search {
    li {
        padding-left: 0;

        input {
            max-width: 100%;
            padding: 1.5rem .8rem;
            font-family: $primary-font;
            font-size: $normal;
            color: $gray-black-dark;
            height: 50px;
            border: 1px solid $gray-lighter;
        }
    }

    button {
        background-color: $blue-dark;
        ;
        position: relative;
        //right: 1rem;
        padding: 0.438rem;

        &:hover {
            background-color: $blue-dark;
            ;
        }

        &:focus {
            background-color: $blue-dark;
            ;
        }

        svg {
            path {
                fill: $white !important;
            }
        }
    }
}

@include breakpoint(medium down) {
    .title-bar {
        justify-content: space-between;
        //background-color: transparent;
        padding:0 0.5rem;
        align-items: flex-start;
    }

}

div.en-for-mobile {
    background-color: $blue-dark;
    position: relative;

    li.li-en {
        //width: 40%;
        list-style: none;

        a.selectedLang {
            background-color: $blue-dark;
            color: #f7f7f7;
            font-family: Muli, sans-serif;
            font-size: .938rem;
            font-style: normal;
            font-weight: 800;
            padding: .7rem 0.4rem;

            svg {
                float: none;
                margin-bottom: 4px;

                path {
                    fill: $white;
                }
            }
        }

        // ul.ul-en {
        //     display: none;
        //     background-color: $blue;
        //     right: 10px;
        // }

    }

    ul.menu.vertical.ul-en.submenu.is-dropdown-submenu {
        background-color: $blue;
        padding: 0;
        min-width: 255px;
        right: 10px;
        top: 9px;

        li {
            a {
                color: $white;

                svg {
                    float: right;
                    margin-right: 7px;

                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

}

/* Hamburg */

.nav-icon {
    width: 39px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 2;
    height: 41px;
    overflow: hidden;

    @include breakpoint (medium down) {
        right: 0.375rem;      
        top: 8px;
    }

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 50%;
        background: $white;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(even) {
            left: 50%;
            border-radius: 0 9px 9px 0;
        }

        &:nth-child(odd) {
            left: 0px;
            border-radius: 9px 0 0 9px;
        }

        &:nth-child(1) {
            top: 8px;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 18px;
        }

        &:nth-child(4) {
            top: 18px;
        }

        &:nth-child(5) {
            top: 28px;
        }

        &:nth-child(6) {
            top: 28px;
        }
    }
}

.nav-icon.open {
    span {
        &:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            // left: 5px;
            // top: 7px;
            left: 0px;
            top: 10px;
        }

        &:nth-child(6) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            // left: calc(50% - 5px);
            // top: 17px;
            left: 14px;
            top: 24px;
        }

        &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            // left: calc(50% - 5px);
            // top: 7px;
            left: 14px;
            top: 10px;
        }

        &:nth-child(5) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            // left: 4px;
            // top: 18px;
            left: 0;
            top: 24px;
        }

        &:nth-child(3) {
            left: -50%;
            opacity: 0;
        }

        &:nth-child(4) {
            left: 100%;
            opacity: 0;
        }
    }
}

//Ends
.top-bar-right {
    > {
        .menu.accordion-menu {
            position: relative;

            @include breakpoint (medium down) {
                height: calc(100vh - 8.75rem);
                overflow: auto;
                display: block;
                padding-bottom: 20px;
                box-sizing: border-box;

            }
        }
    }

}



.language-dropdown {
    .en-for-mobile {
        > {
            a {
                display: inline-block;
                color: #fff;
                font-weight: 800;
                font-size: 1rem;
                line-height: 3.75rem;
                padding: 0 2.813rem 0 1.563rem;
                position: relative;
                width:92px;

                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 0.375rem solid transparent;
                    border-right: 0.375rem solid transparent;
                    border-top: 0.375rem solid #fff;
                    display: block;
                    position: absolute;
                    right: 1.25rem;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            a.active {
                background-color: $blue;
            }
        }

        .lan-dropdown {
            width: 100%;
            background-color: $blue;
            padding: 0.625rem 0;
            display: none;

            li {
                position: relative;

                a {
                    font-size: 1rem;
                    font-weight: 400;
                    color: #fff;
                    padding: 0.75rem 1.563rem;
                    position: relative;
                }

            }

            li.selected {
                &:after {
                    content: '';
                    width: 1.063rem;
                    height: 0.938rem;
                    display: block;
                    position: absolute;
                    right: 1.5rem;
                    background: url(/-/media/Project/RMHC/Common/check-mark-white.svg) no-repeat right;
                    top: 0.75rem;
                }

            }

        }
    }
}

.top-bar-right {
    .accordion-menu {
        a.rmhc-mainnav-a {
            @include breakpoint (medium down) {
                padding: 1.125rem 1.563rem;
                font-size: 16px;
                font-weight: 800;
                color: $gray-black-dark;
            }
        }

        .is-accordion-submenu {
            a {
                @include breakpoint (medium down) {
                    color: $gray-black-dark;
                    padding: .7rem 1.563rem;
                }
            }

            p {
                @include breakpoint (medium down) {
                    padding: .7rem 1.563rem;
                    margin: 0;
                    color: $blue-dark;
                    text-transform: uppercase;
                    line-height: 1;
                    font-size: .813rem;
                    font-weight: 800;
                }
            }
        }

    }
}



.rmhc-nav {
    @include breakpoint (medium down) {        
    right:0;       
    overflow: hidden;
    display:none;
    // visibility: hidden;   
    // -webkit-backface-visibility: hidden;
    // backface-visibility: hidden;
    // transition:all 0.3s ease-in-out;
    // -moz-transition:all 0.3s ease-in-out;
    // -webkit-transition:all 0.3s ease-in-out;
    // transform: translate3d(100%, 0, 0);
    // transition:transform 300ms cubic-bezier(0.4, 0, 0.6, 1);
    }
}

.overflow-hidden .rmhc-nav {
    @include breakpoint (medium down) {
        // transform: translate3d(0, 0, 0); 
        // visibility: visible;
        // right:0;
       

    }
}

.language-dropdown {
    @include breakpoint (medium down) {
        position: relative;
        background-color:$blue-dark;
        height:60px; 
    }
}



@media all and (max-width:1023px) and (-ms-high-contrast:none) {
    nav .rmhcLogo {
        display: none
    }
}

$breakpoint-tablet-up: 1024px;

@media (min-width: $breakpoint-tablet-up) {
    nav .top-bar .top-bar-right .dropdown.menu>li.li-search {
        margin: 0;
        padding: 0 1rem 0 0;
    }

    .dropdown {
        &.menu {
            li {
                > {
                    ul {
                        display: block;
                        opacity: 0;
                        visibility: hidden; 
                        overflow: hidden; 
                        -webkit-transition: max-height 0.4s ease-in;
                        -moz-transition: max-height 0.4s ease-in;
                        -o-transition: max-height 0.4s ease-in;
                        -ms-transition: max-height 0.4s ease-in;
                        transition: max-height 0.4s ease-in; 
                        max-height:0;
                    }
                }
                // a:focus + ul{
                //     opacity: 1;
                //     visibility: visible;                       
                //     max-height: 600px;
                //     overflow: hidden;
                // }
                &:hover {
                    ul {
                        opacity: 1;
                        visibility: visible;                       
                        max-height: 600px;
                        overflow: hidden;
                    }
                }

                &.li-search.show-for-large.opens-left {
                    ul {
                        transform-origin: top right;
                        right: 16px;
                        width: 35.063rem;
                        padding:0.375rem 1.563rem;
                        //top: 80%;
                    }

                }
            }
        }
    }
}


.dropdown {
    .is-dropdown-submenu {
        &.ul-en {
            @include breakpoint(medium up) {
                min-width: 150px !important;

                a {
                    padding: .8rem 1.35rem;

                    &:hover {
                        background-color: #fff;
                    }

                    &.showCheckmark {
                        position: relative;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 1.25rem;
                            top: 0.813rem;
                            width: 1.125rem;
                            height: 0.938rem;
                            //background: url(/-/media/Project/RMHC/Common/check-mark-black.svg) no-repeat right;
                            background: url(../../images/svg/icons-operational-check-mark-black.svg) no-repeat right;
                        }

                    }
                }
            }
        }

        @include breakpoint(medium up) {
            a {
                padding: .93rem 1.35rem;

                &:hover {
                    background-color: $blue-super;
                }
                &:focus {
                    background-color: $blue-super;
                }
            }
        }

    }

    &.menu {
        > {
            li {
                > {
                    a {
                        display: inline-block;
                    }
                }
            }
        }

        li {
            position: relative;
            &.focus{
                a{
                    color:#000!important;                   
                }
            }
            &:last-child{
                .is-dropdown-submenu{
                    right:0;
                    left: auto;
                }
            }
            .arrow{
                position: absolute;
                height:57.6px;
                right:0;
                top:0;
                display:block;
                width:100%;
                text-align: center;
                @include breakpoint(medium down){
                    height:50px;
                }
                &:focus{
                    background-color:#fff;
                    @include breakpoint(medium down){
                        background-color:transparent;
                    }
                }
                @include breakpoint(medium down){
                    right:0;
                    display: block;
                    opacity:1;
                    width:50px
                    
                }
                &:before{
                    @include breakpoint(medium down){
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid $blue-dark;
                    display: inline-block;                   
                    transition: all .5s ease;                       
                    }
                }
            }
            
            &.has-children {        

                > {
                    ul {
                        display: none;

                        li {
                            &.subHeader {
                                @include breakpoint (medium down) {
                                    padding: .7rem 1.6rem;
                                    margin: 0;
                                    color: $blue-dark;
                                    text-transform: uppercase;
                                    line-height: 1;
                                    font-size: .813rem;
                                    font-weight: 800;
                                }

                                p {
                                    @include breakpoint (medium down) {
                                        margin: 0;
                                    }
                                }
                            }

                            a {
                                font-size: 1rem;
                                line-height: 1.38;
                                color: $gray-black-dark;
                                padding: .7rem 1.563rem
                            }
                        }
                    }
                }

                &.active {               
                    .arrow{
                        transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    }
                }

            }

            &.li-en {
                .selectedLang {
                    @include breakpoint(medium up) {
                        position: relative;
                        padding: 1.3rem 2.4rem 1.3rem 1.25rem !important;
                        width:78.8px;
                    }

                    &::after {
                        @include breakpoint(medium up) {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 1.25rem;
                            top: 50%;
                            transform: translateY(-50%);
                            -moz-transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid #fff;
                        }
                    }

                }

                &.is-active {
                    .selectedLang {
                        &::after {
                            border-color: $blue-dark transparent transparent !important;
                        }
                    }
                }
            }

        }
    }
}
@media (min-width: $breakpoint-tablet-up) {
.rmhc-nav{    
        transition: top 0.2s ease-in-out;
}
}
.nav-up {
    top: -98px;
    @include breakpoint(medium down){
        top:0;
    }
}
_:-ms-fullscreen, :root .li-search input {
    padding: 0;
    padding-left: 10px;
}
.has-privecy{
    &.overflow-hidden{            
        .title-bar {
            .for-mobile {
                position: absolute;
                top:0;
                right:0;
            }
        }      
       
    }
}
  @media all and (max-width: 1270px) {    
 nav{
     .top-bar{
         .top-bar-right{
             .dropdown{
                 &.menu{
                     >{
                         li{
                             >{
                                 a{
                                     padding:1.3rem 0.80rem;
                                 }
                             }
                         }
                     }
                 }
             }
         }
     }
 }
  }
  @media all and (max-width: 1180px) {  
    .dropdown.menu li.li-en .selectedLang {
        position: relative;
        padding:1.33rem 1.4rem 1.33rem 0.80rem!important;
        width: 60px;
    }
    nav .top-bar .top-bar-right .dropdown.menu>li.li-search {
        margin: 0;
        padding: 0 0.60rem 0 0;
    }
    .dropdown.menu li.li-en .selectedLang::after{
        right:0.50rem;
    }
    .dropdown.menu li.li-search.show-for-large.opens-left ul{
        right:10px;
    }
    nav{
        .top-bar{
            .top-bar-right{
                .dropdown{
                    &.menu{
                        >{
                            li{
                                >{
                                    a{
                                        padding:1.32rem 0.70rem;
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
     }
     @media all and (max-width: 1115px) {  
        nav .rmhcLogo {
            width: 3.125rem;
            height: 3.5rem;           
            .rmhc-logo{
                width:50px
            }
            .rmhc-title-logo{
                padding: 0.75rem .5rem;
                width: 140px;
            }
        }
        .dropdown.menu li.li-en .selectedLang {
            position: relative;
            padding:1.34rem 1.4rem 1.34rem 0.80rem!important;           
        }
        .dropdown.menu li.li-search.show-for-large.opens-left ul{
            right:8px;
        }
        nav{
            .top-bar{
                .top-bar-right{
                    .dropdown{
                        &.menu{
                            >{
                                li{
                                    >{
                                        a{
                                            padding:1.34rem 0.60rem;
                                            font-size: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        nav .top-bar .top-bar-right .dropdown.menu>li.li-search {
            margin: 0;
            padding: 0 0.5rem 0 0;
        }
         }   
         
         
         @media all and (max-width: 1022px) { 
            nav .top-bar .top-bar-right .dropdown.menu>li.li-search {
               padding: 0 1.563rem;
           }
           nav .top-bar .top-bar-right .dropdown.menu>li>a {
            color: #000;
            padding: 1.125rem 1.563rem;
        }
       }         
       .top-bar {
        .input-group-field{
            &:focus{
                border: 1px solid #ccc
            }
        }
       }

       .keyboard-navigation{
        .dropdown {
            &.menu {
                li {
                   
                    
                        ul.show-menu {
                            opacity: 1;
                            visibility: visible;                        
                            max-height: 600px;
                            overflow: hidden;
                        }
                    
    
                    &.li-search.show-for-large.opens-left {
                        ul {
                            transform-origin: top right;
                            right: 16px;
                            width: 35.063rem;
                            padding:0.375rem 1.563rem;
                            //top: 80%;
                        }
    
                    }
                }
            }
        }
        .dropdown{
            &.menu li{
                &.has-children{
                     > {
                        ul.show-menu{
                            display: block;
                        }
                    }
                }
            }
        }
       }
      .keyboard-navigation{
          nav{
              @include breakpoint(medium down){
                  display:none;
              }
            &.active{
                @include breakpoint(medium down){
                    display:block;
                }
            }
          }
      }
      .header-donate{
          position: relative;
        @include breakpoint (medium down) {
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 30px;
                left: 0;
                bottom: 100%;
                z-index: 1;
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0.89) 89%, rgba(249, 249, 249, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0.89) 89%, rgba(249, 249, 249, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0.89) 89%, rgba(249, 249, 249, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f9f9f9', GradientType=0);
                /* IE6-9 */
    
            }
        }
          .header-search{
              position: relative;
              .search-container{
                  margin:0;
                }                      
                          .search-dropdown{
                              position: absolute;
                              right:0;
                              top:auto;
                              background:#fff;
                              width: 35.063rem;
                              padding:1.563rem;
                              display:none;
                               input#search-term {
                                    &::placeholder {
                                             color: #767676;
                                    }
                               }
                              
                                  .input-group-field{
                                    max-width:100%;
                                    padding: 1.5rem .8rem;
                                    font-family: Muli,sans-serif;
                                    font-size: 1rem;
                                    color: #404042;
                                    height: 50px;
                                    border: 1px solid #ccc;
                                  }
                                  .input-group-button{
                                      .button{
                                        background-color:$blue-dark;
                                        padding: .7rem 1rem;
                                        &:hover{
                                            background-color:#2e548a;
                                        }
                                      }
                                  }
                              
                          }
                      
                    
                      .search-toggle{
                          padding:0 20px;
                          height:57px;
                          cursor: pointer;
                          &:hover{
                              background-color:#fff;
                              svg {
                                  path{
                                    fill:#000;
                                  }
                                  
                              }
                          }
                          &:focus{
                            background-color:#fff;
                            svg {
                                path{
                                  fill:#000;
                                } 
                            }
                        }
                          &.search-open{
                            background-color:#fff;
                            svg {
                                path{
                                  fill:#000;
                                }                                
                            }
                          }
                      }
                  
              
          }
          .donate_btn_container{
            @include breakpoint(medium down){
              width:100%;
              padding:0 1.375rem;
              box-sizing: border-box;
              }
              a{
                  height:56px;
                  line-height:1.5;
                  border-radius:0;
                  @include breakpoint(medium down){
                    display: block;
                  }
              }
          }
      }
      .close-hamberger{
          width:40px;
          height:40px;
          position: absolute;
          right: 8px;
        top:9px;
      }
      .search_mobile{
        padding:1rem 1.563rem;
        position: relative;
        @include breakpoint(medium down){
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 25px;
                left: 0;
                top:85%;
                z-index: 1;
                background: -moz-linear-gradient(top, rgba(249, 249, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(249, 249, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(249, 249, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#00ffffff', GradientType=0);
                /* IE6-9 */
            }
        }
        .input-group-field{
            max-width:100%;              
        }
        .input-group-button{
          .button{
              background-color: #4872ae;
              position: relative;
              padding: .438rem;
              margin-left:-1px;
          }
        }
       
        }
        .nav-backdrop{ 
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                position: fixed;
                left: 0;
                top: 0;                                
                z-index: 2;
        }