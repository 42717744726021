%slickArrow {
    font-size: 0;
    line-height: 0;
    position: relative;
    top: 50%;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    z-index: 2;
    border-radius: 50%;
    border: 1px solid $blue-dark;
    box-sizing: border-box;
}
.horizontal-gallery-component {
    position: relative;
    z-index: 0;
    background-color: #f1f4f9;
    padding: 3.125rem 0;

    @include breakpoint(medium down) {
        padding: 1.875rem 0;
    }
    .scroller-area {
        @include breakpoint(medium down) {
            padding: 0;
        }
    }

    .section-header {
        h2 {
            font-size: 2rem;
            line-height: 1.14;
            font-family: 'Raleway', sans-serif;
            font-weight: 800;
            color: $gray-black-dark;
            margin-bottom: 2rem;
            @include breakpoint(medium down) {
                font-size: 1.625rem;
                font-weight: 800;
                line-height: 1.11;
            }
        }
    }



    .left-arrow {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2.813rem;

        @include breakpoint (medium down) {
            padding-left: 0.313rem;
        }

        // &:before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     height: 100%;
        //     width: 4.5rem;
        //     z-index: 1;
        //     background: -moz-linear-gradient(left, rgba(241, 244, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
        //     background: -webkit-linear-gradient(left, rgba(241, 244, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
        //     background: linear-gradient(to right, rgba(241, 244, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
        //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f4f9', endColorstr='#00ffffff', GradientType=1);

        // }

        .slick-prev {
            @extend %slickArrow;
            padding-left: 0.375rem;
            left: 0;
            display: none;

            &:before {
                content: '';
                width: 0.625rem;
                height: 0.625rem;
                border-left: 2px solid $blue-dark;
                border-bottom: 2px solid $blue-dark;
                display: inline-block;
                transform: rotate(45deg);
            }
        }

        &.slick-disabled {
            &:before {
                display: none;
            }
        }
    }

    .right-arrow {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 2.5rem;

        @include breakpoint (medium down) {
            width: 2.813rem;
            padding-right: 0.313rem;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 2rem;
            z-index: 1;
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(241, 244, 249, 1) 60%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(241, 244, 249, 1) 60%);
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(241, 244, 249, 1) 60%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f1f4f9', GradientType=1);

        }

        .slick-next {
            @extend %slickArrow;
            right: 0;
            padding-right: 0.375rem;
            display: none;

            &:before {
                content: '';
                width: 0.625rem;
                height: 0.625rem;
                border-top: 2px solid $blue-dark;
                border-right: 2px solid $blue-dark;
                display: inline-block;
                transform: rotate(45deg);
            }
        }

        &.slick-disabled {
            &:before {
                display: none;
            }
        }
    }
    .action-container {
        margin-top: 1.875rem;
        .button {
            margin: 0;

            &.hollow {
                border: 0.063rem solid $blue-dark;
                color: $blue-dark;
                font-family: 'Muli', sans-serif;
                font-size: 1rem;
                font-weight: 700;
                padding: 1rem 1.25rem;
                border-radius:2px;
            }

            &.primary {
                background-color: $blue-dark;
                border-radius: 2px;
                font-family: 'Muli', sans-serif;
                font-size: 1rem;
                font-weight: 700;
                padding: 1rem 1.25rem;
            }
        }
    }

}

//Aricle preview
.article-preview {
    .inner-content {
        height: 100%;
        position: relative;
        padding-bottom: 5.375rem;

        @include breakpoint(small down) {
            height: auto;
        }

        .img-container {
            height: 14rem;

            @include breakpoint(medium down) {
                height: auto;
            }

            img {
                object-fit: cover;
                height: 14rem;
                width: 100%;

                @include breakpoint(medium down) {
                    height: 430px;
                }

                @include breakpoint(small down) {
                    height: 275px;
                }
            }
        }

        .description {
            padding: 1.875rem;
            text-align: left;

            h4 {
                word-wrap: break-word;
                font-size: 1rem;
            }

            .img-title-container {
                font-family: 'Raleway', sans-serif;
                line-height: 1.36;
                font-size: 1.125rem;
                font-weight: 700;
                color: $gray-black-dark;
                word-wrap: break-word;
                white-space: normal;

                @include breakpoint(medium down) {
                    font-size: 1rem;
                    line-height: normal;
                }

                h4 {
                    word-wrap: break-word;
                    font-size: 1rem;
                }
            }

            .action-container {
                margin-top: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                box-sizing: border-box;
                padding: 0 30px 30px 30px;

                a {
                    font-family: 'Caveat', sans-serif;
                    font-size: 1.375rem;
                    font-weight: bold;
                    color: $blue-dark;
                    border-bottom: 1px solid $blue-dark;
                    /* line-height: 3.469rem; */
                    display: inline-block;
                    position: relative;
                    /* padding-right: 1.25rem;  */
                    white-space: normal;

                    &:hover {
                        color: #2e548a;
                    }

                    &:focus {
                        color: #2e548a;
                    }

                    .arrow_icon {
                        display: inline-block;

                        &::after {
                            content: '>';
                            position: absolute;
                            display: block;
                            right: 0;
                            /* top: 50%; */
                            transform: translateY(-50%);
                            -moz-transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            margin-top: -7px;
                        }
                    }


                    @include breakpoint(small down) {
                        /* line-height: 2.688rem; */
                    }
                }
            }
        }
    }
}

.horizontal-gallery {
    .item {
        background-color: #fff;
        border-left: 1.125rem solid #f1f4f9;
        border-right: 1.125rem solid #f1f4f9;
        box-sizing: border-box;

        @include breakpoint(medium down) {
            border-left: 0.563rem solid #f1f4f9;
            border-right: 0.563rem solid #f1f4f9;
        }
    }

    &.article-preview {
        .inner-content {
            height: 100%;
        }
    }
}

//File block
.horizontal-gallery-file {
    .file-block-content{
        text-align: left;
    }
    &.file-block {
        // padding:10px 0 20px;
        .item{
            background-color:#fff;
            box-shadow: 0 7px 8px 0 rgba(91,101,115,.08);
            .file-block-content {
                // margin: 0 0.75rem;
                box-shadow:none;
                background-color:none;
                text-align: left;
                a{
                    img{
                        width: 60px;
                        height: 74px;
                    }
                }
            }
        }


    }
}

//Article Preview Block
.article-preview {
    &.article-preview-grid {
        .item {
            width: 33.33333%;
            background-color: #f1f4f9;
            border-left: 1.125rem solid #fff;
            border-right: 1.125rem solid #fff;
            box-sizing: border-box;
            margin: 0 0 2.25rem;

            @include breakpoint(medium down) {
                width: 100%;
                border: none;
            }
        }
    }
}

//Article Preview Promoted Block
.article-preview {
    &.article-preview-promoted-grid {
        .item {
            width: 100%;
            margin: 0 0 3.125rem;

            .inner-content {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                margin: 0;
                padding: 0;
                background-color: #fff;

                @include breakpoint(medium down) {
                    flex-flow: row wrap;
                    align-items: normal;
                }

                .description {
                    padding: 40px 40px 40px 3.8rem;
                    flex-basis: 100%;

                    @include breakpoint(medium down) {
                        padding: 2.313rem;
                    }

                    @include breakpoint(small down) {
                        padding: 1.875rem;
                    }

                    h4, h3 {
                        margin: 0 0 0.938rem;
                        font-family: 'Raleway', sans-serif;
                        font-size: 1.125rem;
                        font-weight: bold;
                        line-height: 1.31;
                        color: $gray-black-dark;
                        word-wrap: break-word;
                    }

                    .img-title-container {
                        font-family: 'Raleway', sans-serif;
                        font-size: 1rem;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        word-wrap: break-word;

                        @include breakpoint (small down) {
                            font-size: 1rem;
                        }
                    }

                    .action-container {
                        margin-top: 0.313rem;
                        position: static;
                        padding: 0;
                    }
                }

                .img-container {
                    flex: 0 0 480px;
                    height: auto;

                    @include breakpoint(medium down) {
                        flex: 0 0 100%;
                    }

                    @include breakpoint(small down) {
                        flex: 0 0 100%;
                    }

                    img {
                        height: 360px;

                        @include breakpoint(medium down) {
                            height: 430px;
                        }

                        @include breakpoint(small down) {
                            height: 275px;
                        }
                    }
                }
            }
        }
    }
}

//for IE Only
@media all and (-ms-high-contrast:none) {
    .custom-object-fit {
        position: relative;
        background-size: cover;
        background-position: center center;

        img {
            opacity: 0;
        }
    }
}

@media all and (max-width:47.93625em) and (-ms-high-contrast:none) {
    .article-preview {
        &.article-preview-promoted-grid {
            .item {
                .inner-content {
                    display: block;
                    flex: none;

                    .img-container {
                        text-align: center;
                    }
                }
            }
        }
    }

}
.horizontal-gallery-component {
    .scroller-area {
        .grid-x {
            //     display: grid;
            // grid-template-columns: 1fr 1fr 1fr;
            // -ms-grid-columns:1fr 1fr 1fr;
            // grid-template-rows: auto 1fr auto;
            // -ms-grid-rows:auto 1fr auto;
            // @include breakpoint(medium down){
            //     grid-template-columns: 1fr;
            //     grid-template-rows: 0.3fr 1fr auto 1fr;
            // }
            //{
            .cell {
                padding: 0;
                overflow-x: hidden;
                overflow-y: hidden;
                position: relative;
                // grid-area: cell;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: -1px;
                    top: 0;
                    height: 100%;
                    width: 50px;
                    z-index: 1;
                    background: linear-gradient(to right,rgba(255,255,255,0) 0,#f1f4f9 100%);

                    @include breakpoint(medium down) {
                        width: 20px;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: -1px;
                    top: 0;
                    height: 100%;
                    width: 50px;
                    z-index: 1;
                    background: linear-gradient(to right,rgba(255,255,255,0) 0,#f1f4f9 100%);
                    transform: rotate(-180deg);

                    @include breakpoint(medium down) {
                        width: 30px;
                    }
                }

                .horizontal-gallery, .horizontal-gallery-file {
                    position: relative;
                    width: 100%;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    white-space: nowrap;
                    user-select: none;
                    overflow: -moz-scrollbars-none;
                    -ms-overflow-style: none;
                    text-align: center;

                    &::-webkit-scrollbar {
                        width: 0 !important;
                        height: 0 !important;
                        -webkit-appearance: none;
                    }

                    &.active {
                        cursor: grabbing;
                        cursor: -webkit-grabbing;
                    }

                    .item, .cell {
                        display: inline-block;
                        width: 376px;
                        margin: 0 1em;
                        border: none;
                        vertical-align: top;

                        @include breakpoint(medium down) {
                            width: 316px;
                            margin: 0 0.6em;
                        }

                        @include breakpoint(medium down) {
                            width: 282px;
                            margin: 0 0.5em;
                        }

                        &:first-child {
                            margin-left: 6.6%;
                        }

                        &:last-child {
                            margin-right: 6.6%;
                        }
                    }
                }

                .horizontal-scroll {
                    pointer-events: none;
                    margin: auto;

                    .item {
                        .inner-content {
                            .description {
                                .action-container {
                                    a {
                                        pointer-events: auto;
                                    }
                                }
                            }
                        }

                        .file-block-content {
                            a {
                                img {
                                    pointer-events: auto;
                                }
                                h3 {
                                    pointer-events: auto;
                                }
                                h4 {
                                    pointer-events: auto;
                                }
                            }
                        }
                        width: 326px;
                        &:first-child {
                            margin-left: 1em;

                            @include breakpoint(small down) {
                                margin-left: 2.6em;
                            }
                        }

                        &:last-child {
                            margin-right: 1em;

                            @include breakpoint(small down) {
                                margin-right: 2.6em;
                            }
                        }

                        @include breakpoint(medium down) {
                            width: 326px;
                            margin: 0 1em;
                        }

                        @include breakpoint(small down) {
                            width: 265px;
                            margin: 0 2.6em;
                        }
                    }
                }
            }
        }
    }

    .horizontal-arrow {
        position: absolute;
        top: 50%;
        z-index: 2;
        margin: 0;
        cursor: pointer;

        @include breakpoint(small down) {
            margin: 0;
        }

        &.horizontal-arrow-left {
            left: 0;
        }

        &.horizontal-arrow-right {
            right: 0;
        }
    }

    &.horizontal-gallery-carousel {
        .grid-container {
            position: relative;
            padding: 0;

            &.scroller-area {
                .grid-x {
                    .cell {
                        &:before, &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.file-block {
    .file-block-content {
        h4 {
            font-size: 1rem;
            white-space: normal;

            a {
                white-space: normal;
            }
        }
    }
}