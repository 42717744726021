.find-a-chapter {
    position: relative;

    div.find-a-chapter-left {
        overflow: auto;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0 !important
        }
    }

    #resultParentDiv {
        @include breakpoint(medium down) {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
        }
    }

    div.find-a-chapter-left,
    .search-result-filter {
        background-color: $blue-dark;
        color: $white;

        .inner-content {
            padding: 7rem 3.2rem 2rem 6.2rem;

            @include breakpoint(medium only) {
                padding: 7rem 2rem;
            }

            @include breakpoint(small down) {
                padding: 4rem 1.5rem 2rem 1.5rem;
            }
        }

        h1 {
            font-family: 'Raleway', sans-serif;
            font-size: 2rem;
            font-weight: 800;
            line-height: 1.14;
            color: $white;
            margin-top: 3.125rem;

            @include breakpoint (small down) {
                font-size: 1.625rem;
                line-height: 1.16;
            }
        }

        p {
            font-family: $primary-font;
            font-size: $medium;
            font-weight: $regular;
            line-height: $line-height-5;
            color: $white;
        }

        .field-label {
            margin-bottom: 8px;
            display: block;
            font-family: 'Muli', sans-serif;
            font-size: 1rem;
            font-weight: normal;

            @include breakpoint(small down) {
                margin-bottom: 5px;
            }
        }

        p.non-us {
            font-family: 'Muli', sans-serif;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.67;
            color: $white;
            display: inline-block;
            border-bottom: 1px solid $white;

            @include breakpoint (small down) {
                font-size: 0.813rem;
            }
        }

        div.find-a-chapter-form {
            h3 {
                font-family: 'Raleway', sans-serif;
                font-size: 1.375rem;
                font-weight: 800;
                line-height: 1.31;
                color: #fff;
                margin: 0;

                @include breakpoint(medium down) {
                    display: none;
                }
            }

            label {
                font-family: 'Raleway', sans-serif;
                font-size: 1.625rem;
                font-weight: 800;
                line-height: 1.31;
                color: $white;
                position: relative;

                &.field-label {
                    font-family: $primary-font;
                    font-size: $normal;
                    font-weight: $regular;
                }
            }

            #geoLocatorSearchBtn {
                position: absolute;
                bottom: 2rem;
                right: 1rem;
            }

            input {
                font-family: $primary-font;
                font-size: $normal;
                background-color: $white;
                color: #000000;
                height: 3.5rem;

                &:focus {
                    border-color: transparent;
                    box-shadow: none;
                }
            }

            #findAChapterSearchTxt {
                padding-right: 40px;
            }

            select.country-slect {
                height: 3.5rem;
                background-image: url(../../images/svg/black-bigger.svg);
                font-family: 'Muli', sans-serif;
                font-size: 1.125rem;
                font-weight: normal;
                line-height: normal;
                color: $gray-black-dark;
                margin-bottom: 0;

                &:focus {
                    box-shadow: none;
                    border-color: transparent;
                }
            }

            select {
                height: 3.5rem;
                background-image: url(../../images/svg/black-bigger.svg);
                font-family: 'Muli', sans-serif;
                font-size: 1rem;
                font-weight: normal;
                line-height: normal;
                color: $gray-black-dark;
                width: 136px;

                &:focus {
                    box-shadow: none;
                    border-color: transparent;
                }
            }

            .label-container {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-family: 'Muli', sans-serif;
                font-size: 1rem;
                color: $white;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                font-weight: normal;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:checked {
                        ~ {
                            .checkmark {
                                &:after {
                                    display: block;
                                }
                            }
                        }
                    }

                    &:focus {
                        ~ {
                            .checkmark {
                                outline: 2px solid #333;
                            }
                        }
                    }
                }

                .checkmark {
                    &:after {
                        left: 9px;
                        top: 5px;
                        width: 8px;
                        height: 13px;
                        border: solid $blue-dark;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: -2px;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: $white;

                @include breakpoint (small down) {
                    top: 0px;
                }

                @include breakpoint (medium only) {
                    top: 0px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }

            button.find-a-chapter-form-search-button {
                background-color: $blue-dark;
                border-radius: 2px;
                border: 1.8px solid $white;
                font-family: $primary-font;
                font-size: $normal;
                font-weight: bold;
                color: $white;
            }

            input.find-a-chapter-form-mobile-search {
                border: solid 1px $gray-lighter;
                background-color: $white;
                display: inline;
                width: 80%;
            }

            button.find-a-chapter-form-mobile-search-btn {
                height: 56px;
                border-radius: 2px;
                border: solid 1.8px $white;
                padding: 1rem;
                background-color: $blue-dark;
                margin-top: 10px;
            }

            .nous-chapter-link {
                margin-bottom: 50px;

                @include breakpoint(medium down) {
                    margin: 20px 0 0
                }

                a {
                    font-family: 'Muli', sans-serif;
                    font-size: 1.125rem;
                    font-weight: 600;
                    line-height: 1.67;
                    color: #fff;
                    text-decoration: underline;
                    display: inline-block;
                }
            }

            .us-chapter-section .checkboxes .checkboxe-item {
                &:focus-visible {
                    outline: 2px solid #000;
                }

                > span {
                    margin-left: 5px;
                    filter: drop-shadow(0px 0px 10px #fff);
                }
            }
        }

        div.find-a-chapter-results {
            @include breakpoint(medium down) {
                position: fixed;
                left: 0;
                bottom: -7px;
                width: 100%;
                z-index: -1;
                overflow-x: scroll; /* Must be 'scroll' not 'auto' */
                -webkit-overflow-scrolling: touch;
            }

            &.list-view-container {
                bottom: auto;
                top: 132px;
                height: 80%;
                overflow: auto;
            }

            .result_count {
                font-family: $primary-font;
                font-size: $medium;
                font-weight: $x-bold;
                color: $white;
                line-height: 1.67;

                &:focus-visible {
                    outline: 2px solid #000 !important;
                }
            }

            .cell {
                &:focus-visible {
                    outline: 2px solid #000;
                }
            }

            div.find-a-chapter-results-card-main {

                @include breakpoint(medium down) {
                    display: none;
                    white-space: nowrap;
                    // overflow-x: auto;
                    // overflow-y: hidden;
                    // overflow: -moz-scrollbars-none;
                    // -ms-overflow-style: none;
                    user-select: none;
                    padding: 10px 0;

                    &::-webkit-scrollbar {
                        width: 0 !important
                    }

                    &.list-view {
                        white-space: normal;
                        padding: 7px 0 20px;
                    }
                }

                &.list-view {
                    @include breakpoint(medium down) {
                        white-space: normal;
                        flex-flow: row wrap;
                    }

                    div.find-a-chapter-results-card {
                        @include breakpoint(medium down) {
                            display: block;
                            width: auto;
                            margin: 5px 20px;
                            flex: 0 0 calc(100% - 40px);
                        }
                    }
                }

                div.find-a-chapter-results-card {
                    box-sizing: border-box;
                    border: 1px solid transparent;
                    padding: 0;

                    &:focus-visible {
                        outline: 2px solid #000;
                    }

                    &.active-marker-selected {
                        //box-shadow: 0 0 10px #0a2f65;
                        //border-top: 10px solid $yellow;
                        transform: scale(1.035);
                        -moz-transform: scale(1.035);
                        -webkit-transform: scale(1.035);

                        @include breakpoint(medium down) {
                            box-shadow: 0 0 5px #666;
                        }
                    }

                    @include breakpoint(medium down) {
                        display: inline-block;
                        margin-bottom: 0;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                        width: 310px;
                        flex: 0 0 310px;
                    }

                    &:first-child {
                        @include breakpoint(medium down) {
                            margin-left: 1.25rem;
                        }
                    }

                    &:last-child {
                        @include breakpoint(medium down) {
                            margin-right: 1.25rem;
                        }
                    }

                    h5 {
                        font-family: $primary-font;
                        font-size: $medium;
                        font-weight: $bold;
                        color: $gray-black-dark;
                        white-space: normal;
                        line-height: normal;
                    }

                    p {
                        font-family: $primary-font;
                        font-size: $normal;
                        color: $gray-black-dark;
                        white-space: normal;
                        margin-bottom: 2px;
                    }

                    span {
                        font-family: $primary-font;
                        font-size: 0.813rem;
                        color: $red-dark;
                        font-weight: $regular;
                        line-height: $line-height-4;
                        display: block;
                        white-space: normal;
                        border-bottom: 1px solid #e1e1e1;
                        margin-bottom: 10px;
                        margin-top: 15px;
                    }

                    a {
                        font-family: $primary-font;
                        font-size: $normal;
                        font-weight: $x-bold;
                        line-height: $line-height-4;
                        color: $blue-dark;
                        text-decoration: underline;
                        display: inline-block;
                        white-space: normal;
                        word-break: break-all;
                    }

                    div.find-a-chapter-results-card-inner {
                        p {
                            font-family: 'Muli', sans-serif;
                            font-size: 0.813rem;
                            font-weight: normal;
                            line-height: normal;
                            color: $gray-black-dark;
                            white-space: normal;
                            margin: 0;
                        }

                        a {
                            font-family: 'Muli', sans-serif;
                            font-size: 1rem;
                            font-weight: 600;
                            line-height: 1.31;
                            color: $blue-dark;
                            text-decoration: underline;
                            border: none;
                            white-space: normal;
                        }
                    }

                    .inner-container {
                        position: relative;
                        padding: 20px 47px;

                        &:focus-visible {
                            outline: 2px solid #000;
                        }

                        .location-icon {
                            position: absolute;
                            left: 20px;
                            top: 21px;
                        }

                        .sub-address {
                            position: relative;
                            border-top: 1px solid #e1e1e1;
                            padding: 18px 20px 10px;

                            .sub-location-icon {
                                position: absolute;
                                left: 0;
                                top: 13px;
                            }
                        }
                    }
                }
            }
        }

        .horizontal-search-result-card-component {
            position: relative;
            z-index: 0;
            padding: 3.75rem 0;

            @include breakpoint(medium down) {
                padding: 2.5rem 0;
            }
            //   .full{
            //     padding-left: 5.5rem;
            //   }
            .scroller-area {
                @include breakpoint(medium down) {
                    padding: 0;
                }
            }

            .section-header {
                h2 {
                    font-size: 2.625rem;
                    line-height: 1.14;
                    font-family: 'Raleway', sans-serif;
                    font-weight: 800;
                    margin-bottom: 4.063rem;
                    color: $gray-black-dark;

                    @include breakpoint(medium down) {
                        font-size: 2.375rem;
                        font-weight: 800;
                        line-height: 1.11;
                        margin-bottom: 2.813rem;
                    }
                }
            }



            .left-arrow {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 2.813rem;

                @include breakpoint (medium down) {
                    padding-left: 0.313rem;
                }

                .slick-prev {
                    @extend %slickArrow;
                    padding-left: 0.375rem;
                    left: 0;
                    display: none;

                    &:before {
                        content: '';
                        width: 0.625rem;
                        height: 0.625rem;
                        border-left: 2px solid $blue-dark;
                        border-bottom: 2px solid $blue-dark;
                        display: inline-block;
                        transform: rotate(45deg);
                    }
                }

                &.slick-disabled {
                    &:before {
                        display: none;
                    }
                }
            }

            .right-arrow {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 2.5rem;

                @include breakpoint (medium down) {
                    width: 2.813rem;
                    padding-right: 0.313rem;
                }

                .slick-next {
                    @extend %slickArrow;
                    right: 0;
                    padding-right: 0.375rem;
                    display: none;

                    &:before {
                        content: '';
                        width: 0.625rem;
                        height: 0.625rem;
                        border-top: 2px solid $blue-dark;
                        border-right: 2px solid $blue-dark;
                        display: inline-block;
                        transform: rotate(45deg);
                    }
                }

                &.slick-disabled {
                    &:before {
                        display: none;
                    }
                }
            }




            .action-container {
                margin-top: 4.375rem;

                @include breakpoint(medium down) {
                    margin-top: 3.125rem;
                }

                .button {
                    margin: 0;

                    &.hollow {
                        border: 2px solid $blue-dark;
                        color: $blue-dark;
                        font-family: 'Muli', sans-serif;
                        font-size: 1.125rem;
                        font-weight: 700;
                        padding: 0.96em 1.9rem;
                        border-radius: 2px;
                    }

                    &.primary {
                        background-color: $blue-dark;
                        font-family: 'Muli', sans-serif;
                        font-size: 1.125rem;
                        font-weight: 700;
                        padding: 1em 3rem;
                    }
                }
            }

            @include breakpoint (small down) {
                div.horizontal-search-card-file {
                    p {
                        color: $gray-black-dark;
                        font-size: 0.875rem;
                    }

                    a {
                        color: $blue-dark;
                        font-size: 0.875rem;
                    }

                    .callout {
                        border: 0;
                    }
                }
            }
        }
    }
    //File block
    .horizontal-search-card-file {
        &.file-block {
            background-color: transparent;

            .file-block-content {
                margin: 0;
            }
        }
    }

    div.find-a-chapter-right {
        position: sticky;
        position: -webkit-sticky;
        top: 0;

        @include breakpoint(medium down) {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9;
            height: 100% !important;
            background-color: $blue-super;
            overflow: hidden;
            display: none;
            padding-top: 122px;
        }

        &.sticky-top-ie {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 1;
        }

        &.sticky-map-ie {
            position: absolute;
            bottom: 0;
            right: 0;
            top: auto;
        }

        div#myMap {
            height: 100%;
            overflow: hidden;

            @include breakpoint(medium down) {
                width: 100%;
                position: absolute;
            }

            canvas {
                height: 100% !important;
                width: 100% !important;
            }
        }

        div.icons-finder-container {
            height: 55px;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            flex-flow: row nowrap;
            background-color: $white;
            padding: 0 20px;
            align-items: center;

            @include breakpoint(medium down) {
                display: none;
            }

            span {
                padding: 0 10px 0 10px;
                font-family: $primary-font;
            }
        }

        .bottom-right.subcontrol-container {
            bottom: 10px !important;

            @include breakpoint(medium down) {
                bottom: auto !important;
            }
        }

        .azure-maps-control-button {
            background-color: transparent;
            box-shadow: none;
            width: 40px;
            height: 40px;
            background-image: none;
            border: 2px solid #4872ae;
            border-radius: 50%;
            margin: 4px 0;
            position: relative;
            background-image: none !important;
            box-shadow: none;


            &:focus {
                background-color: transparent;
            }

            &.zoom-in {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    border: none;
                    width: 14px;
                    height: 3px;
                    background-color: #4872ae;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &:after {
                    content: '';
                    border: none;
                    width: 3.5px !important;
                    height: 14px !important;
                    background-color: #4872ae;
                    left: 50% !important;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                }
            }

            &.zoom-out {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    border: none;
                    width: 14px;
                    height: 3px;
                    background-color: #4872ae;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

    .has-privecy {
        .find-a-chapter {
            .find-a-chapter-right {
                top: 94px;

                @include breakpoint(medium down) {
                    top: 0;
                }
            }
        }
    }

.result-header-mobile {
    background-color: $blue-dark;
    padding: 18px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    .top {
        display: flex;
        flex-flow: row nowrap;

        .back {
            width: 40px;
            height: 40px;
            margin-left: -12px;
            margin-top: -10px;

            button {
                display: block;
                line-height: 38px;
                text-indent: -9999px;
                position: relative;
                width: 40px;

                &::before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    border-left: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    display: block;
                    position: absolute;
                    left: 15px;
                    top: 12px;
                    transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }
            }
        }

        .right-link {
            margin-left: auto;

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 10px;

                    button {
                        display: block;
                        color: #fff;
                        font-family: $primary-font;
                        font-size: $x-small;
                        font-weight: $normal-weight;
                        line-height: normal;

                        svg {
                            display: none;
                            vertical-align: top;
                        }

                        &.ListView {
                            svg.list-view-icon {
                                display: inline-block;
                            }
                        }

                        &.MapView {
                            svg.map-view-icon {
                                display: inline-block;
                            }
                        }
                    }

                    &.filter {
                        button {
                            svg {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom {
        .input-group {
            margin: 0;

            .twitter-typeahead {
                width: 100%
            }

            .input-group-field {
                height: 56px;
                box-shadow: none;
                border: 1px solid #999999;
                font-size: 1rem;
                font-family: $primary-font;
            }

            .input-group-button {
                .button.hollow {
                    border: .087em solid #fff;
                    border-radius: 0;
                    margin-left: 8px;
                    width: 56px;
                    padding: 0;

                    &:hover {
                        svg {
                            path {
                                fill: #4872ae
                            }
                        }
                    }

                    &:focus {
                        svg {
                            path {
                                fill: #4872ae
                            }
                        }
                    }
                }
            }
        }
    }
}

    .search-result-visible {
        @include breakpoint(medium down) {
            overflow: hidden;
        }

        .find-a-chapter {
            // .find-a-chapter-right {
            //     @include breakpoint(medium down) {
            //         visibility: visible;
            //         left:0;
            //     }
            // }

            div.find-a-chapter-left {
                div.find-a-chapter-results {
                    @include breakpoint(medium down) {
                        z-index: 99;
                    }
                }
            }
        }
    }

    .filter-open {
        &::before {
            content: '';
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 999;
        }
    }

.search-result-filter {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 999;
    background-color: $blue-dark;
    padding: 0 !important;
    display: none;

    .filter-header {
        background-color: $blue;
        height: 64px;
        text-align: center;
        color: #fff;
        font-family: $primary-font;
        font-size: $medium;
        line-height: 3.60;
        position: relative;

        h6 {
            color: #fff;
            font-family: $primary-font;
            font-size: $medium;
            line-height: 3.60;
            margin: 0;
        }

        button {
            width: 26px;
            height: 26px;
            display: block;
            position: absolute;
            left: 20px;
            top: 17px;
            z-index: 9;

            &:before {
                content: '';
                width: 2px;
                height: 25px;
                background: #fff;
                position: absolute;
                left: 10px;
                top: 0;
                display: block;
                transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }

            &:after {
                content: '';
                width: 2px;
                height: 25px;
                background: #fff;
                position: absolute;
                left: 10px;
                top: 0;
                display: block;
                transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }
    }

    .filter-body {
        padding: 20px;

        .checkboxes {
            .checkboxe-item.check-group-mobile {
                span {
                    margin-left: 5px;
                    filter: drop-shadow(0px 0px 10px #fff);
                }
            }
        }

        label {
            font-family: $primary-font;
            font-size: 1rem;
            font-weight: normal;
            color: #fff;
        }

        .radiusDropdown {
            width: 136px;
            border: solid 1px #979797;
            border-radius: 2px;
            box-shadow: none;
        }
    }
}

    .us-chapter-section {
        .input-geo {
            .cell {
                padding: 0 5px;

                @include breakpoint(small down) {
                    padding: 0;
                }

                &:first-child {
                    padding-left: 0
                }

                &:last-child {
                    padding-right: 0
                }
            }
        }

        .twitter-typeahead {
            display: block !important;
        }
    }

    .marker-collection-container {
        .marker-container {
            img {
                width: 24px;
                height: 31px;
            }
        }
    }
    /****For Modal start****/
.reveal {
    &.modal {
        padding: 90px 60px;
        outline: none;
        border: none;

        @include breakpoint(medium down) {
            width: 85%;
            height: auto;
            min-height: auto;
            transform: translateX(-50%);
            left: 50%;
            margin: 0;
            padding: 80px 40px;
        }

        h4 {
            font-family: 'Raleway',sans-serif;
            font-size: 26px;
            font-weight: 800;
            line-height: 1.31;
            color: $gray-black-dark;

            @include breakpoint(small down) {
                font-size: 18px
            }
        }

        p {
            font-family: 'Muli',sans-serif;
            font-size: 18px;
            font-weight: normal;
            line-height: normal;
            color: $gray-black-dark;

            @include breakpoint(small down) {
                font-size: 16px
            }
        }

        .location-marker {
            margin-bottom: 20px
        }
    }

    .close-button {
        width: 40px;
        height: 40px;
        outline: none;
        top: 20px;
        right: 20px;
    }

    &.hospitalPartner-reveal {
        top: 2.5%;
        height: auto;
        min-height: unset;
        width: 90%;
        margin: auto;
        padding: 2rem;
        font-family: $primary-font;

        .hospitalPartner-modal_container {
            background-color: #fff;

            h5 {
                font-weight: $xxx-bold;
                font-family: $primary-font;
                font-size: $medium;
            }

            ol {
                margin-top: 1rem;

                li {
                    margin-top: 0.5rem;
                    font-family: $primary-font;
                    font-size: $normal;
                }
            }
        }
    }
}
    .reveal.hospitalPartner-reveal .close-button {
        right: 5px;
        top: 5px;
    }
    /****For Modal end****/


    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        .find-a-chapter div.find-a-chapter-left .inner-content {
            padding: 7rem 2rem;
        }

        .find-a-chapter div.find-a-chapter-right div.icons-finder-container {
            padding: 0 10px;
        }
    }

    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
        .find-a-chapter-left {
            height: calc(100dvh - 56px) !important;
        }

        div.find-a-chapter-results-card-main {
            display: none !important;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 1080px) and (orientation: landscape) {
        .find-a-chapter {
            div.find-a-chapter-left {
                height: 100dvh !important;
            }
        }

        #myMap {
            display: block !important;
        }

        .find-a-chapter {
            div.find-a-chapter-left {
                div.find-a-chapter-results {
                    div.find-a-chapter-results-card-main {
                        white-space: normal;
                        flex-flow: row wrap;
                    }
                }
            }
        }

        .find-a-chapter {
            div.find-a-chapter-left {
                div.find-a-chapter-results {
                    div.find-a-chapter-results-card-main {
                        div.find-a-chapter-results-card {
                            display: block;
                            width: auto;
                            margin: 5px 20px;
                            flex: 0 0 calc(100% - 40px);
                        }
                    }
                }
            }
        }

        .find-a-chapter {
            div.find-a-chapter-left {
                div.find-a-chapter-results {
                    bottom: auto;
                    top: 70px;
                    height: 80%;
                    overflow: auto;

                    &.list-view-container {
                        display: none;
                    }
                }
            }
        }

        .result-header-mobile {
            .bottom {
                position: absolute;
                width: 65%;
                left: 45px;
                top: 7px;
            }
        }

        .result-header-mobile {
            .top {
                .back {
                    margin-top: -6px;

                    a {
                        margin-top: 2px;
                    }
                }
            }
        }

        .result-header-mobile {
            .top {
                .right-link {
                    ul {
                        height: 18px;

                        li {
                            &:first-child {
                                display: inline-block
                            }
                        }
                    }
                }
            }
        }

        .result-header-mobile {
            .top {
                align-items: center;
            }
        }

        .search-result-filter {
            .find-a-chapter-form {
                display: flex;
                flex-flow: row wrap;
            }

            .filter-body {
                padding: 20px 20px 10px;
            }

            .input-geo {
                width: 30%;

                .cell {
                    width: 100%
                }
            }

            .checkbox-container {
                width: 70%;

                .margin-top-1 {
                    margin-top: 0.5rem !important;
                }
            }

            .find-a-chapter-form-search-button {
                margin-top: 1rem !important;
            }
        }
    }

    div.tt-menu {
        background-color: $white;
        color: #404042;
        font-family: 'muli',sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1;
        width: 100%;
        border: 1px solid #ccc;
        border-top: none;

        .tt-suggestion {
            padding: 15px;
            border-bottom: 1px solid #ccc;
            color: #404042;
            font-size: 1rem;
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }

            &.tt-cursor {
                background-color: $gray-blue;
                color: #fff;
            }
        }
    }
    //Mobile result card
.find-a-chapter-results-card-main-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 0;
    overflow: scroll;
    z-index: 9999;

    &.list-view-container {
        display: block;
        flex: none;
        white-space: normal;
        height: 100%;
        overflow: auto;
        position: relative;
        z-index: 999;
        padding: 20px;
        background: #f1f4f9;


        .find-a-chapter-results-card {
            width: 100%;
            margin: 0 0 5px;

            &.active-marker-selected {
                margin: 15px 0;
            }

            .inner-container {
                padding: 15px 15px 10px 35px;
                height: auto;

                .right-col {
                    height: auto;
                    position: static;

                    .contact-icons {
                        position: relative;
                        width: 100%;
                        padding: 1rem 0 0;

                        @include breakpoint(medium up) {
                            width: 50%;
                            margin: auto;
                        }
                    }

                    .hospital-partner-btn-container {
                        min-height: unset;
                        position: relative;
                        bottom: unset;
                    }

                    .buffer-element {
                        display: none;
                    }
                }
            }
        }
    }

    .find-a-chapter-results-card {
        margin-bottom: 0;
        margin-left: .5rem;
        margin-right: .5rem;
        width: 310px;
        flex: 0 0 310px;
        padding: 0;
        border: none;

        &.active-marker-selected {
            box-shadow: 0 0 5px #666;
            transform: scale(1.035);
            -moz-transform: scale(1.035);
            -webkit-transform: scale(1.035);
        }

        .inner-container {
            position: relative;
            padding: 15px 15px 5px 35px;
            height: 100%;

            .location-icon {
                position: absolute;
                left: 13px;
                top: 15px;
            }

            .right-col {
                height: 100%;
                position: relative;

                h5 {
                    font-family: $primary-font;
                    font-size: $medium;
                    font-weight: $xx-bold;
                    color: #404042;
                    white-space: normal;
                    line-height: normal;
                }

                p {
                    font-family: $primary-font;
                    font-size: $normal;
                    color: #404042;
                    white-space: normal;
                    margin-bottom: 2px;
                }

                .hospital-partner-btn-container {
                    font-family: $primary-font;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                    height: 3rem;
                    bottom: 5.1rem;
                    right: 10px;
                    margin: 1rem 0 0;
                }

                .buffer-element {
                    min-height: 4.5rem;
                }

                .hospital-partner-btn-container ~ .buffer-element {
                    min-height: 9rem;
                }

                .contact-icons {
                    list-style-type: none;
                    padding: 1rem 0 0;
                    margin: 0;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    right: 10px;

                    li {
                        display: inline-block;
                        margin: 0 0.5rem;

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            width: 4rem;
                            display: block;
                            margin: auto;
                            font-family: $primary-font;
                        }
                    }
                }
            }
        }
    }
}
    
    @media only screen and (min-width: 320px) and (max-width: 1080px) and (orientation: landscape) {
        .find-a-chapter {
            div.find-a-chapter-right {
                padding-top: 70px;
            }
        }

        .find-a-chapter-results-card-main-mobile {
            display: none;
        }
    }

    @media only screen and (min-width:1024px) and (orientation: landscape) {
        .find-a-chapter div.find-a-chapter-left {
            display: block !important;
        }

        .find-a-chapter div.find-a-chapter-right {
            padding-top: 0px
        }
    }

    @media only screen and (min-width:1024px) {
        #myMap {
            display: block !important;
        }
    }
    
    .autocomplete-container {
        position: relative;

        input[type=text] {
            margin: 0;
        }

        .autocomplete-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            background-color: $white;
            width: 98.5%;
            position: absolute;
            left: 0;
            top: 84px;
            z-index: 1;
            max-height: 200px;
            overflow: auto;

            li {
                color: #404042;
                font-family: 'muli',sans-serif;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1;
                padding: 15px;
                border-bottom: 1px solid #ccc;
                cursor: pointer;

                &:last-child {
                    border-bottom: none;
                }

                &.autocomplete-active {
                    background-color: $gray-blue;
                    color: #fff;
                }
            }
        }
    }

    @media all and (min-width:320px) and (max-width:1023px) and (orientation: portrait) {
        .find-a-chapter div.find-a-chapter-right div#myMap {
            bottom: 8rem;
            z-index: -1;
        }

        .find-a-chapter div.find-a-chapter-right {
            background-color: #c7c7c7;
        }

        .find-a-chapter div.find-a-chapter-right .bottom-right.subcontrol-container {
            top: 42%;
        }
    }

    @media all and (max-width:380px) and (orientation: portrait) {
        .find-a-chapter div.find-a-chapter-right .bottom-right.subcontrol-container {
            top: 42%;
        }
    }  