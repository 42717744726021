.quick-link-block {
    div.hero-bottom-text {
        margin-top: -2.5rem;

        @include breakpoint (small down) {
            margin-top: -3.5rem;
        }
    }
    .quicklink-block {
        @include breakpoint (small down) {
            padding: 1.5rem !important;
        }
        a {
            display: block;
            margin: 0 0.5rem;

            @include breakpoint(medium down) {
                width: 30%;
            }

            @include breakpoint(small down) {
                width: 100%;
            }
        }
        .icons-grid {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 2rem;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            background-color: $white;
            box-shadow: 0 7px 8px 0 rgba(91, 101, 115, 0.08);
            width: 21.5rem;
            height: 8.5rem;
            @include breakpoint (medium down) {
                margin-bottom: 1rem;
            }
            @include breakpoint (medium down) {
                padding: 1rem;
                width: 100%;
            }
            .icons {
                padding: 0 0.438rem;
                flex: 0 0 5em;

                @include breakpoint(small down) {
                    flex: 0 0 7em;
                    border-color: $blue-dark;
                }
            }
            .info {
                padding: 0 0.875rem;
                border-left: 2px solid #ccc;
                min-height: 3.75rem;
                display: flex;
                display: -ms-flexbox;
                display: -webkit-flex;
                flex-flow: column nowrap;
                justify-content: center;
            }
            @media all and (-ms-high-contrast:none) {
                .info {
                    display: table-cell;
                    vertical-align: middle;
                    width: 15rem
                }

                /* IE10 */
                *::-ms-backdrop,
                .info {
                    display: table-cell;
                    vertical-align: middle;
                    width: 15rem
                }

                /* IE11 */
            }

            h2 {
                margin: 0 0 1.125rem;
                font-family: 'Raleway', sans-serif;
                font-size: 2rem;
                color: $gray-black-dark;
                font-weight: 700;
                line-height: 2.5rem;

                @include breakpoint(small down) {
                    font-size: 1.625rem;
                }
            }

            h3 {
                margin: 0;
                font-family: 'Muli', sans-serif;
                font-size: 1.25rem;
                font-weight: 700;
                line-height: 1;
                color: $gray-black-dark;
                width: 100%;
            }
        }

    }
}

%btnHover {
    border-color: $gray-black-dark;
    color: $gray-black-dark;
}


//expand
.quicklink-block {
    &.quicklink-block-expand-icon {
        h4 {
            font-size: 1rem;
            line-height: 1.37;
        }

        .icons-grid {
            .info {
                border-color: $gray-lighter;
            }

            .icons {
                @include breakpoint(small down) {
                    flex: 0 0 5em;
                    border-color: $gray-lighter;
                }
            }
        }
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .quick-link-block {
        .quicklink-block {
            .icons-grid {
                padding: 0;
                width: 100%;
            }
        }
    }

    .quick-link-block {
        .quicklink-block {
            a {
                width: 30%;
            }
        }
    }
}

.icons-heading {
    margin: 0;
    font-family: $primary-font;
    font-size: $medium-1;
    font-weight: $xx-bold;
    line-height: $line-height-1;
    color: $gray-black-dark;
    width: 100%;
}