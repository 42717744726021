$color_1: #3671c1;
$color_2: #ffbf04;
$color_3: #fff;
$color_4: #ffffff;
$color_5: #000;
$color_6: #e0251c;
$font-family_1: "Raleway", sans-serif;
$background-color_1: #e0251c;

@keyframes leftBouncing {
    0% {
        left: 0;
    }

    70% {
        left: -5px;
    }

    95% {
        left: 25px;
    }

    100% {
        left: 0;
    }
}

@keyframes rightBouncing {
    0% {
        left: 0;
    }

    95% {
        left: -10px;
        transform: scaleX(1.05);
    }

    100% {
        left: 0;
        transform: scaleX(1);
    }
}

.inTimeline {
    height: 100vh;
    overflow: hidden;
}

.timeline-wrapper + .statement-block-component {
    margin-top: 0;
    background: #f1f4f9;

    .statement-block {
        background: #f1f4f9;
    }
}

.timeline-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.timeline {
    margin: 0 0 0;
    padding: 0;
    display: flex;
    overflow: hidden;
    position: relative;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: $font-family_1;
    font-size: 1rem;
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #3671c1;
    z-index: 8;
}

.landscapeView {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 60%);
    z-index: 10;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: $font-family_1;
        font-size: 1.1rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        height: 25%;
        margin-bottom: 1rem;
    }
}

.timeline-item {
    &:first-child.currSlide {
        .timeline-content {
            display: flex;
        }
    }

    z-index: 7;
    display: flex;
    width: 70px;
    height: calc(100vh);
    position: relative;
    background: #fff;
    color: $color_1;
    left: 0px;
    transition: width 1200ms ease;

    &:nth-child(even) {
        background: #3671c1;
        color: $color_2;
    }

    &:first-child {
        > div {
            background: #ffbf04;
            background-repeat: no-repeat !important;
            background-size: 100% 100% !important;
            background-position: left !important;
        }

        > div.timeline-content {
            background-image: url("images/bg.png");
        }

        .timeline-content {
            padding: 3rem;
        }

        .content-box {
            &:first-child {
                margin-bottom: 0;
            }
        }

        .bullet {
            button {
                display: flex;
                align-items: center;
                width: auto;

                img {
                    width: auto;
                    height: 2.5rem;
                }
            }

            .prev-btn {
                display: none;
            }
        }
    }

    .content-box {
        &:first-child {
            margin-bottom: 4rem;
        }
    }

    &:nth-child(odd) {
        .timeline-content {
            .content-box {
                color: $color_5;

                h2 {
                    color: $color_1;
                }

                h3 {
                    span {
                        background: #ffffff;
                        color: $color_1;
                    }

                    &:after {
                        background-color: $background-color_1;
                    }
                }
            }

            .txt-data {
                strong {
                    color: $color_6;
                }
            }
        }
    }

    &:last-child {
        .bullet {
            .next-btn {
                display: none;
            }
        }
    }
}

.timeline-item.currSlide {
    &:first-child {
        > .timeline-content {
            left: 0;
        }
    }

    .content-box {
        opacity: 1;
    }

    .bullet {
        opacity: 1;
    }
}

.timeline-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    cursor: pointer;
    width: 70px;
    padding: 0.5rem;

    span {
        font-size: 1.5rem;
        font-weight: 800;
    }

    img {
        position: absolute;
        width: auto;
        height: 2.2rem;
        bottom: 1.5rem;
    }
}

.timeline-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 70px;
    height: 100%;
    padding: 3rem 6.4rem 5rem 2rem;
}

.content-box {
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    color: $color_3;
    margin-bottom: 0;
    display: flex;
    opacity: 0.2;
    transition: all 1000ms ease;

    img {
        width: 30%;
        margin: 1rem;
    }

    > strong {
        font-size: 2.3rem;
        font-weight: 800;
        color: $color_1;
    }

    .img-box {
        width: 300px;
        height: 100%;
        margin-left: 2.5rem;
        display: flex;
        align-items: end;
        justify-content: center;

        img {
            width: 100%;
            margin: -15rem 0 -0.3rem;
        }
    }

    h2 {
        color: $color_4;
        font-family: $font-family_1;
        line-height: 1;
        font-weight: 800;
        font-size: 2.7rem;
        margin: 0 0 1rem;

        span {
            line-height: normal;
            display: block;
        }
    }

    h3 {
        position: relative;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin: 0 0 1.8rem;

        span {
            position: absolute;
            background: #3671c1;
            z-index: 1;
            font-family: $font-family_1;
            font-weight: 800;
            font-size: 1.7rem;
            color: $color_4;
            line-height: 1;
            bottom: -0.35rem;
            padding-right: 1rem;
        }

        &:after {
            position: relative;
            display: block;
            content: "";
            background: #ffbf04;
            width: 100%;
            height: 2px;
            margin: 0;
        }
    }

    p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 0 0 1rem;
    }
}

.txt-box {
    width: 100%;

    .milestone {
        display: flex;

        .year {
            display: inline-table;
            min-width: 1rem;
            margin-right: 1rem;

            strong {
                font-weight: 800;

                img {
                    width: 1rem;
                    height: auto;
                    margin: 0;
                }
            }
        }

        p {
            font-size: 1rem;
        }
    }
}

.txt-record {
    display: flex;
    align-items: center;
    justify-content: start;
}

.txt-record-mob {
    display: flex;
    align-items: center;
    justify-content: start;
    display: none;
}

.txt-data {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: 1.25rem;

    &:last-child {
        margin-right: 0;
    }

    strong {
        color: $color_2;
        line-height: 1;
        font-weight: 800;
        font-size: 3rem;
        margin-right: 0.5rem;
    }

    p {
        display: inline-block;
        margin: 0;

        span {
            display: block;
        }
    }
}

.bullet {
    position: absolute;
    width: 100%;
    bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    opacity: 0.2;
    transition: all 1000ms ease;

    span {
        font-family: $font-family_1;
        font-size: 1.25rem;
        font-weight: 800;
        color: $color_5;
        margin-right: 0.5rem;
    }

    button {
        cursor: pointer;
        background: transparent;
        border: 0;
        width: 2.5rem;
        margin: 0 1rem;
    }

    img {
        width: 100%;
    }
}

.bullet-mob {
    display: none;
}

.timeline-item.right.currSlide {
    animation: rightBouncing 1400ms ease;
}

.timeline-item.left.currSlide {
    + {
        .timeline-item.left {
            animation: leftBouncing 1500ms ease;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .timeline-header {
        span {
            font-size: 1.2rem;
        }
    }

    .content-box {
        .img-box {
            width: 200px;
        }

        > strong {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        h3 {
            span {
                font-size: 0.9rem;
            }
        }

        p {
            font-size: 0.8rem;
            line-height: 1rem;
        }
    }

    .txt-data {
        strong {
            font-size: 1.5rem;
        }
    }

    .txt-box {
        .milestone {
            p {
                font-size: 0.8rem;
                line-height: 1rem;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .timeline-content {
        padding: 10rem 10rem 10rem 4.4rem;
    }

    .timeline-header {
        img {
            bottom: 2.5rem;
        }
    }

    .bullet {
        bottom: 2.5rem;
    }

    .content-box {
        .img-box {
            width: 400px;

            img {
                margin: -25rem 0 -0.3rem;
            }
        }

        > strong {
            font-size: 3rem;
        }

        &:first-child {
            > .txt-box {
                > p {
                    margin: 0 0 1.88rem;
                }
            }
        }

        h2 {
            font-size: 3rem;
            margin: 0 0 1.25rem;
        }

        h3 {
            margin-bottom: 2.5rem;
        }
    }

    .txt-data {
        margin-right: 2.5rem;

        strong {
            margin-right: 1rem;
        }
    }
}

@media screen and (min-width: 1900px) {
    .timeline-content {
        padding: 10rem 16rem 10rem 10.4rem;
    }

    .content-box {
        img {
            width: 36.5%;
        }
    }
}

@include breakpoint(medium down) {
    .timeline {
        flex-direction: column;
        height: calc(100vh - 56px);
        margin-top: 0;
    }

    .timeline.last {
        height: auto !important;

        .timeline-item {
            &:last-child.collapsed {
                height: auto;
                position: relative;
                top: 0;
            }
        }
    }

    .timeline-item {
        flex-direction: column;
        width: 100vw;
        height: 50px;
        transition: all 499ms cubic-bezier(0, 0.78, 0.37, 1.11), height 200ms cubic-bezier(0, 0.78, 0.37, 1.11);

        &:first-child.currSlide {
            top: 0;

            .timeline-content {
                width: 100vw;
                height: 100%;
                display: flex;
            }
        }

        &:first-child {
            .timeline-header {
                img {
                    width: 3rem;
                }

                justify-content: center;
            }

            .timeline-content {
                justify-content: center;
                overflow: hidden;

                .bullet {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    top: auto;
                    bottom: 0;
                }

                .bullet-mob {
                    position: absolute;
                    bottom: 1rem;

                    img {
                        width: 1.5rem;
                    }

                    button {
                        transform: rotate(0deg);
                        padding: 0;
                    }
                }
            }

            .content-box {
                &:first-child {
                    height: 100%;
                    margin-bottom: 3rem;
                }
            }
        }

        &:last-child {
            .bullet-mob {
                .next-mob {
                    display: none;
                }
            }
        }
    }

    .timeline-item.currSlide {
        position: absolute;
        top: 50px;

        .timeline-content {
            width: 100vw;
            height: 100%;
            display: flex;
        }

        .bullet {
            display: none;
        }

        .content-box {
            display: flex;
        }

        .bullet-mob {
            display: flex;
        }
    }

    .timeline-header {
        width: 100%;
        height: 50px;
        padding: 5px 20px;
        background-size: 100% 100% !important;
        justify-content: space-between;

        img {
            position: relative;
            bottom: auto;
            width: 1.7rem;
            height: auto;
        }
    }

    .timeline-content {
        position: static;
        overflow-y: auto;
        justify-content: flex-start;
        width: 100vw;
        padding: 10px;
        display: none;
        padding: 20px;

        .content-box {
            flex-direction: column;
            display: none;
            opacity: 1;
        }
    }

    .content-box {
        p {
            font-size: 1rem;
            line-height: 22px;
        }

        img.landing-img {
            width: auto;
            height: 50%;
            margin: 0;
        }

        > strong {
            font-size: 1.5em;
        }

        h3 {
            &:after {
                margin-bottom: 0;
            }

            margin-bottom: 2rem;

            span {
                font-size: 1.05rem;
                padding-right: .5rem;
                bottom: -0.25rem;
            }
        }

        h2 {
            span {
                display: inline;
            }
        }

        .img-box {
            justify-content: space-between;
            width: 100%;
            margin: 2rem 0 0;

            .img-box-wrapper {
                display: flex;
                flex-shrink: 2;
                height: auto;
                flex-shrink: unset;
                height: auto;
                position: relative;
                width: 60%;

                img {
                    margin: 0 0 -0.3rem;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                }
            }

            .txt-record-mob {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 8rem;
                margin-left: 0.8rem;
            }
        }
    }

    .timeline-item.timeline-mob {
        &:first-child {
            .timeline-content {
                .bullet {
                    display: none;
                }
            }
        }

        &:first-child.currSlide {
            .timeline-content {
                .bullet {
                    display: flex;
                    width: 100vw;
                }
            }
        }
    }

    .img-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .txt-record {
        display: none;
    }

    .txt-record-mob {
        display: block;
    }

    .txt-data {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 0;
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .bullet {
        display: none;
    }

    .bullet-mob {
        position: relative;
        display: none;
        justify-content: center;
        align-items: center;
        opacity: 1;
        transition: all 1000ms ease;
        text-align: center;
        flex-direction: row;

        button {
            background: transparent;
            border: none;
            transform: rotate(90deg);
            padding: 0.5rem 1.5rem;
        }

        span {
            font-family: $font-family_1;
            font-size: 0.8rem;
            font-weight: 800;
            color: $color_5;
            display: block;
            margin-bottom: 0.5rem;
        }

        img {
            width: 2.5rem;
        }
    }

    .loader {
        display: none;
    }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
    .content-box {
        img.landing-img {
            width: auto;
            height: 45%;
        }

        .img-box {
            .img-box-wrapper {
                width: 35%;

                img {
                    width: 80%;
                }
            }

            justify-content: center;
            margin: 7rem 0 0;

        }
    }
}


@media screen and (max-width: 500px) and (orientation: portrait) {
    .content-box {
        .img-box {
            margin: 4rem 0 0;
        }

        > strong {
            font-size: 1em;
        }
    }
}

@media screen and (max-width: 400px) {
    .timeline-item {
        &:first-child.currSlide {
            .timeline-content {
                padding: 0;
            }
        }
    }

    .content-box {
        img.landing-img {
            width: auto;
            height: 40%;
        }

        .img-box {
            margin: 2rem 0 0;
        }
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {

    body.bodyTimeline {
        overflow: hidden;
        height: 100dvh;
    }

    .landscapeView{
        display: flex;
    }

}

@media screen and (max-height: 360px) and (orientation: landscape) {
    .content-box img.landing-img {
        height: 60%;
    }

    .content-box > strong {
        font-size: 1em;
    }
}