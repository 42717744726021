.search-results {
    background-color: $blue-dark;
    height: 18.5rem;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .search-results-description {
        padding: 56px 20% 0 20%;

        @include breakpoint (small down) {
            padding: 0 1.75rem;
        }

        h2 {
            color: #fff;
            font-family: Raleway, sans-serif;
            font-size: 2rem;
            font-weight: 800;
            line-height: 1.14;
            letter-spacing: normal;

            @include breakpoint (small down) {
                font-size: 1.625rem;
                font-weight: bold;
            }
        }
    }

    .heart-img {
        display: block;
        position: absolute;
        right: 70px;
        top: 56px;
        width: 388px;
        //height: 392px;

        //opacity: .2;
        @include breakpoint(small down) {
            right: -120px;
            width: 425px;
            top: 0;
            //height: 323px;
        }
    }

}

.search-results-content {
    background-color: $gray-super-lighter;

    .search-results-inner-content {
        background-color: $white;
        padding: 7rem;
        position: relative;
        top: -25px;

        @include breakpoint (small down) {
            width: 90%;
            padding: 2rem 1rem;
        }

        input {
            height: 3.5rem;
            font-family: $primary-font;
            font-size: $normal;
            color: $black;
            max-width: 75%;
            font-weight: $regular;
            border: solid 1px $gray-lighter;
            box-shadow: none;
            padding-left: 10px;

            @include breakpoint (small down) {
                max-width: 100%;
            }

            &::placeholder {
                color: #000;
            }
        }

        button {
            background-color: $blue-dark;
            padding: 1rem;
            height: 3.5rem;
        }

        p.resultsheader {
            font-family: $primary-font;
            font-size: $x-medium;
            font-weight: $x-bold;
            line-height: $line-height-4;
            color: $gray-black-dark;
            border-bottom: 1px solid $gray-lighter;
            width: 100%;
        }

        div.searchresults {
            border-bottom: 1px solid $gray-lighter;

            h4 {
                font-family: $primary-font;
                font-size: $normal;
                font-weight: $xx-bold;
                line-height: $line-height-4;
                color: $blue-dark;
                margin-bottom: 0;
            }

            a {
                font-family: $primary-font;
                font-size: $x-small;
                font-weight: $regular;
                line-height: $line-height-6;
                letter-spacing: normal;
                color: $gray-medium;
            }

            p {
                font-family: $primary-font;
                font-size: $normal;
                font-weight: $regular;
                line-height: $line-height-normal;
                color: $gray-black-dark;
                padding-top: 1rem;

                @include breakpoint (small down) {
                    margin-top: 2rem;
                    line-height: 1.4rem;
                }
            }
        }

        div#search-result {
            width: 100%;
        }
    }
}

.pagination .current {
    background-color: transparent;
    font-family: 'Muli', sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: normal;
    color: $gray-black-dark;
    padding: .1875rem 0rem;
}

.pagination a {
    font-family: 'Muli', sans-serif;
    font-size: 1.125rem;
    font-weight: normal;
    color: #999999;

}

div#pagination-container ul.pagination li.current a {
    color: $gray-black-dark;
}

div#pagination-container ul.pagination li.pagination-btn-hide {
    display: none;
}

div#pagination-container ul.pagination li.pagination-btn-show {
    display: inline-block;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
    display: none;
}

.pagination-next a::after,
.pagination-next.disabled::after {
    display: none;
}

.pagination a:hover,
.pagination button:hover {
    background-color: transparent;
}

@include breakpoint (small down) {
    ul.pagination {
        text-align: center;
    }

    .pagination li {
        display: inline-block;
    }
}

div.no-results p {
    padding: 1rem 0.2rem;
    font-family: 'Raleway', sans-serif;
    font-size: 1.625rem;
    font-weight: bold;
    line-height: 1.31;
    color: $gray-black-dark;
    margin-top: 2rem;
}