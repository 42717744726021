.section-header-component{
      padding-top: 3.125rem !important;
      padding-bottom: 3.125rem;
      @include breakpoint (medium down){
          padding-top: 1.875rem !important;
          padding-bottom: 0;
      }
    h2{
        font-family: 'Raleway',sans-serif;
        font-size: 2rem;
        font-weight: 800;  
        line-height: 1.14;
        margin: 0 0 2rem;
        color:$gray-black-dark;
        @include breakpoint(small down){
            font-size: 1.625rem;
        }
    }
    h4{
        font-family: 'Muli',sans-serif;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.36;
        margin:0;
        color:$gray-black-dark;
    }
    &.text-center{
        margin:0 auto;
    }
}