.classy-amount-info-container {
    background-color: $blue-super;
    border-radius: 2px;
    border: solid 2px #c5d6f2;
    text-align: center;
    padding: 10px 20px;
    font-family: $primary-font;
    font-size: $normal;
    font-weight: $regular-2;
    line-height: $line-height-normal;
    margin-top: 0.625rem;
    margin-left: 3px;
    margin-right: 3px;

    @include breakpoint(medium down) {
        font-size: 1rem;
        padding: 10px 22px;
    }

    .classy-inner-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .text {
            text-align: left;

            span {
                display: block;

                @include breakpoint(small down) {
                    display: inline;
                }
            }
        }

        .icon {
            margin: 0 10px 0 0;
            height: 56px;
            flex: 0 0 56px;
            width: 56px;
            display: flex;
            align-items: center;

            @include breakpoint(medium down) {
                flex: 0 0 50px;
                width: 50px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
.donation-main-form .donation-main-form-right-content .expansion-form{
    &.classy-donation{
        position:relative;
        min-height:520px;
        @include breakpoint(small down) {
            padding: 1rem;
        }

        input, .checkbox, select{
            cursor: pointer;
        }
    }
    &.classy{ 
        padding: 0;
        min-height:592px;
    }
}
.classy-iframe-container{
  height: auto;
  visibility: hidden;
  position: absolute;
  top: 0px; 
  left: 0px;
  width:100%;
  iframe{
    width: 100%;
    height: auto;
    min-height: 592px;
    border:0px;
  }
}

.donation-main-form .donation-main-form-right-content .expansion-form {
    div.classy-donation-frequency-section {
        margin-bottom: 2.5rem;

        h6, legend {
            font-family: $primary-font;
            font-size: $normal;
            font-weight: $x-bold;
            color: $gray-black-dark;
            text-transform: uppercase;
        }

        label {
            display: inline-block;
            width: 140px;
            margin-right: 0.5rem;
            margin-top: 0;
            position: relative;
            height: 48px;
            font-size: 0;
            vertical-align: top;

            @include breakpoint(small down) {
                width: calc(50% - 7px);
                margin: 0 2px;
            }

            .radiobutton {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid $blue-dark;
                border-radius: 4px;
                color: $blue-dark;
                font-family: $primary-font;
                font-size: $medium;
                font-weight: $bold;
                text-align: center;
                line-height: $xxxxx-large;
                cursor: pointer;
            }

            input[type="radio"] {
                opacity: 0;
                position: absolute;
                margin: 0;
                left: 0;
                top: 0;

                &:checked {
                    & + .radiobutton {
                        background-color: $blue-dark;
                        color: $white;
                    }
                }

                &:focus {
                    & + .radiobutton {
                        outline: 2px solid #000;
                    }
                }

                &:focus {
                    &:checked {
                        & + .radiobutton {
                            background-color: $blue-dark;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    div.classy-amount-section {
        padding-bottom: 2rem;
        text-align: center;
        margin-left: -3px;
        margin-right: -3px;

        .currency-container {
            display: flex;

            .amount-label {
                margin-bottom: auto;
                margin-top: 1rem;
            }

            .currency {
                margin-left: 0.3rem;
                width: min-content;
            }
        }

        h6, legend {
            font-family: $primary-font;
            font-size: $normal;
            font-weight: $x-bold;
            color: $gray-black-dark;
            text-align: left;
            margin-left: 3px;
            margin-right: 3px;
            text-transform: uppercase;
        }

        label {
            display: inline-block;
            width: calc(100% / 6 - 10px);
            margin-right: 3px;
            margin-left: 3px;
            margin-bottom: 0.625rem;
            position: relative;
            cursor: pointer;
            font-size: 0;
            height: 48px;
            vertical-align: top;

            .radiobutton {
                border: 2px solid $blue-dark;
                border-radius: 4px;
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
                background-color: #fff;
                color: $blue-dark;
                font-family: $primary-font;
                font-size: $medium;
                font-weight: $bold;
                text-align: center;
                line-height: $xxxxx-large;
            }

            @include breakpoint(small down) {
                width: calc(100% / 3 - 10px);
            }

            input[type="radio"] {
                opacity: 0;
                position: absolute;
                margin: 0;
                left: 0;
                top: 0;

                &:checked {
                    & + .radiobutton {
                        background-color: $blue-dark;
                        color: $white;
                    }
                }

                &:focus {
                    & + .radiobutton {
                        outline: 2px solid #000;
                    }
                }
            }
        }

        .classy-amount-txt-wrapper {
            display: none;
            width: calc(100% / 6 - 10px);
            background-color: $blue-dark;
            border-radius: 4px;
            height: 48px;
            position: relative;
            margin-left: 3px;
            margin-right: 3px;

            @include breakpoint(small down) {
                width: calc(100% / 3 - 10px);
            }

            label {
                border: none;
                width: auto;
                color: #fff;
                display: inline-block;
                margin: 0;
                position: absolute;
                left: 10px;
                top: 0;
                line-height: 48px;
                font-family: $primary-font;
                font-size: $medium;
                font-weight: $bold;
            }

            input {
                margin: 0;
                box-shadow: none;
                outline: none;
                border: none;
                background-color: transparent;
                font-family: $primary-font;
                font-size: 18px;
                font-weight: $bold;
                color: #fff;
                height: 100%;
                padding: 0 10px 0 24px;
                border-radius: 4px;

                &.input-validation-error {
                    background: rgba(255, 0, 0, 0.1);
                    border: 1px solid #da1a00;
                }
            }

            span.field-validation-error {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                border: 0;
            }
        }

        span.amount-error {
            color: #da1a00;
            font-size: $x-small;
            font-family: $primary-font;
        }
    }

    div.classy-designate-checkbox-section {
        label {
            font-family: $primary-font;
            font-size: $medium;
            font-weight: $regular-2;
            color: $gray-black-dark;
        }

        div.classy-designate-drop-section {
            border-left: 2px solid $gray-lighter;
            padding: 1rem 0 1rem 1.5rem;
            margin-left: 1rem;

            p {
                font-family: $primary-font;
                font-size: $normal;
                font-weight: $regular;
                color: $gray-black-dark;
            }

            select {
                font-family: $primary-font;
                font-size: $normal;
                font-weight: $regular;
                color: $gray-black-dark;
                width: 75%;
                background-image: url(../../images/svg/black-bigger.png);
                border-radius: 2px;

                @include breakpoint(small down) {
                    width: 100%;
                }
            }
        }

        div.classy-select-a-chapter-section {
            h6 {
                font-family: $primary-font;
                font-size: $normal;
                font-weight: $x-bold;
                color: $gray-black-dark;
            }

            .classy-select-a-chapter-sub-section {
                display: flex;
                flex-flow: row nowrap;
                margin-left: -5px;
                margin-right: -5px;

                @include breakpoint(small down) {
                    margin: 0;
                    flex-flow: row wrap;
                }

                .classy-state-dropdown-section {
                    flex: 0 0 40%;
                    padding: 0 5px;
                    width: calc(40% - 10px);

                    @include breakpoint(small down) {
                        flex: 0 0 100%;
                        width: 100%;
                    }

                    label {
                        font-family: $primary-font;
                        font-size: $normal;
                        font-weight: $regular;
                        color: $gray-black-dark;
                    }

                    select {
                        width: 100%;
                    }
                }

                .classy-chapter-dropdown-section {
                    flex: 0 0 60%;
                    padding: 0 5px;
                    width: calc(60% - 10px);

                    @include breakpoint(small down) {
                        flex: 0 0 100%;
                        width: 100%;
                    }

                    label {
                        font-family: $primary-font;
                        font-size: $normal;
                        font-weight: $regular;
                        color: $gray-black-dark;
                    }

                    select {
                        width: 100%;
                    }
                }

                #non-chapter-dropdown-section {
                    flex: 0 0 75.5%;

                    @include breakpoint(small down) {
                        flex: 0 0 100%;
                        padding: 0;
                    }
                }
            }
        }
    }
}

form.expansion-campaign-donation-form input.classy-donations-submit {
    background-color: $red-dark;
    border: none;
    color: $white;
    font-size: $medium;
    font-family: $primary-font;
    width: 100%;
    border-radius: 2px;
    font-weight: $xx-bold;
    padding: 1rem 0;
    text-align: center;
    cursor: pointer;
    margin-top: 2rem;

    &:disabled {
        opacity: 0.5;
        background-color: #ccc;
        cursor: not-allowed;
    }
}
.backtoRMHC-btn-container {
    text-align: center;
    @include breakpoint(medium down) {
        margin-top: 2rem;
    }

    button {
        @include breakpoint(small down) {
            width: 100%;
        }
    }
}
.custom-loader {
    width: 48px;
    height: 48px;
    border: 5px solid #4872ae;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: custom-rotation 1s linear infinite;
}

@keyframes custom-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} 