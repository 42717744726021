%inputStyle {
  box-shadow: none;
  height: 56px;
  border-color: #979797;
  font-family: Muli, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: $gray-black-dark;
}

.donation-main-form {
  background-color: $blue-dark;

  // padding: 3rem 0;
  input[type="text"] {
    @extend %inputStyle;
    &.input-validation-error {
      border-color: #da1a00;
    }
	&.input-error-border {
      border-color: #da1a00;
    }
  }

  input[type="number"] {
    @extend %inputStyle;
    &.input-validation-error {
      border-color: #da1a00;
    }
  }

  input[type="email"] {
    @extend %inputStyle;
    &.input-validation-error {
      border-color: #da1a00;
    }
  }

  input[type="password"] {
    @extend %inputStyle;
    &.input-validation-error {
      border-color: #da1a00;
    }
  }

  select {
    @extend %inputStyle;
    &.input-validation-error {
      border-color: #da1a00;
    }
  }

  textarea {
    @extend %inputStyle;
    height: 200px;
    resize: none;
    &.input-validation-error {
      border-color: #da1a00;
    }
  }

  .donation-main-form-left-content {
    background-image: url(../../images/svg/donation-heart.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 250px;
    padding: 3rem 3rem 3rem 0;
    a {
      color: #fff;
      text-decoration: underline;
      font-weight: $xxx-bold;
    }

    @include breakpoint(medium down) {
      padding: 0;
      padding-bottom: 3rem;
    }

    h2 {
      font-family: $primary-font;
      font-size: $xx-large-2;
      line-height: 3rem;
      color: $white;
      font-weight: $xxx-bold;
      padding: 2rem 0;
    }

    h3 {
      font-family: $primary-font;
      font-size: $xx-medium;
      line-height: 1.875rem;
      color: $white;
      font-weight: $x-bold;
      @include breakpoint(medium down) {
        font-size: $medium-2;
        line-height: $line-height-6;
      }
    }

    h6,h4 {
      font-family: $primary-font;
      font-size: $normal;
      font-weight: $bold;
      line-height: 1.375rem;
      color: $white;
      padding-top: 1rem;
      a {
        color: $white;
        text-decoration: underline;
        font-family: $primary-font;
        font-size: $normal;
        font-weight: $bold;
        line-height: 1.375rem;
      }

      @include breakpoint(medium down) {
        padding-top: 2.5rem;
      }
    }

    span {
      color: $white;
      font-size: $normal;
      font-weight: $regular-1;
      font-family: $primary-font;
    }
  }

  .donation-main-form-right-content {
    padding: 3rem 0;

    @include breakpoint(medium down) {
      padding-top: 0;
    }

    h2 {
      font-family: $primary-font;
      font-size: $xx-large-2;
      line-height: 3rem;
      color: $white;
      font-weight: $xxx-bold;
      padding: 2rem 0;
      margin: 0;

      @include breakpoint(medium down) {
        font-size: $xx-medium;
        line-height: 1.75rem;
        text-align: center;
      }
    }
    .expansion-form {
      background-color: $white;
      padding: 2rem;
      border-radius: 2px;
      @media all and (-ms-high-contrast: none) {
        overflow: hidden;
      }
      @include breakpoint(medium down) {
        padding: 1.25rem;
      }
      &.crypto{
        .grid-container{
            padding: 0px;
        }
		.rich-text-block{
			padding: 0px;
		}
        iframe{
            @include breakpoint(small down) {
				width: 100% !important;
			}
        }
	  }
      form.expansion-campaign-donation-form {
        div.amount-section {
          padding-bottom: 2rem;
          text-align: center;
          margin-left: -3px;
          margin-right: -3px;

          h6 {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: bold;
            color: $gray-black-dark;
            text-align: left;
            margin-left: 3px;
            margin-right: 3px;
            text-transform: uppercase;
          }

          label {
            display: inline-block;
            width: calc(100% / 6 - 10px);
            margin-right: 3px;
            margin-left: 3px;
            margin-bottom: 0.625rem;
            position: relative;
            cursor: pointer;
            font-size: 0;
            height: 48px;
            vertical-align: top;

            .radiobutton {
              border: 2px solid $blue-dark;
              border-radius: 4px;
              display: block;
              height: 100%;
              position: absolute;
              width: 100%;
              background-color: #fff;
              color: $blue-dark;
              font-family: "Raleway", sans-serif;
              font-size: 1.125rem;
              font-weight: bold;
              text-align: center;
              line-height: 2.75rem;
            }

            @include breakpoint(small down) {
              width: calc(100% / 3 - 10px);
            }

            input[type="radio"] {
              opacity: 0;
              position: absolute;
              margin: 0;
              left: 0;
              top: 0;

              &:checked {
                & + .radiobutton {
                  background-color: $blue-dark;
                  color: $white;
                }
              }
            }
          }

          .amount-txt-wrapper {
            display: none;
            width: calc(100% / 6 - 10px);
            background-color: $blue-dark;
            border-radius: 4px;
            height: 48px;
            position: relative;
            margin-left: 3px;
            margin-right: 3px;

            @include breakpoint(small down) {
              width: calc(100% / 3 - 10px);
            }

            label {
              border: none;
              width: auto;
              color: #fff;
              display: inline-block;
              margin: 0;
              position: absolute;
              left: 10px;
              top: 0;
              line-height: 48px;
              font-family: "Raleway", sans-serif;
              font-size: 1.125rem;
              font-weight: bold;
            }

            input {
              margin: 0;
              box-shadow: none;
              outline: none;
              border: none;
              background-color: transparent;
              font-family: "Muli", sans-serif;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              height: 100%;
              padding: 0 10px 0 24px;
              border-radius: 4px;

              &.input-validation-error {
                background: rgba(255, 0, 0, 0.1);
                border: 1px solid #da1a00;
              }
            }
            span.field-validation-error {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              border: 0;
            }
          }
          span.amount-error{
            color: $red-dark;
            font-size: $x-small;
            font-family: $primary-font;
          }
        }

        div.payment-info-section {
          padding-bottom: 2rem;

          h6 {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: bold;
            color: $gray-black-dark;
          }

          .payment-information-section {
            background-color: $blue-super;
            padding: 2rem 2.5rem;

            label {
              font-family: Muli, sans-serif;
              font-size: 1rem;
              font-weight: 600;
              color: $gray-black-dark;
              display: block;
              line-height: normal;
              margin-bottom: 0.75rem;
            }

            @include breakpoint(small down) {
              padding: 1.25rem;
              border: solid 2px #c5d6f2;
            }

            .payment-information-section-subsection {
              flex-flow: row nowrap;
              @include breakpoint(medium down) {
                flex-flow: row wrap;
              }
              .payment-subsection {
                flex: 0 0 50%;
                padding-right: 5px;
                @include breakpoint(medium down) {
                  flex: 0 0 100%;
                  padding: 0;

                  input[type="text"] {
                    width: 80%;
                  }
                }
              }

              .payment-subsection1 {
                flex: 0 0 50%;
                padding-left: 5px;

                @include breakpoint(medium down) {
                  flex: 0 0 100%;
                  padding: 0;

                  input[type="text"] {
                    width: 80%;
                  }
                }
              }
            }

            .medium-4.large-4 {
              @include breakpoint(medium down) {
                width: 65%;
              }

              input {
                width: 45%;
                float: left;
                margin-right: 6px;
                text-align: center;
                ::placeholder {
                  color: $gray-lighter;
                }

                @include breakpoint(medium down) {
                  width: 42%;
                }
              }
            }

            .medium-2.large-2 {
              @include breakpoint(medium down) {
                width: 35%;
              }
            }
          }

          .card-expiration-date {
            .medium-4.large-4 {
              input.input-validation-error {
                border: 1px solid $red-dark;
              }

              span.field-validation-error {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                border: 0;
              }
            }
            .payment-cvv {
              margin-left: 10px;
              @include breakpoint(medium down) {
                margin-left: 0;
              }
              input.input-validation-error {
                border: 1px solid $red-dark;
              }
              .field-validation-error {
                & > span {
                  width: 90px;
                  text-transform: inherit;
                }
              }
            }
          }
        }

        div.billing-section {
          h6 {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: bold;
            color: $gray-black-dark;
            margin-bottom: 1rem;
          }
          .captch-error {
		      color: $red-dark;
			  font-size: 0.875rem;
			  font-family: "Muli", sans-serif;
			  position: relative;
			  display: block !important;
			  line-height: normal;
              }
          label {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: 600;
            color: $gray-black-dark;
            margin-bottom: 0.75rem;
            line-height: normal;
            &.send-an-ecard-label {
              margin-bottom: 1.5rem;
            }
            // display: inline-block;
            &.gift-checkbox-label {
              position: relative;
              margin-bottom: 1.5rem;

              input[type="checkbox"] {
                position: absolute;
                left: 0;
                top: 3px;
              }
            }
          }

          div.gift-organization-section {
            width: 50%;
            border-left: 2px solid $gray-lighter;
            margin-left: 0.813rem;
            padding: 0.5rem 1.3rem 1rem;
            margin-bottom: 2rem;
            input[type="text"] {
              margin: 0;
            }

            @include breakpoint(medium down) {
              width: 100%;
            }
          }

          div.mediun-8.large-8 {
            width: 77%;

            @include breakpoint(medium down) {
              width: 100%;
            }
          }
          div.medium-8.large-8.small-12 {
            select {
              width: 52%;
              @include breakpoint(medium down) {
                width: 100%;
              }
            }
          }

          .billing-info {
            .medium-6.large-6 {
              margin-right: 12px;

              @include breakpoint(medium down) {
                width: 100%;
                margin-right: 0px;
                input[type="text"] {
                  width: 85%;
                }
              }
            }

            .medium-3.large-3 {
              margin-right: 12px;

              @include breakpoint(medium down) {
                margin-right: 8px;
                width: 40%;
              }
            }

            .medium-2.large-2 {
              @include breakpoint(medium down) {
                width: 57%;
              }
            }
          }

          label.memorial-gift-label {
            margin: 1.5rem 0;
            font-size: 1.125rem;
          }

          label.send-an-ecard-label donations-custom-checkbox {
            margin-top: 2rem;
          }

          label.receive-email-update-label {
            margin-top: 2rem;
            margin-bottom: 2rem;
          }

          label.receive-direct-email-label {
            margin-bottom: 2rem;
          }
        }
      }

      .payment-processing-main {
        padding: 7rem 0;

        @include breakpoint(medium down) {
          padding: 3rem 0;
        }

        span {
          display: block;
          width: 100%;
          height: 56px;
          background-image: url(../../images/svg/icons-handrawn-56-x-56-host-a-fundraiser.png);
          background-repeat: no-repeat;
          background-position: center;
          margin-bottom: 2rem;
        }

        h3 {
          font-family: Raleway, sans-serif;
          font-size: 1.625rem;
          line-height: 2.125rem;
          color: $gray-black-dark;
          text-align: center;
          font-weight: 900;
          width: 100%;
        }
      }
      .donation-confirmation-page {
        padding: 3rem 0;
        @include breakpoint(medium down) {
          padding: 1rem 0;
        }

        span {
          display: block;
          width: 100%;
          height: 56px;
          background-image: url(../../images/svg/icons-handrawn-56-x-56-host-a-fundraiser.png);
          background-repeat: no-repeat;
          background-position: center;
          margin-bottom: 2rem;
        }

        h3 {
          font-family: Raleway, sans-serif;
          font-size: 1.625rem;
          line-height: 2.125rem;
          color: $gray-black-dark;
          text-align: center;
          font-weight: 900;
          width: 100%;
        }

        p {
          font-family: Muli, sans-serif;
          font-size: 1.375rem;
          line-height: 1.875rem;
          color: $gray-black-dark;
          text-align: center;
          font-weight: 400;
          width: 100%;
          margin-top: 1rem;
        }

        p.confirmation-email {
          font-family: Muli, sans-serif;
          font-size: 1rem;
          color: $gray-black-dark;
          text-align: center;
          font-weight: normal;
          width: 100%;
          padding-top: 2rem;
        }
        ul {
          list-style: none;
          text-align: center;
          li {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            color: $red-dark;
          }
        }
      }

      div.donation-frequency-section {
        margin-bottom: 2.5rem;

        h6 {
          font-family: Muli, sans-serif;
          font-size: 1rem;
          font-weight: bold;
          color: $gray-black-dark;
          text-transform: uppercase;
        }

        label {
          display: inline-block;
          width: 140px;
          margin-right: 0.5rem;
          margin-top: 0;
          position: relative;
          height: 48px;
          font-size: 0;
          vertical-align: top;
          @include breakpoint(small down) {
            width: calc(50% - 7px);
            margin: 0 2px;
          }
          .radiobutton {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 2px solid $blue-dark;
            border-radius: 4px;
            color: $blue-dark;
            font-family: Raleway, sans-serif;
            font-size: 1.125rem;
            font-weight: bold;
            text-align: center;
            line-height: 2.75rem;
            cursor: pointer;
          }

          input[type="radio"] {
            opacity: 0;
            position: absolute;
            margin: 0;
            left: 0;
            top: 0;

            &:checked {
              & + .radiobutton {
                background-color: $blue-dark;
                color: $white;
              }
            }
            &:focus {
              &:checked {
                & + .radiobutton {
                  background-color: $blue-dark;
                  color: $white;
                }
              }
            }
          }
        }
      }

      div.designate-checkbox-section {
        label {
          font-family: Muli, sans-serif;
          font-size: 1.125rem;
          font-weight: 400;
          color: $gray-black-dark;
        }

        div.designate-drop-section {
          border-left: 2px solid $gray-lighter;
          padding: 1rem 0 1rem 1.5rem;
          margin-left: 1rem;

          p {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            color: $gray-black-dark;
          }

          select {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            color: $gray-black-dark;
            width: 75%;
            background-image: url(../../images/svg/black-bigger.png);
            border-radius: 2px;
            @include breakpoint(small down) {
              width: 100%;
            }
          }
        }

        div.select-a-chapter-section {
          h6 {
            font-family: Muli, sans-serif;
            font-size: 1rem;
            font-weight: bold;
            color: $gray-black-dark;
          }

          .select-a-chapter-sub-section {
            display: flex;
            flex-flow: row nowrap;
            margin-left: -5px;
            margin-right: -5px;
            @include breakpoint(small down) {
              margin: 0;
              flex-flow: row wrap;
            }

            .state-dropdown-section {
              flex: 0 0 40%;
              padding: 0 5px;
              width: calc(40% - 10px);
              @include breakpoint(small down) {
                flex: 0 0 100%;
                width: 100%;
              }
              label {
                font-family: Muli, sans-serif;
                font-size: 1rem;
                font-weight: normal;
                color: $gray-black-dark;
              }

              select {
                width: 100%;
              }
            }
            .chapter-dropdown-section {
              flex: 0 0 60%;
              padding: 0 5px;
              width: calc(60% - 10px);
              @include breakpoint(small down) {
                flex: 0 0 100%;
                width: 100%;
              }
              label {
                font-family: Muli, sans-serif;
                font-size: 1rem;
                font-weight: normal;
                color: $gray-black-dark;
              }
              select {
                width: 100%;
              }
            }
          }
        }
      }
      .donation-progress-bar-sub-head {
        font-family: "Raleway", sans-serif;
        font-size: 1.625rem;
        font-weight: 800;
        line-height: 1.31;
        text-align: center;
        margin-bottom: 2rem;
        .selected-amount {
          color: #4872ae;
        }
      }
      .donating-info-text {
        font-family: "Muli", sans-serif;
        font-size: 1.125rem;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 2rem;
        .text-bold {
          font-weight: bold;
        }
      }
      .ecard-bottom-confirm-section {
        border-radius: 2px;
        border: solid 2px #c5d6f2;
        background-color: #f1f4f9;
        padding: 30px;
        text-align: center;
        @include breakpoint(small down) {
          padding: 30px 15px;
        }
        .icon {
          background: url(../../images/svg/icons-handrawn-56-x-56-host-a-fundraiser.png)
            no-repeat center;
          display: block;
          height: 56px;
          margin-bottom: 1rem;
        }
        .heading-1 {
          font-family: "Raleway", sans-serif;
          font-size: 1.125rem;
          font-weight: 800;
          line-height: 1.33;
          margin: 0 0 0.8rem;
          color: $gray-black-dark;
          display: block;
        }
        p {
          font-family: "Muli", sans-serif;
          font-size: 1rem;
          font-weight: normal;
          line-height: normal;
          margin: 0 0 1.5rem;
          color: $gray-black-dark;
        }
        .radion-button-section {
          label {
            font-size: 0 !important;
            margin: 0.75rem 0 0 !important;
            display: block;
            position: relative;
            input {
              opacity: 0;
              position: absolute;
              margin: 0;
              left: 0;
              top: 0;
            }
            .radiobutton {
              background-color: #fff;
              font-family: "Raleway", sans-serif;
              font-size: 1rem;
              font-weight: bold;
              color: $blue-dark;
              min-width: 325px;
              padding: 0.81rem 1.875rem;
              border-radius: 2px;
              border: 0.087rem solid $blue-dark;
              display: inline-block;
              cursor: pointer;
              @include breakpoint(small down) {
                min-width: 100%;
                padding: 0.81rem 0.5rem;
              }
            }
            input[type="radio"] {
              &:checked {
                & ~ .radiobutton {
                  background-color: $blue-dark;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.amount-info-container {
  background-color: #f1f4f9;
  border-radius: 2px;
  border: solid 2px #c5d6f2;
  text-align: center;
  padding: 10px 20px;
  font-family: "Muli", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.625rem;
  margin-left: 3px;
  margin-right: 3px;

  @include breakpoint(medium down) {
    font-size: 1rem;
    padding: 10px 22px;
  }

  .inner-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .text {
      text-align: left;
      span {
        display: block;
        @include breakpoint(small down) {
          display: inline;
        }
      }
    }

    .icon {
      margin: 0 10px 0 0;
      height: 56px;
      flex: 0 0 56px;
      width: 56px;
      display: flex;
      align-items: center;

      @include breakpoint(medium down) {
        flex: 0 0 50px;
        width: 50px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.others_val {
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background-color: $blue-dark;
  display: flex;
  flex-flow: row nowrap;
  z-index: 1;
  padding: 0 10px;

  em {
    font-style: normal;
  }

  input {
    margin: 0;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0;

    &:focus {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.field-validation-error {
    display: none;
    > span {
        color: $red-dark;
        font-size: 0.875rem;
        font-family: "Muli", sans-serif;
        position: relative;
        top: -0.7rem;
        display: block;
        line-height: normal;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
  
.amount-txt-wrapper {
  display: none;
}

form.expansion-campaign-donation-form input.donations-submit {
  background-color: $red-dark;
  border: none;
  color: $white;
  font-size: 1.125rem;
  font-family: Muli, sans-serif;
  width: 100%;
  border-radius: 2px;
  font-weight: bold;
  padding: 1rem 0;
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;
  &:disabled {
    opacity: 0.5;
    background-color: #ccc;
    cursor: not-allowed;
  }
}

div.honor-memorial-gift-section {
  border-left: 2px solid $gray-lighter;
  margin-left: 0.5rem;
  padding: 1rem 1.3rem;
  margin-bottom: 2rem;

  select {
    &.gifttype-dropdown {
      width: 40%;
      border-radius: 4px;
    }
  }

  label {
    &.memorial-gift-label {
      font-size: 1.125rem;
      font-family: Muli, sans-serif;
      color: $gray-black-dark;
      font-weight: 400;
    }
  }
}

div.send-an-ecard-main-section {
  padding-left: 2.625rem;

  input[type="text"] {
    width: 50%;
    border-radius: 2px;

    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  textarea {
    border-radius: 2px;
  }
}
//Campaign Label
.display-campaign-name .campaign-label {
    position: relative;
    margin-bottom: 2rem;
    font-family: Muli,sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
//donations-custom-checkbox
.donations-custom-checkbox {
  position: relative;
  padding-left: 40px;
  margin-bottom: 2rem;
  font-family: $primary-font;
  font-size: $medium;
  font-weight: $x-bold;
  line-height: $line-height-normal;

  @include breakpoint(medium down) {
    font-size: $normal;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    margin: 0;

    &:checked {
      & + .checkbox {
        &::after {
          content: "";
          width: 16px;
          height: 9px;
          border-left: 3px solid $blue-dark;
          border-bottom: 3px solid $blue-dark;
          display: block;
          position: absolute;
          left: 4px;
          top: 5px;
          transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }
    &.input-validation-error {
      & ~ .checkbox {
        border-color: #da1a00;
      }
    }
  }

  .checkbox {
    width: 27px;
    height: 27px;
    border: 2px solid #ccc;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
  }
}

//Donations full form
.donation-progress-bar {
  display: flex;
  flex-flow: row nowrap;
  counter-reset: step;
  margin-bottom: 40px;
  position: relative;

  .back {
    width: 18px;
    height: 18px;
    /* text-indent: -9999px; removing because of 3077 issue */
    background-color: #fff;
    border: none;
    border-left: 2px solid $blue-dark;
    border-top: 2px solid $blue-dark;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
    display: block;
    transform: rotate(-45deg);
	color: transparent;
  }

  span {
    flex: 0 0 33.333%;
    position: relative;
    text-align: center;
    padding-top: 25px;
    font-family: "Muli", sans-serif;
    font-size: 0.813rem;
    font-weight: 600;
    color: #666;

    &::before {
      content: counter(step);
      counter-increment: step;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      background-color: #fff;
      border: 1px solid #999999;
      border-radius: 50%;
      transform: translateX(-50%);
      z-index: 2;
      font-family: "Muli", sans-serif;
      font-size: 10px;
      font-weight: bold;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #666;
      position: absolute;
      right: -50%;
      top: 10px;
      z-index: 1;
    }

    &:nth-last-of-type(1) {
      &::after {
        display: none;
      }
    }

    &.active {
      color: $blue-dark;

      &::before {
        background-color: $blue-dark;
        color: #fff;
        border-color: $blue-dark;
      }
    }

    &.completed {
      color: $blue-dark;

      &::before {
        content: "";
        background: $blue-dark url(../../images/svg/check-mark-white.png)
          no-repeat center;
        color: #fff;
        border-color: $blue-dark;
      }
    }
  }
}

//E-CARD CSS
.ecard-image-radio-section {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 30px;
  margin-left: -5px;
  margin-right: -5px;

  @include breakpoint(small down) {
    margin: 0;
    padding-right: 50px;
  }

  label {
    flex: 0 0 calc(33.333% - 10px);
    width: calc(33.333% - 10px);
    position: relative;
    height: 135px;
    text-indent: -9999px;
    cursor: pointer;
    margin: 0 5px;
    @include breakpoint(small down) {
      flex: 0 0 100%;
      width: 100%;
      height: 125px;
      margin: 0 0 20px 0;
    }

    .radiobutton {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #ccc;
      display: block;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    input[type="radio"] {
      &:checked {
        & + .radiobutton {
          &::before {
            content: "";
            width: 11px;
            height: 11px;
            background-color: $blue-dark;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            z-index: 1;
            margin-left: -5.5px;
            margin-top: -5.5px;
          }
        }
      }
    }
    input[type="radio"] {
      &:checked {
        & + input[type="hidden"] {
          & + .radiobutton {
            &::before {
              content: "";
              width: 11px;
              height: 11px;
              background-color: $blue-dark;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              border-radius: 50%;
              z-index: 1;
              margin-left: -5.5px;
              margin-top: -5.5px;
            }
          }
        }
      }
    }

    &:nth-child(3) {
      flex: 0 0 100%;
      height: auto;
      margin-bottom: 10px;
      text-indent: inherit;
      cursor: default;

      &::after {
        display: none;
      }

      &::before {
        display: none;
      }
    }

    &:nth-child(4) {
      background: url(../../images/svg/ecard-image-1.jpg) no-repeat center top;
    }

    &:nth-child(5) {
      background: url(../../images/svg/ecard-image-2.jpg) no-repeat center top;
    }

    &:nth-child(6) {
      background: url(../../images/svg/ecard-image-3.jpg) no-repeat center top;
    }
  }
}
select.gifttype-dropdown {
  width: 40%;
  border-radius: 2px;

  @include breakpoint(medium down) {
    width: 80%;
  }
}

div.ecard-main-name-section {
  display: flex;
  flex-flow: row wrap;
  margin-left: -5px;
  margin-right: -5px;
  @include breakpoint(medium down) {
    margin: 0;
  }

  .ecard-sub-first-name-section,
  .ecard-sub-last-name-section {
    flex: 0 0 50%;
    padding: 0 5px;

    @include breakpoint(medium down) {
      flex: 0 0 100%;
      padding: 0;
    }

    input {
      border-radius: 2px;
    }
  }
}

div.memorial-gift-type-section {
  border-left: 2px solid #ccc;
  margin-left: 0.813rem;
  padding: 1rem 0 1rem 1.5rem;
  margin-bottom: 2rem;

  @include breakpoint(medium down) {
    padding: 1rem 1rem 1rem 1.875rem;
  }
  @include breakpoint(small down) {
    padding: 1rem 0rem 1rem 1.875rem;
  }
}

select {
  background-image: url(../../images/svg/black-bigger.png);
  background-size: 14px;
  border-radius: 2px;
}

input[type="text"]:focus {
  border: 1px solid $gray-lighter;
}

.keyboard-navigation {
  .amount-section {
    input[type="radio"] {
      &:focus {
        & + .radiobutton {
          outline: 2px solid #333;
        }
      }
    }
  }

  .donations-custom-checkbox {
    input[type="checkbox"] {
      &:focus {
        & + .checkbox {
          outline: 2px solid #333;
        }
      }
    }
  }

  .ecard-image-radio-section {
    input[type="radio"] {
      &:focus {
        & + .radiobutton {
          outline: 2px solid #333;
        }
      }
    }
    input[type="radio"] {
      &:focus {
        & + input[type="hidden"] {
          & + .radiobutton {
            outline: 2px solid #333;
          }
        }
      }
    }
  }

  .donation-frequency-section {
    input[type="checkbox"] {
      &:focus {
        & + .checkbox {
          outline: 2px solid #333;
        }
      }
    }
  }
  .ecard-bottom-confirm-section {
    input[type="radio"] {
      &:focus {
        & + .radiobutton {
          outline: 2px solid #333;
        }
      }
    }
  }

  .donation-frequency-section {
    input[type="radio"] {
      &:focus {
        &:checked {
          & + .radiobutton {
            outline: 2px solid #333;
          }
        }
      }
    }
  }
}

//CVV tooltip
.cvv-tooltip {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-left: 8px;
  margin-top: -2px;

  &::before {
    content: attr(data-tooltip);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 302px;
    background-color: $gray-black-dark;
    padding: 15px 20px;
    display: block;
    font-family: "Muli", sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.31;
    color: #fff;
    margin-left: 10px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    @include breakpoint(medium down) {
      margin: 0 0 10px 0;
      left: auto;
      top: auto;
      bottom: 100%;
      right: -55px;
      transform: none;
    }
  }

  &:hover {
    &::before {
      opacity: 1;
      visibility: visible;
      -webkit-transition: all 0.75s ease;
      transition: all 0.75s ease;
    }
  }
  &:focus {
    &::before {
      opacity: 1;
      visibility: visible;
      -webkit-transition: all 0.75s ease;
      transition: all 0.75s ease;
    }
  }
}
//For IE
@media all and (-ms-high-contrast: none) {
  .donation-main-form {
    .donation-main-form-right-content {
      .expansion-form form {
        &.expansion-campaign-donation-form {
          div.payment-info-section {
            .payment-information-section {
              .payment-information-section-subsection {
                .payment-subsection {
                  flex: none;
                  width: 50%;
                  box-sizing: border-box;
                }
                .payment-subsection1 {
                  flex: none;
                  width: 50%;
                  box-sizing: border-box;
                }
              }
            }
          }
        }
      }
    }
  }
}
.donation-error {
  margin-bottom: 10px;
  background-color: #fff9f9;
  border: 1px solid #da1a00;
  padding: 1.563rem 2.188rem 1.25rem;
  @include breakpoint(small down) {
    padding: 1rem 1.5rem 0.9rem;
  }
  h3 {
    margin: 0 0 0.625rem;
    font-family: "Muli", sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.13;
    color: #da1a00;
    padding: 0;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      font-family: "Muli", sans-serif;
      font-size: 0.813rem;
      font-weight: normal;
      line-height: 1.38;
      color: #da1a00;
      margin: 5px 0;
      position: relative;
      padding-left: 10px;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 6px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #da1a00;
      }
    }
  }
}
//Ipad Landscape view
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .donation-header {
    padding: 1rem;
  }
  .donation-main-form {
    .donation-main-form-left-content {
      padding: 3rem 2rem 3rem 1rem;
      h2 {
        font-size: $x-large;
        line-height: 2rem;
      }
    }
    .donation-main-form-right-content {
      padding: 3rem 1rem 3rem 0;
      .expansion-form form {
        &.expansion-campaign-donation-form {
          div.payment-info-section {
            .payment-information-section {
              padding: 1.5rem 2rem;
            }
          }
        }
      }
      .card-expiration-date {
        .large-4 {
          width: 50%;
        }
        .large-2 {
          width: 25%;
        }
      }
    }
  }
  .cvv-tooltip {
    &::before {
      width: 178px;
    }
  }
}
//Ipad Portrait view
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .donation-main-form {
    .donation-main-form-right-content {
      .expansion-form {
        form {
          &.expansion-campaign-donation-form {
            div.payment-info-section {
              .card-expiration-date {
                .payment-cvv {
                  .field-validation-error {
                    & > span {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
//Ipad pro Portrait view
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .donation-header {
    padding: 1rem;
  }
  .donation-main-form {
    .donation-main-form-left-content {
      padding: 3rem 2rem 3rem 1rem;
      h2 {
        font-size: $x-large;
        line-height: 2rem;
      }
    }
    .donation-main-form-right-content {
      padding: 3rem 1rem 3rem 0;
      .expansion-form form {
        &.expansion-campaign-donation-form {
          div.payment-info-section {
            .payment-information-section {
              padding: 1.5rem 2rem;
            }
          }
        }
      }
      .card-expiration-date {
        .large-4 {
          width: 50%;
        }
        .large-2 {
          width: 25%;
        }
      }
    }
  }
  .cvv-tooltip {
    &::before {
      width: 178px;
    }
  }
}
//Mobile Landscape view
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation: landscape) {
  .ecard-image-radio-section {
    label {
      flex: 0 0 calc(33.333% - 10px);
      position: relative;
      height: 135px;
      text-indent: -9999px;
      cursor: pointer;
      margin: 0 5px;
    }
  }
}
%custom-error-msg {
  color: #da1a00;
  font-size: 0.875rem;
  font-family: Muli, sans-serif;
  position: relative;
  top: -0.7rem;
  line-height: normal;
  clear: both;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}
.ccinvalid-error {
  @extend %custom-error-msg;
  display: none;
}
.cclength-error {
  @extend %custom-error-msg;
  display: none;
}
.expiration-date-validation {
  @extend %custom-error-msg;
  display: block;
}
form.expansion-campaign-donation-form div.validation-summary-errors {
  display: none;
}
