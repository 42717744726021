.contact-us-page{
    background-color:$blue-super;
    .contact-us-page-container{
        background-color:#fff;
        margin-top:-40px;
        position: relative;
        padding:80px 0;
        @include breakpoint(small down){
            padding:40px 0;
        }
    }
}
.contact-address-block{
    padding:0 120px;
    @include breakpoint(small down){
        padding:0 30px;
    }
    address{
        font-style:normal;
        font-family: 'Muli',sans-serif;
        font-size:1.125rem;
        color:$gray-black-dark;
        a{
            color:$blue-dark;
            text-decoration:underline;
        }
        h2{
            font-family: 'Raleway',sans-serif;
            font-size: 2rem;
            font-weight: bold;
            color:$gray-black-dark;
            margin:0 0 30px;
            @include breakpoint(small down){
                line-height: 1.31;
                font-size: 1.625rem;
            }
        }
    }
}
%form-control{
    width:380px;
    height:56px;
    border: solid 1px $gray-lighter;
    box-shadow:none;
    margin:0 0 30px 0;
    font-family: 'Muli',sans-serif;
    @include breakpoint(small down){
        width:100%;
    }
}
.ContactUsForm{
    border-top:1px solid $gray-lighter;
    margin:70px 60px 60px 60px;
    padding:60px 60px 0 60px;
    &.noborder{
        border:none;
        margin:0;
        padding:2.5rem 0;
    }
    
    @include breakpoint(small down){
        margin:30px 0px 25px 0px;
        padding:30px 5px 0 5px;
    }
    h2{
        font-family: 'Raleway',sans-serif;
        font-size: 2rem;
        font-weight: bold;
        color:$gray-black-dark;
        margin:0 0 30px;
        @include breakpoint(small down){
            line-height: 1.31;
            text-align: left;
            font-size: 1.625rem;
        }
    }
    h4{
        font-family:'Muli',sans-serif;
        font-size: 1rem;
        font-weight:800;
        line-height:1.36;
        color:$blue-dark;
        @include breakpoint(small down){
            text-align:center;
        }
    }
    
        
        label{
            font-family: 'Muli',sans-serif;
            font-size:1rem;
            font-weight: bold;
            color:$gray-black-dark;
            text-transform:uppercase;
        }
      input[type='text']{
          @extend %form-control;
      }
      input[type='email']{
        @extend %form-control;
    }
      textarea{
        @extend %form-control;
        height:184px;
        width:100%;
      }
      
    
    input[type='submit']{
        background-color: $blue-dark;
        font-family:'Muli',sans-serif;
        font-size:1.125rem;
        font-weight:bold;
        line-height:56px;
        padding:0 43.5px;
        margin:0;
        outline:none;
        border-radius:2px;
        border:none;
        color:#fff;
        cursor: pointer;
        margin-top:30px;
        &:disabled{           
            opacity:1;           
        }
        &:focus{
            background-color:#2e548a!important;
        }
        &:hover{
            background-color:#2e548a!important;
        }
    }
    .field-validation-error{
        display:block;
        font-family: 'Muli',sans-serif;
                font-size: .813rem;
                font-weight: 400;
                line-height: 1.38;
                color: #da1a00;
                margin-top: -20px;
                display: block;
                margin-bottom: 10px;
        >{
            span{
                color: #da1a00;
                display: block;
            }
        }
    }
}

form.contactUs.richtext-withcontactus{
    background-color: $blue-super;
    padding-bottom: 7rem;
    .ContactUsForm{
        background-color: $white;
        margin: 0;
        margin-top: -4rem;
        padding: 3rem 6.3rem;
        @include breakpoint (medium down){
            padding: 3rem 2rem;
        }
    }
}