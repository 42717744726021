.get-updates {
    background-color: $white;
    padding-top: 3.1875rem;

    h4 {
        font-size: 1.375rem;
    }

    input {
        height: 56px;
        border: 1px solid $gray-lighter;
        font-family: $primary-font;
        font-size: $normal;
        font-weight: normal;
        line-height: normal;
        color: $gray-black-dark;
        padding-left: 10px;

        &::placeholder {
            color: $gray-black-dark;
        }

        &:focus {
            border: 1px solid $gray-lighter;
            box-shadow: none;
        }
    }

    button {
        background-color: $gray-blue !important;
        color: $white !important;
        font-family: $primary-font;
        font-size: $normal;
        font-weight: $xx-bold;
        font-style: normal;
        padding: 1rem 1.25rem;
    }
}

.follow-rmhc {
    padding: 3.125rem 0;

    .cell {
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            padding-left: 0rem;
            padding-right: 0rem;
        }
    }

    h5, .heading5 {
        color: $gray-dark;
        font-family: $primary-font;
        font-size: $medium;
        font-weight: $x-bold;
        font-style: normal;
        line-height: $line-height-5;
    }

    a {
        margin: 0 0.5rem;
        display: inline-block;
        position: relative;

        @include breakpoint (medium down) {
            margin: 0 0.2rem;
        }
    }
}

footer {
    width: 100%;
    //height: 36.5rem;
    background-color: $gray-black-dark;
    color: $white;
    padding-top: 3.5625rem;
    padding-bottom: 3.5625rem;
    font-family: $primary-font;

    a {

        &.btn-find {
            background-color: $blue-dark;
            width: 100%;
            border-radius: 0.125rem;
            font-family: $primary-font;
            font-weight: $xx-bold;
            text-align: center;
            padding: 1rem 0.625rem;

            @include breakpoint(small down) {
                width: 100%;
            }

            &:hover {
                background-color: $blue-dark;
                color: #fff !important;
            }

            &:focus {
                background-color: $blue-dark;
                color: #fff !important;
            }
        }
    }

    .footer-links-left {
        margin-top: 0.375rem;

        a {
            color: $white;
            font-size: $small;
            font-weight: $bold;
            font-style: normal;
            display: block;

            &:hover {
                color: #ccc;
            }

            &:focus {
                color: #ccc;
                outline: none;
                border: none;
            }

            &.button {
                font-size: $small;
                text-transform: uppercase;
                min-height: 55px;
                font-weight: 900;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem .1rem;
            }
        }

        button {
            background-color: #da1a00;
            line-height: 1.25rem;
            margin: 0;
            padding: .882em 0rem;
            font-size: $small;
            font-family: $primary-font;
            font-weight: $xxxx-bold;
            border-radius: 2px;
            text-transform: uppercase;
            width: 100%;

            img {
                display: inline-block;
                vertical-align: top;
                margin-right: .625rem;
            }

            &:hover {
                background-color: #da1a00;
            }

            &:focus {
                background-color: #da1a00;
            }
        }
    }

    .footer-links {
        h5, .heading5 {
            color: $white;
            border-bottom: 4px solid $gray-medium;

            a {
                font-weight: $xxx-bold;
                font-size: $normal;
                font-family: $primary-font;

                &:hover {
                    color: #ccc;
                }

                &:focus {
                    color: #ccc;
                    border: none;
                }
            }
        }

        a {
            color: $gray-super-lighter;
            font-size: $x-small;
            font-weight: $regular;
            font-style: normal;
            display: block;
            text-transform: none;

            &:hover {
                color: #ccc;
            }

            &:focus {
                color: #ccc;
                border: none;
                outline: none;
            }
        }

        h6, .heading6 {
            &.footer-sub-head {
                color: #fff;
                font-size: 12.0pt;
                text-transform: uppercase;
                font-weight: $normal-weight;
                font-family: $primary-font;
            }
        }
    }

    .copy-right-text {
        a {
            color: $gray-lighter;
            text-decoration: none;
            outline: none;
        }

        p {
            color: $gray-lighter;
            font-size: 0.75rem;
            font-weight: 400;
            font-style: normal;
        }
    }

    .footer-icons {
        display: flex;

        a {
            &:last-child {
                text-align: right;
                margin-left: 2rem;
            }
        }
    }
}

@include breakpoint (medium down) {
    .footer-center-for-iPad {
        justify-content: center;
    }

    .footer-links, .copy-right-text, .footer-icons {
        padding: 0 2rem;
    }
}

@include breakpoint (small down) {
    .footer-links, .copy-right-text, .footer-icons {
        padding: 0rem;
    }
}

.social-icons {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;

        a {
            width: 50px;
            height: 50px;
            position: relative;
            text-align: center;
            display: block;

            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }

            .hover-icon {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                transform: opacity 0.5s ease-in-out;
            }

            &:hover {
                .non-hover-icon {
                    opacity: 0
                }

                .hover-icon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 1;
                }
            }
        }
    }
}