.has-privecy{
    @include breakpoint(medium down){
        padding-top:0!important;
    }
}
.privacy-bar-component{
    background-color:$blue-dark;    
    padding:0 100px;
    position: relative;
    width:100%;
    top:0;
    left:0;
    z-index:999;
    @include breakpoint(medium down){
        z-index:0;
    }
    .grid-x{
        min-height:94px;
        padding:18px 0;
        @include breakpoint(medium down){
            padding:0;
        }
    }
    @include breakpoint(medium down){
        position: relative;
        padding:20px;
    }
    p{
        margin:0;
        color:#fff;
        font-family: 'Muli',sans-serif;
        font-size: 16px;
        line-height: normal;
        padding-right:20px;
        @include breakpoint(small down){
            padding-right:0;
            font-size: 14px;
        }
    }
    .action-button{
        text-align: right;
        @include breakpoint(small down){
            text-align: center;
            margin-top: 10px;
        }
        .button.hollow{
            border:0.1rem solid #fff;
            border-radius:2px;
            color:#fff;
            font-family: 'Muli',sans-serif;
            font-size: 1rem;
            font-weight: 700;
            margin:0;
            min-width:150px;
            padding: 1rem 1.25rem;
        }
    }
}